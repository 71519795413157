@import '../../../variables';

.requirements-container {
    .requirements-title {
        color: $corporate-blue;
        margin-bottom: 10px;
        letter-spacing: 0.5px;
        font-size: 16px;
    }

    @media only screen and (max-width: 1023px) {
        background-color: rgba(255, 255, 255, 0.5);
        color: $body-colour;
        //padding: 15px;
        margin-bottom: 8px;
        width: 100%;

        .requirements-title {
            color: $corporate-blue;
        }
    }
}
.requirement {
    display: flex;
    align-items: center;
    margin-bottom: 10px;

    p {
        color: $body-colour;
        margin-bottom: 0;
        margin-left: 9px;
        font-size: 16px;
    }

    @media only screen and (max-width: 1023px) {
        &:last-child {
            margin-bottom: 0;
        }
    }
}
