.selectable-chip {
    border: 1px solid #7c9fb0;
    color: #184f6d;
    background-color: rgba(27, 78, 107, 0.05);
    height: 35px;
    line-height: 35px;
    vertical-align: middle;
    border-radius: 30px;
    padding: 0 12px;
    margin: 5px 5px 5px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    &.selected {
        border: inherit;
        background-color: #3f417e;
        color: white;
    }
}
