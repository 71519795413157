.add-pass-photo-modal-footer {
    width: 100%;
    text-align: left;

    .action-area {
        display: flex;
        -ms-flex-direction: column;
        -webkit-flex-direction: column;
        flex-direction: column;
        -webkit-align-items: center;
        align-items: flex-end;
        position: relative;

        min-height: 50px;
    }

    .submit-button {
        margin: 0;
        padding: 0 8px;
    }
}