@import "../../../variables";

.view-bank-details-box {
  padding: 12px 0;
  margin-bottom: 12px;
  color: #a0a0a0;
  text-align: center;
  font-family: Foco;
  font-size: 14px;
  text-transform: uppercase;
  border-bottom: 1px solid #eeeeee;
  border-top: 1px solid #eeeeee;
  cursor: pointer;
}

.my-account-table {
  > .widget-contents {
    overflow: visible;
  }
}

.owner-account-number {
  text-align: center;
  padding-top: 12px;
  font-size: 23px;
  line-height: 26px;
  letter-spacing: 0.43px;
  color: $corporate-blue;
}

.payment-result {
  text-align: center;
  width: 350px;

  p {
    color: #1e2171;
    font-size: 20px;
    padding: 10px;
  }

  button {
    width: 100%;
  }

  button.mobile {
    width: 80%;
  }
}

.my-account-table-margin {
  margin-bottom: 20px;
}
