@import '../../../variables';

.btn {
    flex: 1;
    height: 50px;
    border-radius: 4px;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 1px;
    margin: 8px;
    cursor: pointer;
    font-family: 'Foco', Arial, sans-serif;
    transition: background-color 200ms ease-in-out;

    .btn-icon {
        margin-bottom: 1vh;
        color: $corporate-blue;
    }

    &:active,
    &:focus {
        outline: none;
    }

    &[disabled] {
        opacity: 0.5;
        cursor: not-allowed;
        box-shadow: 0 3px 11px 0 rgba(28, 77, 107, 0.2);
    }

    &.btn-primary {
        background-color: $primary-button-colour;
        color: #fff;
        border: none;
        text-transform: uppercase;
        box-shadow: 0 5px 7px -3px rgba(133, 133, 133, 0.5);

        &:hover:not([disabled]) {
            background-color: #143a50;
            box-shadow: 0 5px 20px 0 rgba(133, 133, 133, 0.5);
        }

        &.btn-ripple div {
            border: 4px solid rgba(255, 255, 255, 0.75);
        }
    }

    &.btn-outline {
        background-color: transparent;
        color: #fff;
        border: 1px solid #fff;
        text-transform: uppercase;

        &:hover:not([disabled]) {
            background-color: #ffffff33;
            box-shadow: 0 10px 20px rgba(255, 255, 255, 0.1), 0 6px 6px rgba(255, 255, 255, 0.1);
        }
    }

    &.btn-secondary {
        background-color: #fff;
        color: rgba(28, 77, 107, 0.75);
        border: 1px solid #cdcdcd;
        text-transform: uppercase;
        box-shadow: 0 5px 11px 0 rgba(135, 145, 151, 0.2);

        &:hover:not([disabled]) {
            background-color: #f5f6fb;
            box-shadow: 0 6px 15px 0 rgba(135, 145, 151, 0.3);
        }
    }

    &.btn-cancel {
        @extend .btn-primary;
        opacity: 0.7;
    }

    &.btn-ripple {
        display: inline-block;
        position: relative;
    }

    &.btn-ripple div {
        position: absolute;
        border: 4px solid rgba(28, 77, 107, 0.75);
        opacity: 1;
        border-radius: 50%;
        animation: btn-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
    }

    &.btn-ripple div:nth-child(2) {
        animation-delay: -0.5s;
    }

    @keyframes btn-ripple {
        0% {
            top: 18px;
            right: 30px;
            width: 0;
            height: 0;
            opacity: 1;
        }

        100% {
            top: -1px;
            right: 11px;
            width: 40px;
            height: 40px;
            opacity: 0;
        }
    }

    &.full-width {
        width: 100%;
        margin-left: 0;
        margin-right: 0;
    }
}

.btn-link {
    font-size: 16px;
    font-weight: bold;
    line-height: 19px;
    letter-spacing: 1px;
    cursor: pointer;
    font-family: 'Foco', Arial, sans-serif;
    color: #004bff;
    background: none;
    border: none;

    @media only screen and (max-width: 1023px) {
        color: #fff;
    }
}

.back-button-container {
    text-align: right;
    width: 100px;

    a,
    button {
        outline: none;
        color: $primary-button-colour;
        font-size: 20px;
        letter-spacing: 1.25px;
        line-height: 33px;
        text-decoration: none;
        text-transform: uppercase;
        background: none;
        padding: 0;
        border: none;
        font-family: Foco, Arial, Helvetica, sans-serif;
        cursor: pointer;

        .back-button-arrow {
            margin-right: 15px;
        }

        @media only screen and (max-width: 1023px) {
            color: #fff;
        }
    }
}

.footer-button {
    margin: 0 0 0 auto;
    max-width: 200px;
    // needed for IE
    flex: 100%;
}

.modal-back-button {
    box-shadow: 0 5px 11px 0 rgba(185, 193, 197, 0.2);
    background-color: #dce7ec;
    color: rgba(28, 77, 107, 0.41);
    height: 50px;
    width: 50px;
    line-height: 20px;
    display: flex;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-align-items: center;
    align-items: center;

    transition: 200ms ease-in-out;

    &:hover {
        cursor: pointer;
        background-color: #d1dce1;
    }
}

.btn-tertiary {
    padding: 20px 0;
    color: #a0a0a0;
    text-align: center;
    font-family: Foco;
    font-size: 14px;
    text-transform: uppercase;
    border-bottom: 1px solid #eeeeee;
    border-top: 1px solid #eeeeee;
    cursor: pointer;

    &:hover:not(.disabled) {
        background-color: #e0e9f1;
    }
    &.disabled{
        cursor: not-allowed;
    }
  
    &.btn-ripple {
        position: relative;
    }

    &.btn-ripple div {
        top: 50%;
        position: absolute;
        border: 4px solid rgba(28, 77, 107, 0.75);
        opacity: 1;
        border-radius: 50%;
        animation: btn-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
    }

}
