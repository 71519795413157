@import '../../../variables';

.events-widget-item {
    display: flex;
    align-items: center;

    &:hover {
        cursor: pointer;
    }

    .event-image-container {
        overflow: hidden;
        border-radius: 5px;
        position: relative;
        min-width: 52px;
        max-width: 52px;
        height: 52px;
        display: flex;
        justify-content: center;
        align-items: center;

        img {
            height: 52px;
        }
    }

    .item-details {
        display: flex;
        flex-direction: column;
        font-weight: normal;
        text-align: left;
        text-transform: none;
        margin-left: 15px;
        width: 100%;
        /* need the min-width for the elipsis to work */
        min-width: 0;

        .item-title {
            line-height: 21px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            color: $body-colour;
        }

        .item-description {
            color: #9a9797;
            font-size: 14px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }
    }
}

.event-load-more {
    color: $body-colour;
    padding: 20px 0;
    text-decoration: none;
    cursor: pointer;

    &:hover {
        text-decoration: underline;
    }
}
