@import '../../../variables';

.transaction-item {
    padding: 15px;
    border-bottom: 2px solid #eeeeee;
    height: 46px;

    &:nth-child(6) {
        border-bottom: none;
    }

    .transaction-date-row {
        display: flex;
        justify-content: space-between;

        .transaction-date {
            font-size: 12px;
            font-weight: bold;
            line-height: 14px;
            letter-spacing: 0.75px;
            color: #565656;
            margin: 0;
        }

        .invoice-link {
            color: #565656;
            opacity: 0.8;
            font-size: 12px;
            line-height: 14px;
            padding: 0;
        }
    }

    .transaction-details {
        margin-top: 5px;
        display: flex;
        align-items: flex-end;

        .transaction-type-container {
            flex: 1;
            margin: 0 10px;
            overflow: hidden;

            .transaction-type {
                margin: 0;
                font-size: 14px;
                font-weight: bold;
                letter-spacing: 0.9px;
                line-height: 15px;
                color: $body-colour;
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
            }

            .transaction-no {
                margin: 0;
                font-size: 12px;
                line-height: 15px;
                letter-spacing: 0.9px;
                color: #a0a0a0;
            }
        }

        .transaction-amount {
            margin: 0;
            font-size: 16px;
            line-height: 18px;
            letter-spacing: 1px;
            color: #565656;
        }
    }
}
