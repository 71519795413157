@import "../../../variables";

.home-page-container {
  max-width: 1244px;
  height: calc(100% - 7em);
  margin: auto;
  position: relative;

  .welcome-widget {
    text-align: left;
  }

  .reservation-links-widget-container {
    .widget-contents {
      background: #ffffff;
      box-shadow: 0 2px 11px -3px rgba(95, 129, 226, 0.44);
      .reservations-container {
        padding-left: 20px;
        padding-right: 20px;
      }
    }
  }

  .reservations-container {
    justify-content: center;
  }

  .home-reservations-header {
    margin-bottom: 20px;
  }

  .home-park-image-widget-container {
    .widget-contents {
      .park-home-park-image-container {
        padding: 20px;
      }
    }
  }
}

.horizontal-background {
  &.home-page-background {
    background: url("/images/Desktop-PalmOverlay-Home.svg"), $background-colour;
    background-position-x: center;
    background-position-y: bottom;
    background-repeat: no-repeat;
    min-height: 0px;
    height: 20em;
  }
}

.home-navigation {
  .widget-contents {
    flex-basis: 165px;
    border-radius: 12px;
    padding: 20px 20px;
    transition: all 0.1s ease-in-out;
    &:hover {
      transform: scale(1.1);
    }
  }
}


.park-news-title-home{
  color: #1e2171;
  //font-size: 23px;
  letter-spacing: 0.4px;
  line-height: 26px;
  font-weight: bold;
  text-transform: uppercase;
  text-align: center;
  margin-bottom: 0;
}