.book-table-modal {
    width: 650px;
    overflow-x: hidden;
    overflow-y: auto;

    .modal-header {
        margin-bottom: 20px;
    }

    .info-message p {
        font-size: inherit;
        letter-spacing: inherit;
        margin-bottom: 0;
    }

    .info-message {
        margin-bottom: 20px;
    }

    .error-message {
        width: 92%;
        margin-bottom: 30px;
        align-self: flex-start;
    }

    form {
        width: 100%;
        margin-bottom: 10px;

        .add-booking-field, .add-booking-field-full {
            display: inline-block;
            width: 50%;
            margin-top: 15px;
            margin-bottom: 15px;
            vertical-align: top;

            label {
                color: #7c9fb0;
                font-size: 14px;
                font-weight: bold;
                word-wrap: break-word;
                display: block;
            }

            input {
                width: 90%;
            }

            .react-datepicker-wrapper {
                width: 100%;
            }

            .select-container {
                margin: 8px 0;
                width: 90%;

                div [class*='control'] {
                    border: 1px solid #e0e7ee;
                    height: 50px;
                }
            }

            &-full {
                width: 100%;
            }
        }

        div[data-radium] {
            margin-bottom: 2vh;
        }

        .helper-text {
            position: relative;
            top: -1vh;
            color: transparent;

            &.show {
                color: inherit;
            }
        }

        .timeslot-message {
            font-size: 16px;
            white-space: pre-wrap;
            height: 35px;
            line-height: 35px;
            vertical-align: middle;
            margin: 5px 5px 5px 0;
        }
    }

    .modal-footer {
        margin-top: 0;
    }
}

.book-table-modal-mobile {
    max-width: 100%;
    width: 100%;
    position: relative;
    overflow-x: hidden;
    overflow-y: auto;
    align-content: left;

    .info-message {
        width: 95%;
        margin-bottom: 20px;
        min-height: 50px;
        
    }

    .add-booking-modal-mobile-form {
        width: 100%;

        .add-booking-field, .add-booking-field-full {
            display: block;
            margin-bottom: 25px;
            width: 100%;

            label {
                color: #7c9fb0;
                font-size: 14px;
                font-weight: bold;
                word-wrap: break-word;
                display: block;
            }

            input {
                width: 100%;
            }

            .select-container {
                margin: 8px 0;

                div [class*='control'] {
                    border: 1px solid #e0e7ee;
                    height: 50px;
                }
            }

            &:last-of-type {
                margin-bottom: 45px;
            }
        }

        div[data-radium] {
            margin-bottom: 2vh;
        }

        .timeslot-message {
            font-size: 16px;
            line-height: inherit;
            white-space: pre-wrap;
        }
    }
}

.timeslot-container {
    .spinner{
        margin-left: 40px;
    }
}
.timeslot-container .selectable-chip {
    display: inline-block;
    width: fit-content;
}

.success-modal {
    max-width: 633px;
}

