@import '../../../../variables';

.row-text {
  display: inline-flex;
}

.column {
  float: left;
  width: 45%;
  padding: 0px 2.5%;

  h1 {
    color: $corporate-blue;
    text-align: center;
    margin-top: 0px;
  }

  h3 {
    color: $corporate-blue;
  }
}

/* Clear floats after the columns */
.row:after {
  content: "";
  display: table;
  clear: both;
}

.input {
  margin-top: 15px;
}

.pad-preferences {
  margin: 10px;
}

.float-left {
  float: left;
}

.float-right {
  float: right;
}

.vehicle-table {
  width: 100%;
}

.vehicle-table th {
  text-align: left;
  font-size: 18px;
}

.summary-table {
  width: 100%;
  text-align: center;
}

.vehicle-table tbody>tr>td {
  border: none;
  padding: 0px;
}

.summary-table tbody>tr>td {
  border: none
}

.summary-table th {
  font-size: 18px;
}

.summary-table td {
  padding: 20px 20px;
}

.add-membership-button {
  width: 150px;
}

.autocomplete-input {
  padding: 0px;
  height: 48px;
  width: 90%;

  .css-yk16xz-control {
    height: 48px;
  }

  .css-1pahdxg-control {
    height: 48px;
  }

  .css-1hwfws3 {
    height: 48px;
  }

  .css-1g6gooi {
    height: 48px;
    line-height: 28px;
  }
}

.transfer-mem {
  //border: 1px solid black;
  margin-top: -25px;
}

.referred-drop-down {
  margin-left: 5px;
  margin-right: 5px;
  margin-bottom: 10px;
  margin-top: 7px;
  width: 60px;
  display: inline-block;
}

.end-date-text {
  margin-top: 10px;
  margin-bottom: 5px;
  margin-left: 20px;
}

.end-date-label {
  margin-top: 10px;
  margin-bottom: 0px;
}

.end-date-text-desktop {
  margin-top: 20px;
}

.end-date-label-desktop {
  margin-top: 15px;
  margin-bottom: 0px;
}