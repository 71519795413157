@import '../../../variables';

.memberships-used-box {
    width: 240px;
    height: 20px;
    border: 1px solid darkgray;
    padding: 5px;
    margin: 10px;
    background-color: #b6d7a8;
    margin-right: 5%;
}

@media screen and (min-width: 600px) {
    .used-box-display {
        float: right;
    }
}

@media screen and (max-width: 600px) {
    .used-box-display {
        float: none;
        margin-left: 8%;
        margin-right: 8%;
    }
    
}