.image-background-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    img.image-background {
        position: fixed;
        width: 100%;
        height: 100%;
    }

    .image-background-children {
        z-index: 1;
        width: 85%;
    }
}

.vertical-background-container {
    flex: 50%;
    height: 100%;

    .vertical-background-image {
        position: absolute;
        height: 100%;
        bottom: 0;
        left: 0;
    }
}