div.button-container {
    display: flex;

    .btn:first-child {
        margin-left: 0;
    }

    .btn:last-child {
        margin-right: 0;
    }
}

div.input-container {
    display: flex;
    flex-direction: column;
}

.icon-link-container {
    padding: 23px 15px;
    box-sizing: border-box;
    text-align: center;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    cursor: pointer;
    overflow: hidden;

    .image-container-wrapper {
        margin: 0 10px 20px 10px;
    }

    .image-container {
        background-color: rgba(235, 236, 241, 0.7);
        border-radius: 50%;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        position: relative;

        &:after {
            content: '';
            display: block;
            padding-bottom: 100%;
        }

        img {
            position: absolute;
            left: 50%;
            transform: translateX(-50%) translateY(-50%);
            top: 50%;

            @media only screen and (max-width: 1023px) {
                width: 50%;
            }
        }
    }

    a {
        color: inherit;
        text-decoration: none;
    }

    .label {
        color: #6e7490;
        font-weight: bold;
        font-size: 14px;
        letter-spacing: 1px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
    }
}
