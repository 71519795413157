.payment-modal {
    width: 650px;
    align-items: flex-start;
    overflow: auto;

    @media only screen and (max-width: 1023px) {
        width: 100%;
        position: relative;
    }

    .payment-details-section {
        width: 100%;

        .description {
            font-size: 16px;
            line-height: 22px;
            color: #445870;
        }

        .payment-details {
            display: flex;
            flex-wrap: wrap;

            > div {
                flex-basis: 50%;
            }

            @media only screen and (max-width: 1023px) {
                > div {
                    margin-bottom: 25px;
                    flex-basis: 100%;
                }
            }

            .title {
                font-weight: bold;
                font-size: 14px;
                line-height: 14px;
                color: #80a2b2;
                margin-bottom: 10px;
            }

            label.title {
                width: 100%;
                text-align: center;
            }

            .value {
                font-size: 20px;
                line-height: 18px;
                margin-bottom: 25px;
            }

            a.value {
                color: #008bff;
            }
        }

        .payment-details.card {
            justify-content: center;

            input {
                width: 50%;
                text-align: center;
                padding-right: 3%;
            }

            input[type=number]::-webkit-inner-spin-button,
            input[type=number]::-webkit-outer-spin-button {
                -webkit-appearance: none;
                margin: 0;
            }

            input[type=number] {
                -moz-appearance: textfield;
            }
        }

        .payment-details.card-address {
            height: 72vh;
            display: block;
            flex-wrap: unset;

            @media only screen and (max-width: 1023px) {
                height: auto;
            }

            .payment-details-card-address-field {
                display: inline-block;
                width: 50%;
                margin-top: 15px;
                margin-bottom: 15px;
                vertical-align: top;

                @media only screen and (max-width: 1023px) {
                    width: 100%;
                }

                label {
                    color: #7c9fb0;
                    font-size: 14px;
                    font-weight: bold;
                    word-wrap: break-word;
                    display: block;
                }

                label.error {
                    color: red;
                }

                input[type=text] {
                    width: 90%;
                }

                .select-container {
                    width: 90%;
                    margin-top: 8px;
                    margin-bottom: 8px;

                    > div:first-of-type {

                        > div:first-of-type {
                            height: 50px;
                            width: 100%;
                        }
                    }
                }
            }

            .payment-details-card-address-field.country {
                width: 106%;
            }
        }
    }
}
