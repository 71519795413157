@import '../../../variables';

.my-calendar-reservation-header {
    color: #fff;
    font-size: 23px;
    letter-spacing: 0.43px;
    line-height: 26px;
    margin-bottom: 20px;
}

.reservations-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    padding-bottom: 20px;
}

.reservation-link {
    width: 33.33%;
    border-radius: 9.3px;
    background-color: #ffffff;
    text-transform: uppercase;
    box-shadow: 0 5px 7px 3px rgba(25, 25, 104, 0.17);

    &:first-child {
        margin-right: 20px;
    }

    &:nth-child(2) {
        margin-right: 20px;
    }
}

.reservation-links-mobile {
    margin: 0 -17px;
    box-shadow: 0 2px 11px 0 rgba(84, 83, 177, 0.14);

    .my-calendar-reservation-header {
        text-align: center;
        color: $corporate-blue;
        background-color: white;
        margin-bottom: 0;
        padding: 15px;
        font-size: 19px;
        letter-spacing: 0.43px;
        line-height: 26px;
    }

    .reservations-container {
        background: $background-colour;
        padding: 20px;
        margin: 10px 20px;
    }

    .icon-link-container {
        padding: 18px 0;

        .image-container-wrapper {
            margin: 0 16px 20px 16px;
        }
    }
}
