.menu-contents {
  display: flex;
  margin: 0;

  overflow-x: auto;

  .widget {
    margin-bottom: 20px;
    width: 168px;
    height: 200px;

    @media only screen and (max-width: 1023px) {
      width: 135px;
    }
  }
}

.menu-widget-container {
  @media only screen and (max-width: 1023px) {
    margin-top: 50px;
  }
}
