.pass-status-cell {
    display: flex;
    justify-content: flex-start;

    svg.awaiting-approval {
        color: inherit;
    }

    .pass-status-cell-value {
        margin-left: 1vw;
        color: inherit;
        font-size: 16px;
    }
}

.pass-status-cell-mobile-container {
    margin: 0 !important;
    overflow: visible !important;
}

.pass-status-cell-mobile {
    @extend .pass-status-cell;
    align-items: center;

    .pass-status-cell-value {
        margin-left: 3vw;
        color: inherit;
        font-size: 16px;
    }
}

.awaiting-approval {
    color: #ffaf00;
}

.expired {
    color: #eb3f3f;
}

.approved {
    color: #2acaa2;
}
