.filter-container {
    padding: 23px 40px;
    border-top: 1px solid #eeeeee;
    display: flex;
    flex-wrap: wrap;

    @media only screen and (max-width: 1023px) {
        border: none;
        padding: 0 20px;
    }

    .filter-date {
        label {
            color: #7c9fb0;
            font-weight: bold;
            line-height: 14px;
            font-size: 14px;
        }
        display: flex;
        flex-direction: column;
        margin-right: 10px;
        max-width: 160px;
        width: 100%;
        @media only screen and (max-width: 767px) {
            flex-direction: column;
            margin-right: 0;
            flex: 1;
            max-width: 100%;
        }

        input {
            @media only screen and (max-width: 1023px) {
                width: 100%;
            }
        }
    }

    .search-button {
        min-width: 90px;
        padding-top: 14px;
        padding-right: 10px;
        @media only screen and (max-width: 767px) {
            width: 100%;
            padding-right: 0;
        }

        button {
            border: 1px solid #e0e7ee;
        }
    }

    .filter-type {
        label {
            color: #7c9fb0;
            font-weight: bold;
            line-height: 14px;
            font-size: 14px;
        }

        display: flex;
        flex-direction: column;
        margin-right: 10px;
        margin-left: 15px;

        @media only screen and (max-width: 767px) {
            flex-direction: column;
            margin-right: 0;
            flex: 1;
            margin-left: 0px;
            margin-top: 20px;
        }
    }

    .dropdown__control {
        min-height: 50px;
        width: 240px;
        height: 100%;
        margin-top: 8px;
        border: 1px solid #e0e7ee;
        transition: color ease-in-out 0.3s, border-color ease-in-out 0.3s, box-shadow ease-in-out 0.3s;
        @media only screen and (max-width: 1023px) {
            width: 210px;
        }

        @media only screen and (max-width: 767px) {
            width: 100%;
            margin-right: 0;
            flex: 1;
        }
    }

    .dropdown__value-container {
        min-height: 50px;
    }

    .date-filter {
        width: 100%;
    }
}
