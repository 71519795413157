@import '../../../variables';

.information-row {
    display: flex;
    flex-direction: row;
    margin-bottom: 1em;
    padding: 2em 2em 0 2em;
    flex-wrap: wrap;
}

.information-row.details {
    padding: 2em 0;
}

.park-home-title {
    color: $corporate-blue;
    font-size: 23px;
    letter-spacing: 0.43px;
    line-height: 26px;
}

.park-home-sub-title {
    color: #80a2b2;
    font-size: 14px;
    font-weight: bold;
    margin-top: 1em;
}

.park-home-description {
    font-size: 16px;
    line-height: 22px;
    margin-top: 1em;
    width: 90%;
}

.park-home-title-mobile {
    color: $corporate-blue;
    font-size: 18px;
    letter-spacing: 0.88px;
    line-height: 25px;
    margin: 2em 2em 0.5em 2.5em;
}

.park-home-sub-title-mobile {
    color: #80a2b2;
    font-size: 14px;
    font-weight: bold;
    margin-top: 1em;
    text-align: left;
    margin-left: 2.5em;
}

.park-home-description-mobile {
    color: $body-colour;
    font-size: 16px;
    letter-spacing: 0.88px;
    line-height: 25px;
    margin: 0.5em 2em;
}

.park-home-details-mobile {
    margin-top: 1em;
    margin-right: 0 !important;
}

.park-home-lodge-details-key-mobile {
    color: #80a2b2 !important;
    font-size: 14px !important;
    letter-spacing: 0.88px !important;
    line-height: 25px !important;
    padding: 1em 0 1em 2.5em !important;
}

.park-home-lodge-details-value-mobile {
    color: #1b4e6b !important;
    font-size: 14px !important;
    font-weight: bold;
    letter-spacing: 0.88px !important;
    line-height: 25px !important;
    padding: 1em 1em 1em 0 !important;
}

.contact-row {
    display: flex;
    flex-direction: column;
    flex: 1;
}

.park-home-contact-park {
    margin: 1em 0 1.5em 0;
    width: 87%;
    max-height: 3em;
    min-height: 3em;
}

.park-home-contact-park-mobile {
    margin: 0.5em 2em 0 2.5em;
    min-height: 3em;
}

.park-home-park-image-container {
    flex: 1;

    img {
        width: 100%;
    }
}

.park-home-park-image-mobile {
    width: 100%;
}

.park-home-main-title-mobile {
    border-bottom: 1px solid #eeeeee;
    margin: 0 0 0.5em 0;
    padding: 1em 0;
    font-weight: bold;
}

.faq-title {
    color: $corporate-blue;
    font-size: 23px;
    letter-spacing: 0.43px;
    line-height: 26px;
    padding: 32px;
}

.faq-accordion {
    border: none;
    margin-bottom: 8px;
    background-color: #ffffff;

    .accordion__item {
        border-top: 1px solid rgba(0, 0, 0, 0.1);

        .accordion__button {
            width: auto;
            background-color: #ffffff;
            vertical-align: middle;
            outline: none;
            font-size: 20px;
            display: flex;
            align-content: flex-start;
            align-items: center;
            padding-left: 32px;
            min-height: 50px;
            height: 10px;

            img {
                height: 15px;
            }

            span {
                margin-left: 50px;
                line-height: 15px;
                color: $corporate-blue;
            }

            &:before {
                content: none;
            }
        }

        .accordion__panel {
            font-size: 15px;
            padding-top: 10px;
            padding-right: 55px;
            padding-bottom: 32px;
            padding-left: 98px;
            background-color: white;

            p {
                color: $body-colour;
                font-size: 16px;
            }
        }
    }
}

.my-park-and-home-mobile {
    .faq-title {
        text-align: center;
        font-size: 19px;
    }

    .accordion__item {
        .accordion__button {
            font-size: 16px;

            span {
                margin-left: 10px;
            }
        }

        .accordion__panel {
            padding-left: 58px;
        }
    }
}

.park-news-title-mobile {
    text-align: center;
}

.park-news-title {
    color: #fff;
    font-size: 23px;
    letter-spacing: 0.4px;
    line-height: 26px;
}
