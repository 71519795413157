.add-form-input {
  height: 50px;
  width: 90%;
}

.center-input {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
}

.center-content {
  display: flex;
  justify-content: center;
  align-items: center;
}

.select-text {
  font-size: 18px;
  text-align: center;
  margin-left: 5px;
}