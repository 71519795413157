@font-face {
    font-family: "Foco";
    font-weight: bolder;
    src: url("./Fonts/Foco_Blk.ttf") format("truetype");
}

@font-face {
    font-family: "Foco";
    font-weight: bolder;
    font-style: italic;
    src: url("./Fonts/Foco_BlkIt.ttf") format("truetype");
}

@font-face {
    font-family: "Foco";
    font-weight: bold;
    src: url("./Fonts/Foco_Bd.ttf") format("truetype"), url("./Fonts/Foco-Bold.otf") format('opentype');
}

@font-face {
    font-family: "Foco";
    font-weight: bold;
    font-style: italic;
    src: url("./Fonts/Foco_BdIt.ttf") format("truetype");
}

@font-face {
    font-family: "Foco";
    font-weight: lighter;
    src: url("./Fonts/Foco_Lt.ttf") format("truetype");
}

@font-face {
    font-family: "Foco";
    font-weight: lighter;
    font-style: italic;
    src: url("./Fonts/Foco_LtIt.ttf") format("truetype");
}

@font-face {
    font-family: "Foco";
    font-weight: normal;
    font-style: italic;
    src: url("./Fonts/Foco_It.ttf") format("truetype");
}

@font-face {
    font-family: "Foco";
    font-weight: normal;
    src: url("./Fonts/Foco-Regular.ttf") format("truetype"), url("./Fonts/Foco-Regular.otf") format('opentype');
}
