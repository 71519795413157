.align-div {
    margin: auto;
    width: 70%;
    padding: 20px;
    padding-top: 30%;
}

.confirmButton{
    margin-left: 23%;
    margin-top: 10px;
}