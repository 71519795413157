@import './variables';

.welcome-background {
    background: $background-colour;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-direction: column;
    color: #fff;
    overflow: hidden;

    img.image-background {
        width: unset;
        left: 0;
        bottom: 0;
    }

    h1 {
        font-size: 32px;
        width: 200px;
        letter-spacing: 0.6px;
        line-height: 36px;
    }

    p {
        width: 250px;
        letter-spacing: 1px;
        line-height: 19px;
        font-size: 16px;
    }

    .btn {
        margin-top: 10%;
        margin-bottom: 15%;
    }
}

.login-background {
    background: $background-colour;
    display: flex;
    align-items: center;
    flex-direction: column;
    color: #fff;
    overflow: auto;

    img.image-background {
        width: unset;
        left: 0;
        bottom: 0;
    }

    .btn {
        margin-top: 10%;
        margin-bottom: 5%;
    }
    .forgot-pw-mobile {
        form {
            margin-top: 150px;
        }
    }
}

.forgot-password-page-vertical-background {
    background: $background-colour;
    position: relative;
    overflow: hidden;

    img {
        align-self: flex-start;
    }
}

.forgot-password-page-container {
    display: flex;
    height: 100%;
    background-color: #fff;

    .forgot-password-container {
        flex: 50%;

        h1 {
            color: $corporate-blue;
            font-size: 23px;
        }

        h1.request-failed {
            color: red;
        }

        h3 {
            font-weight: normal;
            color: $corporate-blue;
            font-size: 16px;
            margin-top: 0;
            margin-bottom: 1em;
            width: 100%;
        }

        .error {
            color: red;
            font-size: 18px;
            margin-top: 0;
            width: 100%;
        }

        h3.request-failed {
            color: red;
        }

        .login-back {
            margin: 5vh 5vw 0 auto;
        }

        form {
            margin-top: 15vh;
            margin-left: 5vw;
            margin-right: 5vw;
        }

        .button-container:last-child {
            flex: 1;
            justify-content: flex-end;
            align-items: flex-end;

            .btn-secondary {
                border: 1px solid #cdcdcd;
                width: 175px;
                flex: none;
            }
        }

        .requirements-container {
            margin-bottom: 20px;
        }
    }

    .vertical-background-container {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        flex-direction: column;
        color: #fff;

        .vertical-background-children {
            margin-bottom: 40%;
            min-width: 385px;
            align-self: flex-start;
            margin-left: 15%;
            width: 400px;

            h1 {
                font-size: 69px;
                letter-spacing: 1.29px;
                line-height: 79px;
            }

            p {
                font-size: 23px;
                letter-spacing: 1.44px;
                line-height: 30px;
            }
        }
    }

    .link-right {
        color: $body-colour;
        font-size: 14px;
        line-height: 19px;
        letter-spacing: 0.88px;
    }

    .heading-right {
        color: $body-colour;
        font-size: 14px;
        line-height: 19px;
        letter-spacing: 0.88px;
        margin-top: 10px;
    }
}

.forgotten-password-link {
    text-align: right;
    margin-bottom: 5px;
}

.resendEmail {
    color: $body-colour;
    @media only screen and (max-width: 1023px) {
        color: #fff;
    }

    a {
        color: #004bff;
        cursor: pointer;
    }
}
