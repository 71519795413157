@import "../../../variables";

.welcome-background {
  background: $background-colour;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-direction: column;
  color: #fff;
  overflow: hidden;

  img.image-background {
    width: unset;
    left: 0;
    bottom: 0;
  }

  h1 {
    font-size: 32px;
    width: 200px;
    letter-spacing: 0.6px;
    line-height: 36px;
  }

  p {
    width: 250px;
    letter-spacing: 1px;
    line-height: 19px;
    font-size: 16px;
  }

  .btn {
    margin-top: 10%;
    margin-bottom: 15%;
  }
}

.login-background {
  background: $background-colour;
  display: flex;
  align-items: center;
  flex-direction: column;
  color: #fff;
  overflow: auto;

  img.image-background {
    width: unset;
    left: 0;
    bottom: 0;
  }

  .register-button-container {
    width: 50%;
    margin-left: 50%;
    margin-bottom: 15%;

    .btn {
      margin-bottom: 0;
      margin-left: 8px !important;
    }
  }

  .btn {
    margin-top: 10%;
    margin-bottom: 5%;
  }
}

.login-failure-message {
  color: transparent;
  &.show {
    color: #ffffff;
  }
}

.login-back-mobile a {
  color: #fff;
  position: absolute;
  right: 7.5%;
  top: 20px;
}

.login-page-vertical-background {
  background: $background-colour;
  position: relative;
  overflow: hidden;

  img {
    align-self: flex-start;
  }
}

.login-page-container {
  display: flex;
  height: 100%;
  background-color: #fff;

  .login-popup-container {
    flex: 50%;
    overflow-y: auto;

    .login-back {
      margin: 5vh 5vw 0 auto;
    }

    form,
    .login-input-outer-container {
      margin-left: 5vw;
      margin-right: 5vw;
    }

    .button-container:last-child {
      margin-right: 5vw;
      justify-content: flex-end;
      align-items: flex-end;

      .btn-secondary {
        border: 1px solid #cdcdcd;
        width: 175px;
        flex: none;
      }
    }
  }

  .vertical-background-container {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-direction: column;
    color: #fff;

    .vertical-background-children {
      margin-bottom: 40%;
      min-width: 385px;
      align-self: flex-start;
      margin-left: 15%;
      width: 400px;

      h1 {
        font-size: 69px;
        letter-spacing: 1.29px;
        line-height: 79px;
      }

      p {
        font-size: 23px;
        letter-spacing: 1.44px;
        line-height: 30px;
      }
    }
  }

  .link-right {
    font-size: 14px;
    line-height: 19px;
    letter-spacing: 0.88px;
  }

  .heading-center {
    color: $body-colour;
    font-size: 14px;
    line-height: 19px;
    letter-spacing: 0.88px;
    margin-top: 10px;
    text-align: center;
  }

  .heading-right {
    color: $body-colour;
    font-size: 14px;
    line-height: 19px;
    letter-spacing: 0.88px;
    margin-top: 10px;
    text-align: right;
  }
}

.login-input-outer-container {
  label {
    margin-bottom: 1vh;
  }

  .select-container {
    z-index: 3;
    color: #184f6d;
  }

  margin-bottom: 2vh;
  margin-top: 16vh;

  &.mobile {
    margin-top: 10%;
  }
}

.forgotten-password-link {
  text-align: right;
  margin-bottom: 5px;
}

.login-park-dropdown > div > div {
  min-height: 50px;
}

.separator {
  margin-top: 8px;
  line-height: 30px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  .text {
    text-align: center;
    margin: 0 10px;
    padding: 0 10px;
    position: relative;
    z-index: 1;
    color: #fff;
  }

  .line {
    width: 100%;
    border-top: 1px solid #fff;
    margin-top: 15px;
  }

  &.dark {
    .text {
      color: #a0a0a0;
    }

    .line {
      border-top: 1px solid #a0a0a0;
    }
  }
}

.external-login-container {
  margin-left: 5vw;
  margin-right: 5vw;

  &.mobile {
    margin-left: 0;
    margin-right: 0;
  }

  .google-login-button-container {
    .google-login-button {
      color: $body-colour;
      text-align: center;
      background-color: white;
      border-radius: 4px;
      border: solid 1px rgba(151, 151, 151, 0.48);
      padding: 15px;
      width: 100%;
      font-family: "Foco", Arial, sans-serif;
      font-size: 16px;
      text-transform: uppercase;
      font-weight: bold;
      cursor: pointer;
      line-height: 18px;

      span {
        display: inline-block;
        text-align: left;
      }

      img {
        height: 18px;
        margin-right: 20px;
        vertical-align: bottom;
      }

      &:hover {
        background-color: #f5f6fb;
        border: solid 1px #cdcdcd;
      }

      &:disabled {
        opacity: 0.5;
        cursor: not-allowed;
      }
    }
  }

  .facebook-login-button-container {
    margin-bottom: 2vh;

    .facebook-login-button {
      color: white;
      text-align: center;
      background-color: #3b5998;
      border-radius: 4px;
      border: solid 1px rgba(151, 151, 151, 0.48);
      padding: 15px;
      width: 100%;
      font-family: "Foco", Arial, sans-serif;
      font-size: 16px;
      text-transform: uppercase;
      font-weight: bold;
      cursor: pointer;
      line-height: 18px;

      span {
        display: inline-block;
        text-align: left;
      }

      img {
        height: 18px;
        margin-right: 20px;
        vertical-align: bottom;
      }

      &:disabled {
        opacity: 0.5;
        cursor: not-allowed;
      }
    }
  }
}
