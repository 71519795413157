.simple-image-cropper {
  width: calc(100% - 20%);
  position: relative;
  text-align: center;
  margin-left: 10%;

  .upload-button {
    background-color: #dce7ec;
    color: rgba(28, 77, 107, 0.41);
    height: 50px;
    width: 50px;
    line-height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: flex-start;
    opacity: 0.6;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 50%;
    cursor: pointer;

    &.hide-button {
      display: none;
    }

    &:hover {
      opacity: 1;
      color: #184f6d;
    }
  }

  .crop-container {
    border-radius: 4px;
    max-width: 100%;
    max-height: 45vh;
    display: block;

    img {
      max-width: 100%;
      max-height: 45vh;
      width: auto;
      height: auto;
      margin-left: auto;
      margin-right: auto;
      display: block;
    }
  }

  .action-area {
    margin: 8px 0;
    display: inline-flex;

    .action-button {
      margin-left: 8px;
      background-color: #dce7ec;
      color: rgba(28, 77, 107, 0.41);
      height: 50px;
      width: 50px;
      line-height: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      align-self: flex-start;
      border-radius: 4px;
      cursor: pointer;

      &.remove-button {
        background-color: rgba(255, 167, 167, 0.15);
        border: 1px solid #ffa7a7;
      }

      &.hide-button {
        display: none;
      }
    }
  }

  &.no-photo {
    margin-top: 8px;
    margin-bottom: 8px;
    padding-top: 35.25%;
    border-radius: 4px;
    border: 2px solid #E0E7EE;
    background-color: hsl(0, 0%, 100%);

    img,
    .action-area {
      display: none;
    }
  }
}