.layout-container {
    max-width: 1244px;
    height: calc(100% - 7em);
    margin: auto;
    position: relative;

    .layout-container-children {
        display: flex;
        height: 100%;
    }
}

.layout-container-large {
    width: 66%;
}

.layout-container-small {
    width: 33%;
}