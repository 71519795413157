.dropdown-list-widget {
    padding-top: 0;
}

.dropdown-list-widget .widget-contents {
    background-color: unset;
    box-shadow: unset;

    p {
        color: #90a1b5;
        font-size: 14px;
        line-height: 14px;
        margin-top: 0;
        margin-bottom: 10px;
    }

    .select-container {
        border: unset;
    }

    .mobile-select-container {
        .mobile-select__option {
            height: 34px;
            background-color: unset;
        }

        .mobile-select__control {
            min-width: 15em;
            width: 100%;
            height: 3em;
            border: 1px solid #e0e7ee;

            &--is-focused {
                box-shadow: unset;
                border: 1px solid #2b8aed;
            }
        }

        .mobile-select__menu {
            margin-top: 5px;
            padding: 4px 0;
            border-radius: 4px;
        }

        &:focus {
            border: unset;
        }

        .mobile-select__indicator-separator {
            width: 0;
        }
    }

    .btn {
        width: 100%;
        margin: 10px 0;
        color: #ffffff;
        font-weight: bold;
        background: linear-gradient(90deg, #2686ed 0%, #5ca7ed 100%);
        border: unset;
        box-shadow: 0 2px 17px -5px rgba(95, 129, 226, 0.4);
        text-transform: uppercase;

        &:disabled {
            opacity: 0.4;
        }
    }
}
