
.save-cnt-btn {
  float: right;
}

.exit-btn {
  float: left;
}

.membership-btn {
  width: 200px;
}

.selected-button{
  background-color: #5EBC5E !important;
  color: white !important;
}



.back-button {
  padding: 10px;
  background-color: transparent;
  box-shadow: none;
}

.addM-column {
  float: left;
  width: 50%;
}

/* Clear floats after the columns */
.row:after {
  content: "";
  display: table;
  clear: both;
}


@media screen and (max-width: 600px) {
  .breadcrumb {
    display: none;
  }
  .form-container {
    padding: 0px 20px 30px;
  }
}

@media screen and (min-width: 600px) {
  .form-container {
    padding: 0px 50px 20px 30px;
  }
}