.add-pass-modal-footer {
    display: flex;
    -ms-flex-direction: column;
    -webkit-flex-direction: column;
    flex-direction: column;
    -webkit-align-items: center;
    align-items: flex-end;
    position: relative;

    .add-pass-modal-footer-alternate {
        margin-bottom: 2vh;
    }

    .add-pass-modal-footer-main {
    }

    p {
        margin-top: 40px;
        align-self: center;
    }
}
