@import '../../../variables';

.important-info-container {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    height: 136px;
    width: 228px;
    border-radius: 8px;
    box-shadow: 0 1px 13px -2px rgba(95, 129, 226, 0.4);
    margin: 0 15px 15px 0;
    max-width: 228px;
    background-color: #fff;

    &:hover {
        cursor: pointer;
    }

    &.sidebar {
        margin-right: 0;
        max-height: 160px;
    }
}

.important-info-container:last-child:after {
    margin: 0;
}
.important-info-header {
    padding: 0 0 0 1.3em;
    color: $corporate-blue;
    font-size: 23px;
    letter-spacing: 0.43px;
    line-height: 26px;
}

.important-info-icon {
    height: 50%;
    color: $corporate-blue;
    font-size: 51px;
    letter-spacing: 0.95px;
    line-height: 51px;
    text-align: center;
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 85px;
}

.important-info-title {
    padding: 5px 15px;
    height: 40px;
    font-family: Foco;
    font-size: 16px;
    font-weight: bold;
    border-top: 1px solid #eeeeee;
    letter-spacing: 0.47px;
    line-height: 17px;

    display: flex;
    align-items: center;
}

.important-info-header-mobile {
    color: $body-colour;
    font-size: 18px;
    letter-spacing: 0.88px;
    line-height: 25px;
    margin: 25px 2em 25px 2.5em;
    text-align: center;
}

.important-info-container-mobile {
    display: flex;
    flex-direction: column;
    flex: 1;
    height: 136px;
    width: 100%;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 1px 13px -2px rgba(95, 129, 226, 0.4);
    margin: 0 auto 35px auto;

    &:last-child {
        margin-bottom: 0;
    }
}

.important-info-title-mobile {
    padding: 5px 15px;
    height: 40px;
    font-family: Foco;
    font-size: 16px;
    font-weight: bold;
    border-top: 1px solid #eeeeee;
    letter-spacing: 0.47px;
    line-height: 17px;

    display: flex;
    align-items: center;
}
