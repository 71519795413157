@import "../../../variables";

.table {
  display: -webkit-flex;
  display: flex;
  flex-direction: column;
  height: 100%;
  flex: 1;

  .table-header,
  .row,
  .title,
  .footer {
    display: flex;
  }

  .header {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    padding-top: 15px;
    .header-filter {
      display: flex;
      padding: 0 40px;
    }
  }

  .title {
    padding: 34px 0;
    color: $corporate-blue;
    font-size: 23px;
    letter-spacing: 0.43px;
    line-height: 26px;
    min-width: 25%;
    box-sizing: border-box;
  }

  .mobile-title {
    color: $corporate-blue;
    font-size: 19px;
    letter-spacing: 1.19px;
    line-height: 20px;
    text-align: center;
    padding: 32px;
    border-bottom: 1px solid #eeeeee;
  }

  .filter {
    width: 50%;
  }

  .description {
    width: 100%;
  }

  .mobile-filter {
    width: unset;
    padding: 18px 5px;
    border-bottom: 1px solid #eeeeee;
  }

  .table-header {
    background-color: #f9fbfc;
    padding: 18px 40px;
    border-top: 1px solid #eeeeee;
  }

  .row {
    padding: 15px 40px;
    border-bottom: 1px solid #eeeeee;
    transition: background-color ease-in-out 0.3s;
    will-change: background-color;
    flex-wrap: wrap;

    &:hover {
      background-color: #f5f6fb;
    }

    &.clickable {
      &:hover {
        cursor: pointer;
      }
    }
  }

  .mobile-row {
    padding: 15px 20px;
    flex-direction: row;
  }

  .no-results-message {
    color: #a0a0a0;
    text-align: center;
    font-size: 23px;
    margin: auto;
    padding: 20px;
  }

  .footer {
    margin-top: auto;
    margin-bottom: 38px;
    padding: 17px;
    color: #a0a0a0;
    font-size: 16px;
    letter-spacing: 0.26px;
    text-align: center;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    span {
      margin-left: 33%;
      width: 33%;
      height: 1em;
    }

    div {
      width: 33%;
      display: flex;
      justify-content: flex-end;
    }
  }

  .column-header,
  .cell {
    flex: 1;
  }

  .column-header {
    color: #8c95a1;
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 0.88px;
    line-height: 15px;
  }

  .cell {
    color: #565656;
    font-size: 16px;
    letter-spacing: 2px;
    line-height: 18px;
    margin: auto;

    &.bold {
      font-weight: bold;
    }
  }

  .mobile-cell.cell {
    color: #565656;
    font-size: 12px;
    letter-spacing: 0.75px;
    line-height: 14px;
    margin-right: 5px;

    &.bold {
      margin-top: 10px;
      margin-right: 0;
      color: $body-colour;
      font-size: 16px;
      letter-spacing: 1px;
      line-height: 18px;
      font-weight: bold;
    }
  }
}

.loader {
  text-align: center;
  padding: 30px;
}
