.news-card {
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 28px -5px rgba(95, 129, 226, 0.4);
    overflow: hidden;
    margin-bottom: 35px;
    max-width: 360px;
    margin-left: auto;
    margin-right: auto;

    &:hover {
        cursor: pointer;
    }

    .news-image {
        height: 130px;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
            width: 100%;
            height: auto;
        }
    }

    .description-container {
        padding: 0 20px;

        .title {
            font-size: 20px;
            font-weight: bold;
            line-height: 25px;
            letter-spacing: 0.6px;
            margin: 0;
            padding: 15px 0;
            border-bottom: 1px solid #eeeeee;
        }

        .publish-date {
            font-size: 14px;
            font-weight: bold;
            line-height: 25px;
            letter-spacing: 0.4px;
            color: #80a2b2;
            margin: 0;
            padding: 5px 0;
        }
    }
}

.news-error {
    @media only screen and (max-width: 1023px) {
        text-align: center;
    }
}
