@import '../../../variables';

.alert-container {
    padding: 20px;
    margin: 0 20px;
    p {
        text-align: left;
        color: $corporate-blue;
        margin: 0;
        font-size: 16px;
    }
}
