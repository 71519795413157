@import '../../../variables';
$header-max-width: 1244px;

.header {
    .header-container {
        margin: 2.5em auto 0;
        width: 100%;
        max-width: $header-max-width;
        z-index: 1;
        position: relative;
        background: #ffffff;
        border-radius: 8px;
        display: flex;

        .header-bars-container {
            min-width: 100px;
            display: flex;
            justify-content: center;
            align-items: center;

            .header-bars {
                color: $primary-button-colour;
                opacity: 0.35;
                font-size: 28px;

                &:hover {
                    cursor: pointer;
                }
            }
        }
    }

    .header-menu {
        max-width: $header-max-width;
        display: flex;
        justify-content: space-around;

        &.open {
            margin: 60px auto 0 auto;
        }

        .nav-option {
            height: 230px;
            flex-basis: 175px;
            margin-top: 0;
            transition: all 0.1s ease-in-out;

            &:hover {
                transform: scale(1.1);
            }

            .widget-contents {
                box-shadow: none;
            }
        }
    }
}

.horizontal-background {
    background: $background-colour;
    background-repeat: no-repeat;
    background-size: cover;
    position: absolute;
    top: 0;
    bottom: 60%;
    left: 0;
    right: 0;
    min-height: 385px;
    transition: bottom ease 0.25s;

    &.header-open {
        @media only screen and (min-width: 1024px) {
            bottom: 40%;
        }

        @media only screen and (min-width: 1440px) {
            bottom: 50%;
        }
    }
}

.header-label {
    background-color: white;
}

.header-logo-container {
    padding: 1em;
    height: 2.5em;

    img {
        height: 100%;
        -khtml-user-select: none;
        -o-user-select: none;
        -moz-user-select: none;
        -webkit-user-select: none;
        user-select: none;
    }
}

.header-item-container {
    height: 4.5em;
    display: flex;
    justify-content: flex-end;
    margin-left: auto;

    .header-item {
        border-left: 1px solid #e3dede;
        text-align: center;
        color: $corporate-blue;
        font-size: 16px;
        display: flex;
        align-items: center;
        padding: 0 2vw;
    }

    .header-item-dropdown {
        @extend .header-item;
        padding: 0;
    }

    .my-details-container {
        transition: all 0.3s;
        opacity: 0;
        visibility: hidden;
        margin-left: 10px;

        svg {
            margin-right: 10px;
            font-size: 23px;
            vertical-align: middle;
        }

        &.show {
            opacity: 1;
            visibility: visible;
        }
    }

    .contract-container {
        height: 100%;
        display: flex;
        justify-content: center;
        flex-direction: column;

        div {
            line-height: normal;
        }

        .contract-end-date {
            color: #7c9fb0;
            font-size: 13px;
            text-transform: uppercase;
            margin-top: 1em;
        }
    }

    & > div {
        transition: background-color ease-in-out 0.3s;
        will-change: background-color;

        &:last-child {
            border-top-right-radius: 8px;
            border-bottom-right-radius: 8px;
        }

        &:hover {
            background-color: #f5f6fb;
        }
    }
}

.header-select-container {
    min-width: 15em;
    line-height: normal !important;

    .header-select__option {
        height: 34px;
        background-color: unset;
        color: $primary-button-colour;

        &:hover {
            background: #f5f6fb;
        }
    }

    .header-select__control {
        min-width: 15em;
        width: 100%;
        height: 4.5em;
        border-radius: 0;
        border: unset;

        @media only screen and (max-width: 1023px) {
            border-radius: 5px;
            height: 50px;
        }

        &--is-focused {
            box-shadow: unset;
            background-color: #f5f6fb;
        }

        &:hover {
            background-color: #f5f6fb;
        }

        .header-select__single-value {
            color: $corporate-blue;
        }
    }

    .header-select__menu {
        margin-top: 10px;
        padding: 4px 0;
        border-radius: 4px;
    }

    &:focus {
        border: unset;
    }

    .header-select__indicator-separator {
        width: 0;
    }
}

.mobile-header-container {
    display: flex;
    width: 100%;
    z-index: 1;
    position: relative;
    color: #fff;
    justify-content: space-between;
    align-items: center;
    height: 50px;
    margin-top: 2em;
    padding: 10px;
    text-transform: uppercase;
    font-size: 14px;
    box-sizing: border-box;

    .mobile-header-lodge {
        flex: 1;
        text-align: center;
    }
}
