.chip {
    background-color: #3f417e;
    color: white;
    height: 4vh;
    line-height: 4vh;
    vertical-align: middle;
    border-radius: 30px;
    padding: 0 12px;
    margin: 5px 5px 5px 0;
    display: flex;
    justify-content: center;
    align-items: center;

    .chip-remove {
        margin-top: 0.25vh;
        margin-left: 5px;
    }

    &.invalid {
        border: 1px solid #ffa7a7;
        color: #ff0000;
        background-color: rgba(255, 167, 167, 0.15);
    }
}
