.contact-modal {
    width: 650px;
    align-items: flex-start;
    overflow: auto;

    @media only screen and (max-width: 1023px) {
        width: 100%;
        position: relative;
    }

    .contact-details-section {
        max-width: 100%;

        .description {
            font-size: 16px;
            line-height: 22px;
        }

        .contact-details {
            display: flex;
            flex-wrap: wrap;

            @media only screen and (max-width: 1023px) {
                flex-direction: column;

                > div {
                    margin-bottom: 25px;
                }
            }

            .title {
                font-weight: bold;
                font-size: 14px;
                line-height: 14px;
                color: #7c9fb0;
                margin-bottom: 10px;
            }

            .value {
                font-size: 20px;
                line-height: 18px;
                margin-bottom: 10px;

                &.address {
                    white-space: pre;
                    line-height: 25px;
                }
            }

            a.value {
                color: #008bff;
            }

            > div {
                flex-basis: 50%;
            }
        }
    }
}
