@import './variables';

.confirm-page-vertical-background {
    background: $background-colour;
    position: relative;
    overflow: hidden;

    img {
        align-self: flex-start;
    }
}

.confirm-page-container {
    display: flex;
    height: 100%;
    background-color: #fff;

    .vertical-background-container {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        flex-direction: column;
        color: #fff;

        .vertical-background-children {
            margin-bottom: 40%;
            min-width: 385px;
            align-self: flex-start;
            margin-left: 15%;
            width: 400px;

            h1 {
                font-size: 69px;
                letter-spacing: 1.29px;
                line-height: 79px;
            }

            p {
                font-size: 23px;
                letter-spacing: 1.44px;
                line-height: 30px;
                color: #fff;
            }
        }
    }
}

.confirm-page-message-container {
    flex: 50% 1;

    div {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-left: 3vw;

        h1 {
            color: $corporate-blue;
            font-size: 23px;
        }

        h1.request-failed {
            color: red;
        }

        h3 {
            color: $corporate-blue;
            font-size: 16px;
            margin-top: 0;
            width: 100%;
        }

        h3.request-failed {
            color: red;
        }
    }
}

.waiting:after {
    overflow: hidden;
    display: inline-block;
    vertical-align: bottom;
    -webkit-animation: ellipsis steps(4, end) 900ms infinite;
    animation: ellipsis steps(4, end) 900ms infinite;
    content: '\2026'; /* ascii code for the ellipsis character */
    width: 0px;
}

@keyframes ellipsis {
    to {
        width: 20px;
    }
}

@-webkit-keyframes ellipsis {
    to {
        width: 20px;
    }
}

.request-link {
    flex: none;
    width: 20vw;
    height: 5vh;
    line-height: 5vh;
    margin-left: 0;
}

.confirm-mobile-background {
    background: $background-colour;
    display: flex;
    align-items: center;
    flex-direction: column;
    color: #fff;
    overflow: auto;

    img.image-background {
        width: unset;
        left: 0;
        bottom: 0;
    }
}

.confirm-page-message-mobile-container {
    div {
        height: 100vh;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        h1 {
            color: #ffffff;
            font-size: 23px;
        }

        h1.request-failed {
            color: #ffffff;
        }

        h3 {
            color: #ffffff;
            font-size: 16px;
            margin-top: 0;
        }

        h3.request-failed {
            color: #ffffff;
        }
    }
}

.request-link-mobile {
    flex: none;
    height: 7vh;
    width: 50vw;
    line-height: 7vh;
}
