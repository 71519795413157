@import '../../../variables';

.pass-cell {
    display: flex;
    padding-bottom: 2px;
    align-items: flex-end;

    .pass-date {
        font-size: 12px;
        font-weight: bold;
        letter-spacing: 0.75px;
        color: #565656;
        line-height: 14px;
        width: 100%;
        margin-bottom: 1vh;
    }

    .pass-name {
        font-size: 16px;
        letter-spacing: 1px;
        color: $primary-button-colour;
        line-height: 18px;
        margin: 0;
        width: 100%;
    }

    .renew-checkbox {
        box-sizing: border-box;
        height: 16px;
        width: 16px;
        margin: 0 10px 2px 2px;
    }
}
