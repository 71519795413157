@import '../../../variables';

.horizontal-background.home-page-background-mobile {
    background: url('/images/Mobile-PalmOverlay-Home.svg'), $background-colour;
    background-position-x: left;
    background-position-y: bottom;
    background-repeat: no-repeat;
    background-size: cover;
    height: 16.5em;
}

.welcome-widget {
    text-align: center;
    z-index: 1;
}

.navigation-scroll {
    overflow-x: scroll;
    display: flex;
    justify-content: flex-start;
    padding: 0 17px;
    flex: 1;

    .widget-mobile-container {
        margin: auto;
        padding: 0 5px;
    }

    .widget.nav-option {
        .widget-contents {
            box-shadow: none;
        }
    }

    &:after {
        // hack to get padding right to work with overflow scroll
        content: '';
        min-width: 1px;
    }
}

.standard-widget-container-mobile {
    .widget-contents {
        text-align: center;
        padding: 15px;
    }

    div {
        text-transform: uppercase;
        color: $body-colour;
        font-size: 16px;
        font-weight: bold;
        letter-spacing: 0.94px;
        line-height: 16px;
    }

    .standard-widget-text {
        color: #7c9fb0;
        font-size: 14px;
        margin-top: 0.5em;
    }
}