.toggle-container {
    display: inline-block;
    height: 21px;
    min-width: 39px;
    border-radius: 12px;
    vertical-align: middle;
    margin-right: 10px;
    background-color: rgba(124,159,176,0.2);
    transition: background-color ease-in-out 0.3s;
    will-change: background-color;
    cursor: pointer;

    .toggle-knob {
        height: 21px;
        width: 21px;
        border-radius: 12px;
        border: none;
        background-color: rgba(124,159,176,0.4);
        transition: background-color ease-in-out 0.3s, transform ease-in-out 0.3s;
        will-change: background-color, transform;
        cursor: pointer;
        vertical-align: inherit;

        &:focus {
            outline: none;
        }

        &:hover {
            box-shadow: 0 2px 4px 1px rgba(124,159,176,0.2);
        }
    }

    &.active {
        background-color: #FFE2BB;
        box-shadow: 0 2px 51px 2px rgba(0,0,0,0.08);

        .toggle-knob {
            background-color: #FBB03C;
            transform: translate(18px, 0);

            &:hover {
                box-shadow: 0 2px 4px 1px #f1af4a;
            }
        }
    }

    &.pending.active {
        background-color: #EBEBEB;
        box-shadow: 0 2px 51px 2px rgba(0,0,0,0.08);

        .toggle-knob {
            background-color: rgba(124, 159, 176, 0.4);
            transform: translate(18px, 0);

            &:hover {
                box-shadow: 0 2px 4px 1px #EBEBEB;
            }
        }
    }
}
