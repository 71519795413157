@import '../../../variables';

.important-info-body {
    padding: 38px 38px 0 38px;

    > .title {
        color: $corporate-blue;
        margin-top: 8px;
        margin-bottom: 38px;
    }

    p {
        margin-bottom: 16px;
    }

    @media only screen and (max-width: 767px) {
        padding: 20px;

        p {
            font-size: 15px;
            line-height: 24px;
            letter-spacing: 0.94px;
        }
    }
}
.info-back-button {
    padding: 38px;
    @media only screen and (max-width: 767px) {
        padding: 20px;
    }
}
