.mobile-clickable-header-item {
    display: inline-block;
    text-align: center;
    padding: 0.5em;

    svg {
        font-size: 35px;
        color: white;
    }
}

.right {
    position: absolute;
    right: 0;
    top: 0;
}

.icon-previous,
.icon-next {
    border: none;
    border-radius: 0;
    font-size: 20px;
    letter-spacing: 1.25px;
    line-height: 20px;
    text-align: center;
    background-color: rgba(227, 227, 227, 0.6);
    padding: 15px;
    flex: 0;
    cursor: pointer;
    min-width: 20px;

    &.disabled {
        cursor: not-allowed;
        opacity: 0.5;
    }
}

.icon-previous {
    margin-right: 5px;
}

.icon-next {
    margin-left: 5px;
}

.transaction-icon {
    height: 30px;
    width: 30px;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: center;
    flex-shrink: 0;
}
