.nav-option-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  //padding: 10px;

  @media only screen and (max-width: 1023px) {
    padding: 0;
  }

  p {
    font-size: 15px;
    letter-spacing: 0.95px;
    line-height: 20px;
    font-weight: bold;
    //color: #6E7490;
    text-transform: uppercase;
    text-align: center;
    margin-bottom: 0;
    margin-top: auto;
    padding-top: 10px;
    flex: 1;
    width: 100%;

    @media only screen and (max-width: 1023px) {
      line-height: 17px;
      font-size: 15px;
      letter-spacing: 0.75px;
      flex: none;
    }
  }

  .icon-container {
    flex: 5;
    display: flex;
    align-items: center;

    .icon {
      background: rgba(235, 236, 241, 0.7);
      border-radius: 50%;
      box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0);
      width: 70px;
      height: 70px;
      display: flex;
      justify-content: center;
      align-items: center;
      overflow: hidden;

      @media only screen and (max-width: 1023px) {
        width: 80px;
        height: 80px;
      }

      img {
        width: 50px;
        height: 50px;

        @media only screen and (max-width: 1023px) {
          width: 50px;
          height: 50px;
        }
      }
    }
  }
}
