.add-pass-modal {
    position: relative;
    overflow-y: auto;
    max-height: 650px;

    button {
        flex: none;
        width: 170px;
        margin-top: 0;

        @media only screen and (max-width: 1023px) {
            align-self: flex-end;
        }
    }

    .react-datepicker {
        button {
            width: 10px;
        }
    }

    .add-pass-modal-header {
        text-align: left;
    }

    .error-message {
        margin-bottom: 0;
        line-height: 36px;
        position: absolute;
        bottom: 250px;
    }

    &.mobile-modal {
        overflow: auto;

        .add-pass-input {
            width: 100%;
        }

        .error-message {
            position: static;
            line-height: 20px;
        }

        .add-pass-modal-footer {
            min-height: 250px;
        }

        .add-pass-field {
            flex-basis: 100% !important;
            margin-left: 0 !important;
            margin-right: 0 !important;
            padding: 10px 0 !important;
        }

        .select-container {
            margin: 8px 0;
            border-left: 0;
            width: 100%;
        }
    }

    .add-pass-row {
        display: flex;
        flex-wrap: wrap;
    }

    .add-pass-field {
        display: flex;
        flex-direction: column;
        padding: 10px;
        box-sizing: border-box;

        label {
            color: #7c9fb0;
            font-size: 14px;
            font-weight: bold;
            line-height: 14px;
        }
    }

    .delete-pass-row {
        display: flex;
        flex: 1;
        justify-content: flex-end;
        align-items: flex-end;
        padding: 10px;

        div {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 50px;
            width: 50px;
            max-width: 50px;
            margin: 8px 0;
            border: 1px solid #ffa7a7;
            border-radius: 4px;
            background-color: rgba(255, 167, 167, 0.15);
            cursor: pointer;

            svg {
                color: rgba(28, 77, 107, 0.2);
                font-size: 20px;
            }
        }
    }

    .add-pass-input {
        font-size: 24px;
        text-align: center;
        width: 400px;
        padding: 8px 0;
        box-sizing: content-box;

        &:active,
        &:focus {
            border-width: 2px;
        }
    }

    .select-container {
        margin: 8px 0;
        border-left: 0;
        width: 225px;
        height: 50px;

        > div:first-of-type {
            height: 100%;

            .pass-length__control {
                height: 100%;

                .pass-length__indicator-separator {
                    background-color: #e0e7ee;
                }
            }
        }
    }

    .add-pass-photo {
        width: calc(100% -2px);
        position: relative;

        .upload-button {
            background-color: #dce7ec;
            color: rgba(28, 77, 107, 0.41);
            height: 50px;
            width: 50px;
            line-height: 20px;
            display: flex;
            justify-content: center;
            align-items: center;
            align-self: flex-start;
            opacity: 0.6;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            border-radius: 50%;
            cursor: pointer;

            &:hover {
                opacity: 1;
                color: #184f6d;
            }

            &.disabled:hover {
                cursor: auto;
                opacity: 0.6;
                color: rgba(28, 77, 107, 0.41);
            }
        }

        img {
            width: 100%;
            margin: 8px 0;
            border-radius: 4px;
            border: 1px solid #E0E7EE;
        }

        &.no-photo {
            margin: 8px 0;
            padding-top: 128%;
            border-radius: 4px;
            border: 1px solid #E0E7EE;
            background-color: hsl(0,0%,100%);
            cursor: pointer;
            img {
                display: none;
            }
        }
    }

    .upload-check-box {
        input {
            accent-color: #1b4e6b;
            width: 20px;
            height: 20px;
        }

        label {
            font-weight: normal;
        }
    }

    .add-pass-modal-fields-seperator {
        border: 1px solid #eeeeee;
        margin-top: 2vh;
        margin-bottom: 2vh;
    }
}
