@import "../../../variables";

.request-and-queries-ticket {
  text-align: center;
  padding-top: 12px;
  font-size: 23px;
  line-height: 26px;
  letter-spacing: 0.43px;
  color: $corporate-blue;
}

.request-and-queries-btn {
  max-height: 3em;
  min-height: 3em;
  margin: 0 1.5em 0;
}
.request-and-queries-btn-mobile {
  min-height: 3em;
}
.request-and-queries-details {
  text-align: center;
  padding-top: 12px;
  font-size: 20px;
  line-height: 26px;
  letter-spacing: 0.43px;
  color: #184f6d;
  margin-bottom: 0;
}
.request-and-queries-request-details {
  text-align: center;
  font-size: 20px;
  line-height: 26px;
  letter-spacing: 0.43px;
  color: #184f6d;
}

.conversations-container {
  background-color: #f1f2f5 !important;

  .ticket-info {
    border-spacing: 0px;
    border-radius: 8px;
    overflow: hidden;

    tr {
      height: 50px;

      th,
      td {
        font-size: 14px;
        letter-spacing: 0.88px;
        line-height: 15px;
        text-align: center;
      }

      td {
        background-color: #dce7ec;
      }

      &.header-row {
        background-color: #f9fbfc;

        th {
          color: #8c95a1;
          font-weight: bold;
        }
      }
    }
  }

  .conversations {
    padding: 5px;
    padding-bottom: 80px;

    .quick-reply-button {
      margin: 15px;
      margin-bottom: 0;
      padding: 1px 10px;
    }

    .conversation {
      background-color: white;
      margin: 15px;
      padding: 10px 20px;
      padding-bottom: 20px;
      border-radius: 8px;
      position: relative;

      &.private {
        background-color: #ffe5e5;
        border: 1px solid #ff5252;
      }

      h3 {
        color: #3f417e;
        margin: 10px 0px;
        margin-bottom: 0;
      }
      .strapline {
        font-size: 15px;
        color: #565656;
        margin-bottom: 10px;
      }

      .content {
        color: black;
        margin-bottom: 10px;
      }

      time {
        color: #707a8e;
      }

      .time-desk {
        position: absolute;
        right: 20px;
        top: 20px;
      }

      .time-mob {
        display: flex;
        flex-direction: column;

        time {
          align-self: flex-end;
        }
      }

      .attachment-container {
        display: flex;

        .selectable-chip {
          font-size: 15px;
          height: 30px;
        }
      }
    }
  }
}

.requests-and-queries {
  .back-button {
    bottom: 20px;
    left: 50%;
    position: fixed;
  }

  &.layout-mobile {
    .widget-layout-message {
      grid-row-start: 3;
    }
    .widget-layout-title {
      margin-top: 0;
      grid-column-end: span 12;
    }
  }
}
