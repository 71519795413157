@import '../../../variables';

.item-details-modal {
    min-width: 450px;
    max-width: 675px;
    min-height: 350px;
    position: relative;
    width: 98%;
    overflow-y: auto;

    .item-details-content {
        width: 100%;

        @media only screen and (max-width: 480px) {
            // Prevent heading being cut off on iPhone SE
            margin-top: 25px;
        }
    }

    .item-details-main {
        div:first-child {
            flex: 4;
        }

        .event-image-container {
            flex: 3;
            max-height: 162px;
            overflow: hidden;
            border-radius: 5px;
            box-shadow: 0 2px 28px -5px rgba(95, 129, 226, 0.4);

            > img {
                margin: 0;
            }

            .item-image {
                width: auto;
                height: 100%;
                object-fit: contain;
            }

            @media only screen and (max-width: 1023px) {
                min-width: 0;
                width: calc(100% + 40px);
                max-width: 100%;
            }
        }

        @media only screen and (max-width: 1023px) {
            padding: 0 !important;
        }
    }

    .item-details-main,
    .item-details-summary {
        padding: 2em 0;
        display: flex;
        flex-direction: row;

        @media only screen and (max-width: 1023px) {
            flex-direction: column-reverse;
        }
    }

    @media only screen and (max-width: 1023px) {
        min-width: 0;
        max-height: 100%;
        overflow-y: scroll;
    }

    .item-details-summary {
        padding-top: 0;
    }

    .item-details-summary-column {
        width: 100%;
    }

    .item-details {
        flex: 1;
        padding-right: 16px;

        .item-title {
            color: #80a2b2;
            font-size: 14px;
            font-weight: bold;
            margin: 1em 0;
            letter-spacing: 0.9px;
            width: 100%;
        }

        .item-details-description {
            color: $body-colour;
            font-size: 16px;
            line-height: 22px;
            padding-right: 20px;
            white-space: pre-line;

            @media only screen and (max-width: 1023px) {
                height: initial;
                padding-right: 0;
            }
        }

        .item-subheading {
            letter-spacing: 1.2px;
            line-height: 24px;
            font-size: 19px;
        }

        .item-details-phone-number {
            color: $body-colour;
            font-size: 20px;
            line-height: 18px;
        }

        .item-description {
            font-weight: bold;
            letter-spacing: 0.9px;
            white-space: pre-line;
        }
    }
}

.back-button-calendar-items {
    position: relative;
}

.action-button-calendar-items {
    float: right;
    margin: 0px 8px;
    min-width: 100px;
    max-width: 180px;
}
