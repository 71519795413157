.details-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    border-top: 1px solid #eeeeee;
    border-bottom: 1px solid #eeeeee;
    flex: 1;
    width: 100%;

    .detail-row {
        width: calc(50% - 2em);
    }

    .detail-row:last-child {
        border-bottom: none;
    }
}

.details-container-mobile {
    display: flex;
    flex-direction: column;
    border-top: 1px solid #eeeeee;
    margin-right: 1em;

    .detail-row {
        padding-left: 0;
    }
}

.detail-row {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    border-bottom: 1px solid #eeeeee;
    padding-left: 2em;

    span {
        min-height: 2em;
        width: 50%;
        display: inline-flex;
        align-items: center;
    }

    span:first-child {
        font-size: 16px;
        font-weight: bold;
        letter-spacing: 1px;
        line-height: 18px;
        padding: 1em 0;
    }

    span:last-child {
        font-size: 16px;
        letter-spacing: 1px;
        line-height: 18px;
        padding: 1em 1em 1em 0;
    }
}
