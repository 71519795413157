.welcome-widget .widget-contents {
    background-color: transparent;
    box-shadow:none;

    color: #FFFFFF;
    font-family: Foco;
    font-size: 32px;
    letter-spacing: 0.6px;
    line-height: 36px;
}
