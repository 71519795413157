.add-booking-modal {
    width: 650px;
    overflow-x: hidden;
    overflow-y: auto;

    form {
        width: 100%;

        .modal-header {
            margin-bottom: 20px;
        }

        .add-booking-field, .add-booking-field-full {
            display: inline-block;
            width: 50%;
            margin-top: 25px;
            margin-bottom: 15px;
            vertical-align: top;

            label {
                color: #7c9fb0;
                font-size: 14px;
                font-weight: bold;
                word-wrap: break-word;
                display: block;
            }

            input {
                width: 90%;
            }

            .react-datepicker-wrapper {
                width: 100%;
            }

            .select-container {
                margin: 8px 0;
                width: 90%;

                div [class*='control'] {
                    border: 1px solid #e0e7ee;
                    height: 50px;
                }
            }

            &-full{
                
                width: 100%;
            }
        }

        div[data-radium] {
            margin-bottom: 2vh;
        }

        .helper-text{
            position: relative;
            top: -1vh;
            color:transparent;

            &.show{
                color: inherit;
            }
        }
        .error-message {
            font-size: 16px;
            line-height: inherit;
            white-space: pre-wrap;
        }
    }
    .modal-footer {
        margin-top: 0;
    }
}

.add-booking-modal-mobile {
    max-width: 100%;
    width: 100%;
    position: relative;
    overflow-x: hidden;
    overflow-y: auto;

    .add-booking-modal-mobile-form {
        .info-message {
            width: 95%;
            margin-bottom: 20px;
        }

        .add-booking-field, .add-booking-field-full {
            display: block;
            margin-bottom: 25px;
            width: 100%;

            label {
                color: #7c9fb0;
                font-size: 14px;
                font-weight: bold;
                word-wrap: break-word;
                display: block;
            }

            input {
                width: 100%;
            }

            .select-container {
                margin: 8px 0;

                div [class*='control'] {
                    border: 1px solid #e0e7ee;
                    height: 50px;
                }
            }

            &:last-of-type {
                margin-bottom: 45px;
            }
        }

        div[data-radium] {
            margin-bottom: 2vh;
        }

        .error-message {
            font-size: 16px;
            line-height: inherit;
            white-space: pre-wrap;
        }
    }
}

.add-booking-calendar-widget {
    height: 340px;

    .add-booking-calendar {
        padding: 50px 0px 0px 0px;

        .rbc-toolbar {
            display: flex;
        }
    
        .rbc-event {
            cursor: default;
        }
    }

    .calendar-keys {
        border-top: none;
        height: 0 !important;
    }

    &.mobile {
        margin-bottom: 20px;
    }
}
