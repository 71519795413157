@import '../../../variables';

.mobile-side-nav {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1000;
    overflow-y: scroll;

    .mobile-side-nav-content {
        background: rgba($background-colour, 0.8);
        padding: 10px;
        padding-top: 28px;
        min-height: calc(100% - 38px);
        display: flex;
        overflow-x: hidden;

        .mobile-side-nav-close {
            color: #fff;
            font-size: 35px;
            padding: 7px;
            width: 31px;
            text-align: center;

            &.hover {
                cursor: pointer;
            }
        }

        .mobile-side-nav-options {
            overflow: hidden;
            display: flex;
            align-content: flex-start;
            flex-wrap: wrap;

            .widget-contents {
                box-shadow: none;
            }

            .select-container {
                flex-basis: 100%;
                border-left: none;
                margin-bottom: 25px;
            }

            > * {
                flex-basis: 120px;
            }

            .nav-option {
                margin-right: 25px;
                margin-bottom: 10px;
            }
        }

        .mobile-side-nav-widget-container-mobile {
            padding-left: 0;

            .widget-contents {
                div {
                    text-transform: uppercase;
                    color: $body-colour;
                    font-size: 16px;
                    font-weight: bold;
                    letter-spacing: 0.94px;
                    line-height: 50px;
                    min-width: 15em;
                    width: 100%;
                    height: 50px;
                    text-align: center;
                    vertical-align: middle;
                }
            }
        }
    }
}

.nav-option {
    box-shadow: 0 5px 8px 4px rgba(25, 25, 104, 0.17);
    height: 170px;
    border-radius: 12px;
    background: #fff;
    margin-top: 15px;

    &:hover {
        cursor: pointer;
    }
}
