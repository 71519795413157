@font-face {
  font-family: "Foco";
  font-weight: bolder;
  src: url(/static/media/Foco_Blk.64759234.ttf) format("truetype"); }

@font-face {
  font-family: "Foco";
  font-weight: bolder;
  font-style: italic;
  src: url(/static/media/Foco_BlkIt.a5766daf.ttf) format("truetype"); }

@font-face {
  font-family: "Foco";
  font-weight: bold;
  src: url(/static/media/Foco_Bd.6bc49616.ttf) format("truetype"), url(/static/media/Foco-Bold.8d234162.otf) format("opentype"); }

@font-face {
  font-family: "Foco";
  font-weight: bold;
  font-style: italic;
  src: url(/static/media/Foco_BdIt.cae407cc.ttf) format("truetype"); }

@font-face {
  font-family: "Foco";
  font-weight: lighter;
  src: url(/static/media/Foco_Lt.7dee2e6a.ttf) format("truetype"); }

@font-face {
  font-family: "Foco";
  font-weight: lighter;
  font-style: italic;
  src: url(/static/media/Foco_LtIt.18bc0950.ttf) format("truetype"); }

@font-face {
  font-family: "Foco";
  font-weight: normal;
  font-style: italic;
  src: url(/static/media/Foco_It.398bdd08.ttf) format("truetype"); }

@font-face {
  font-family: "Foco";
  font-weight: normal;
  src: url(/static/media/Foco-Regular.4c219844.ttf) format("truetype"), url(/static/media/Foco-Regular.838c87ec.otf) format("opentype"); }

html,
body {
  height: 100%;
  background-color: #f5f7f9; }

#root {
  height: 100%;
  margin-top: -2.5em;
  padding-top: 2.5em;
  margin-left: calc(100vw - 100%); }

body {
  margin: 0;
  font-family: "Foco", Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #184f6d; }

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace; }

h1 {
  color: #fff;
  font-size: 29px;
  letter-spacing: 0.54px;
  line-height: 32px;
  font-weight: normal;
  margin-top: 37px;
  margin-bottom: 12px; }

p {
  color: #184f6d;
  font-size: 18px;
  line-height: 22px;
  margin-top: 0;
  margin-bottom: 30px; }

.contentful-text p {
  margin-bottom: 0px;
  font-size: 14px;
  text-align: left; }

.contentful-text ul li p {
  margin-bottom: 0px;
  font-size: 14px; }

.contentful-text-payment p {
  margin-bottom: 0px;
  font-size: 16px;
  text-align: left; }

.contentful-text-payment ul li p {
  margin-bottom: 0px;
  font-size: 16px; }

button::-moz-focus-inner {
  border: 0; }

table {
  border-spacing: 0; }

thead {
  background-color: #f9fbfc; }

thead th {
  color: #8c95a1;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0.88px;
  line-height: 15px;
  text-align: left;
  padding: 18px 40px;
  border-top: 1px solid #eeeeee; }
  @media only screen and (max-width: 1023px) {
    thead th {
      padding: 18px 20px; } }

tbody td {
  padding: 14px 40px;
  border-bottom: 1px solid #eeeeee;
  line-height: 22px;
  color: #1b4e6b; }
  @media only screen and (max-width: 1023px) {
    tbody td {
      padding: 14px 20px; } }

.link-right {
  color: #1b4e6b;
  text-decoration: none; }
  @media only screen and (max-width: 1023px) {
    .link-right {
      color: #fff; } }

.heading-center {
  display: block;
  text-align: center;
  color: #1b4e6b;
  position: relative;
  margin-bottom: 0; }
  @media only screen and (max-width: 1023px) {
    .heading-center {
      color: #fff; } }

.heading-right {
  display: block;
  text-align: right;
  color: #1b4e6b;
  position: relative;
  margin-bottom: 0; }
  @media only screen and (max-width: 1023px) {
    .heading-right {
      color: #fff; } }

.form-input, .form-input-alternative {
  margin: 8px 0;
  border-radius: 4px;
  padding-left: 16px;
  box-sizing: border-box;
  font-size: 16px;
  letter-spacing: 1px;
  height: 40px;
  line-height: 50px;
  font-family: "Foco", Arial, sans-serif;
  background-color: #fff;
  border: 1px solid #e0e7ee;
  color: #184f6d;
  -webkit-transition: color ease-in-out 0.3s, border-color ease-in-out 0.3s, box-shadow ease-in-out 0.3s;
  transition: color ease-in-out 0.3s, border-color ease-in-out 0.3s, box-shadow ease-in-out 0.3s;
  will-change: color, border-color, box-shadow; }
  .form-input::-webkit-input-placeholder, .form-input-alternative::-webkit-input-placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #a0a0a0;
    opacity: 1;
    /* Firefox */ }
  .form-input::-moz-placeholder, .form-input-alternative::-moz-placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #a0a0a0;
    opacity: 1;
    /* Firefox */ }
  .form-input:-ms-input-placeholder, .form-input-alternative:-ms-input-placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #a0a0a0;
    opacity: 1;
    /* Firefox */ }
  .form-input::-ms-input-placeholder, .form-input-alternative::-ms-input-placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #a0a0a0;
    opacity: 1;
    /* Firefox */ }
  .form-input::placeholder, .form-input-alternative::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #a0a0a0;
    opacity: 1;
    /* Firefox */ }
  .form-input:-ms-input-placeholder, .form-input-alternative:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #a0a0a0; }
  .form-input::-ms-input-placeholder, .form-input-alternative::-ms-input-placeholder {
    /* Microsoft Edge */
    color: #a0a0a0; }
  .form-input:active, .form-input-alternative:active, .form-input:focus, .form-input-alternative:focus {
    outline: none;
    border: 1px solid #45417b;
    box-shadow: 0 2px 4px 0 #c2bfde; }
  .form-input.invalid, .invalid.form-input-alternative {
    border: 1px solid #ff5252;
    box-shadow: 0 2px 4px 0 rgba(255, 82, 82, 0.3);
    color: #ff647c; }

.form-input-alternative {
  border: 1px solid #fff;
  background-color: #e8f0fe;
  color: #184f6d; }
  .form-input-alternative::-webkit-input-placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: rgba(24, 79, 109, 0.6);
    opacity: 1;
    /* Firefox */ }
  .form-input-alternative::-moz-placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: rgba(24, 79, 109, 0.6);
    opacity: 1;
    /* Firefox */ }
  .form-input-alternative:-ms-input-placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: rgba(24, 79, 109, 0.6);
    opacity: 1;
    /* Firefox */ }
  .form-input-alternative::-ms-input-placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: rgba(24, 79, 109, 0.6);
    opacity: 1;
    /* Firefox */ }
  .form-input-alternative::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: rgba(24, 79, 109, 0.6);
    opacity: 1;
    /* Firefox */ }
  .form-input-alternative:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: rgba(24, 79, 109, 0.6); }
  .form-input-alternative::-ms-input-placeholder {
    /* Microsoft Edge */
    color: rgba(24, 79, 109, 0.6); }
  .form-input-alternative:active, .form-input-alternative:focus {
    outline: none; }

.widget-container, .widget-container-top {
  display: -ms-grid;
  display: grid;
  width: calc(100% + 20px);
  -ms-grid-columns: (minmax(max-content, 1fr))[12];
  grid-template-columns: repeat(12, minmax(auto, 1fr));
  -ms-grid-rows: ( minmax(75px, max-content) )[12]; }

.widget-container-top {
  -ms-grid-rows: ( minmax(75px, max-content) )[1];
  grid-template-rows: repeat(1, minmax(75px, auto));
  height: auto;
  height: initial;
  margin-bottom: 30px; }

.mobile-widget-container {
  grid-template-rows: repeat(12, auto);
  width: 100%;
  margin: 0; }
  .mobile-widget-container .widget-layout-title {
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-column-span: 12;
    grid-column-end: span 12;
    -ms-grid-row: 1;
    grid-row-start: 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    text-align: center;
    margin-top: 15px; }
  .mobile-widget-container .widget-layout-message {
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-column-span: 12;
    grid-column-end: span 12;
    -ms-grid-row: 2;
    grid-row-start: 2;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    margin: 0 22px 30px;
    letter-spacing: 0.8px;
    line-height: 25px;
    height: 125px; }
  .mobile-widget-container .widget {
    margin: 0; }

.mobile-home-widget-container {
  width: 100%;
  margin: auto;
  max-width: 500px; }

.widget-layout-title {
  -ms-grid-column: 1;
  grid-column-start: 1;
  -ms-grid-column-span: 8;
  grid-column-end: span 8;
  -ms-grid-row: 1;
  grid-row-start: 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  margin-left: 17px;
  margin-right: 17px; }

.widget-layout-message {
  -ms-grid-column: 1;
  grid-column-start: 1;
  -ms-grid-column-span: 12;
  grid-column-end: span 12;
  -ms-grid-row: 2;
  grid-row-start: 2;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  margin-left: 17px;
  margin-right: 17px;
  color: #fff; }
  .widget-layout-message p {
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-column-span: 12;
    grid-column-end: span 12;
    -ms-grid-row: 2;
    grid-row-start: 2;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    margin-left: 17px;
    margin-right: 17px;
    color: #fff; }

.error-message, .info-message {
  padding: 8px;
  min-height: 36px;
  margin-bottom: 2vh;
  line-height: 36px;
  border-radius: 4px;
  text-align: center;
  color: transparent; }
  .error-message.show, .show.info-message {
    border-radius: 8px;
    border: 1px solid #ffa7a7;
    color: #ff0000;
    background-color: rgba(255, 167, 167, 0.15); }

.error-message-mobile, .info-message-mobile {
  min-height: 54px;
  margin: 15px 0 -15px;
  text-align: center;
  padding: 8px;
  color: transparent; }
  .error-message-mobile.show, .show.info-message-mobile {
    color: #184f6d;
    font-weight: bold; }

.info-message.show {
  font-size: 16px;
  border-radius: 8px;
  border: 1px solid #7c9fb0;
  color: #184f6d;
  background-color: rgba(27, 78, 107, 0.05); }

.info-message-mobile.show {
  color: #184f6d;
  font-weight: bold; }

.horizontal-background.mobile-background {
  min-height: 285px; }

.menu-item-title {
  background-color: #fff;
  text-align: center;
  margin-top: auto;
  text-transform: uppercase;
  font-size: 15px;
  font-weight: bold;
  letter-spacing: 0.94px;
  line-height: 16px;
  height: 16px;
  color: #74757a;
  padding: 17px 0;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  display: block;
  width: 100%; }

.message-modal.mobile-success-modal {
  padding: 40px 50px; }

.button-group {
  display: flex;
  align-items: center; }
  .button-group .btn,
  .button-group .btn.btn-secondary {
    max-width: 128px;
    max-height: 30px;
    margin: 0;
    border-radius: 0;
    border: 1px solid rgba(28, 77, 107, 0.19);
    text-transform: none;
    font-size: 15px;
    font-weight: normal;
    color: #184f6d; }
    .button-group .btn:hover,
    .button-group .btn.btn-secondary:hover {
      background-color: #dde7f5 !important; }
    .button-group .btn.active:hover,
    .button-group .btn.btn-secondary.active:hover {
      box-shadow: 0 2px 17px -5px rgba(95, 129, 226, 0.4); }
    .button-group .btn.active,
    .button-group .btn.btn-secondary.active {
      box-shadow: unset;
      border: 1px solid #e1e3e8;
      background-color: #e7effc; }
    .button-group .btn:first-child,
    .button-group .btn.btn-secondary:first-child {
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px; }
    .button-group .btn:last-child,
    .button-group .btn.btn-secondary:last-child {
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px; }

.pagination-icons {
  display: flex;
  position: absolute;
  top: 20px;
  right: 20px; }
  .pagination-icons .icon-previous,
  .pagination-icons .icon-next {
    border: 1px solid #cdcdcd;
    border-radius: 4px;
    background-color: #ffffff;
    box-shadow: 0 2px 17px -5px rgba(95, 129, 226, 0.4);
    padding: 5px; }

.mobile-widget-container .pagination-icons {
  top: 102px;
  right: 20px; }

.mobile-select__menu {
  z-index: 1000 !important; }

.kill-scroll {
  overflow: hidden;
  -webkit-overflow-scrolling: touch;
  position: fixed; }

.widget-ie-fix {
  display: block !important; }
  .widget-ie-fix > .widget-contents {
    display: block !important;
    overflow: hidden; }

.cookie-button {
  width: 175px; }

.error-boundary {
  background-color: rgba(30, 33, 113, 0.2);
  display: flex;
  height: 100%;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center; }
  .error-boundary h1 {
    color: #1e2171;
    font-family: Foco;
    font-size: 35px;
    font-weight: bold;
    letter-spacing: 0.65px;
    line-height: 40px;
    text-align: center; }
  .error-boundary p {
    font-family: Foco;
    font-size: 18px;
    letter-spacing: 1px;
    line-height: 22px;
    max-width: 800px;
    text-align: center;
    margin: 15px 15px 30px; }
  .error-boundary .btn.btn-primary {
    max-height: 50px;
    width: 250px;
    margin-top: 25px; }

.btn {
  flex: 1 1;
  height: 50px;
  border-radius: 4px;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 1px;
  margin: 8px;
  cursor: pointer;
  font-family: 'Foco', Arial, sans-serif;
  -webkit-transition: background-color 200ms ease-in-out;
  transition: background-color 200ms ease-in-out; }
  .btn .btn-icon {
    margin-bottom: 1vh;
    color: #1e2171; }
  .btn:active, .btn:focus {
    outline: none; }
  .btn[disabled] {
    opacity: 0.5;
    cursor: not-allowed;
    box-shadow: 0 3px 11px 0 rgba(28, 77, 107, 0.2); }
  .btn.btn-primary, .btn.btn-cancel {
    background-color: #1b4e6b;
    color: #fff;
    border: none;
    text-transform: uppercase;
    box-shadow: 0 5px 7px -3px rgba(133, 133, 133, 0.5); }
    .btn.btn-primary:hover:not([disabled]), .btn.btn-cancel:hover:not([disabled]) {
      background-color: #143a50;
      box-shadow: 0 5px 20px 0 rgba(133, 133, 133, 0.5); }
    .btn.btn-primary.btn-ripple div, .btn.btn-ripple.btn-cancel div {
      border: 4px solid rgba(255, 255, 255, 0.75); }
  .btn.btn-outline {
    background-color: transparent;
    color: #fff;
    border: 1px solid #fff;
    text-transform: uppercase; }
    .btn.btn-outline:hover:not([disabled]) {
      background-color: #ffffff33;
      box-shadow: 0 10px 20px rgba(255, 255, 255, 0.1), 0 6px 6px rgba(255, 255, 255, 0.1); }
  .btn.btn-secondary {
    background-color: #fff;
    color: rgba(28, 77, 107, 0.75);
    border: 1px solid #cdcdcd;
    text-transform: uppercase;
    box-shadow: 0 5px 11px 0 rgba(135, 145, 151, 0.2); }
    .btn.btn-secondary:hover:not([disabled]) {
      background-color: #f5f6fb;
      box-shadow: 0 6px 15px 0 rgba(135, 145, 151, 0.3); }
  .btn.btn-cancel {
    opacity: 0.7; }
  .btn.btn-ripple {
    display: inline-block;
    position: relative; }
  .btn.btn-ripple div {
    position: absolute;
    border: 4px solid rgba(28, 77, 107, 0.75);
    opacity: 1;
    border-radius: 50%;
    -webkit-animation: btn-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
            animation: btn-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite; }
  .btn.btn-ripple div:nth-child(2) {
    -webkit-animation-delay: -0.5s;
            animation-delay: -0.5s; }

@-webkit-keyframes btn-ripple {
  0% {
    top: 18px;
    right: 30px;
    width: 0;
    height: 0;
    opacity: 1; }
  100% {
    top: -1px;
    right: 11px;
    width: 40px;
    height: 40px;
    opacity: 0; } }

@keyframes btn-ripple {
  0% {
    top: 18px;
    right: 30px;
    width: 0;
    height: 0;
    opacity: 1; }
  100% {
    top: -1px;
    right: 11px;
    width: 40px;
    height: 40px;
    opacity: 0; } }
  .btn.full-width {
    width: 100%;
    margin-left: 0;
    margin-right: 0; }

.btn-link {
  font-size: 16px;
  font-weight: bold;
  line-height: 19px;
  letter-spacing: 1px;
  cursor: pointer;
  font-family: 'Foco', Arial, sans-serif;
  color: #004bff;
  background: none;
  border: none; }
  @media only screen and (max-width: 1023px) {
    .btn-link {
      color: #fff; } }

.back-button-container {
  text-align: right;
  width: 100px; }
  .back-button-container a,
  .back-button-container button {
    outline: none;
    color: #1b4e6b;
    font-size: 20px;
    letter-spacing: 1.25px;
    line-height: 33px;
    text-decoration: none;
    text-transform: uppercase;
    background: none;
    padding: 0;
    border: none;
    font-family: Foco, Arial, Helvetica, sans-serif;
    cursor: pointer; }
    .back-button-container a .back-button-arrow,
    .back-button-container button .back-button-arrow {
      margin-right: 15px; }
    @media only screen and (max-width: 1023px) {
      .back-button-container a,
      .back-button-container button {
        color: #fff; } }

.footer-button {
  margin: 0 0 0 auto;
  max-width: 200px;
  flex: 100% 1; }

.modal-back-button {
  box-shadow: 0 5px 11px 0 rgba(185, 193, 197, 0.2);
  background-color: #dce7ec;
  color: rgba(28, 77, 107, 0.41);
  height: 50px;
  width: 50px;
  line-height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-transition: 200ms ease-in-out;
  transition: 200ms ease-in-out; }
  .modal-back-button:hover {
    cursor: pointer;
    background-color: #d1dce1; }

.btn-tertiary {
  padding: 20px 0;
  color: #a0a0a0;
  text-align: center;
  font-family: Foco;
  font-size: 14px;
  text-transform: uppercase;
  border-bottom: 1px solid #eeeeee;
  border-top: 1px solid #eeeeee;
  cursor: pointer; }
  .btn-tertiary:hover:not(.disabled) {
    background-color: #e0e9f1; }
  .btn-tertiary.disabled {
    cursor: not-allowed; }
  .btn-tertiary.btn-ripple {
    position: relative; }
  .btn-tertiary.btn-ripple div {
    top: 50%;
    position: absolute;
    border: 4px solid rgba(28, 77, 107, 0.75);
    opacity: 1;
    border-radius: 50%;
    -webkit-animation: btn-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
            animation: btn-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite; }

.mobile-clickable-header-item {
  display: inline-block;
  text-align: center;
  padding: 0.5em; }
  .mobile-clickable-header-item svg {
    font-size: 35px;
    color: white; }

.right {
  position: absolute;
  right: 0;
  top: 0; }

.icon-previous,
.icon-next {
  border: none;
  border-radius: 0;
  font-size: 20px;
  letter-spacing: 1.25px;
  line-height: 20px;
  text-align: center;
  background-color: rgba(227, 227, 227, 0.6);
  padding: 15px;
  flex: 0 1;
  cursor: pointer;
  min-width: 20px; }
  .icon-previous.disabled,
  .icon-next.disabled {
    cursor: not-allowed;
    opacity: 0.5; }

.icon-previous {
  margin-right: 5px; }

.icon-next {
  margin-left: 5px; }

.transaction-icon {
  height: 30px;
  width: 30px;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
  flex-shrink: 0; }

.widget, .widget-list {
  padding: 20px 20px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column; }
  @media only screen and (max-width: 1023px) {
    .widget, .widget-list {
      padding: 20px 17px; } }
  .widget .widget-contents, .widget-list .widget-contents {
    flex: 1 1;
    background-color: #fff;
    box-shadow: 0 2px 11px -3px rgba(95, 129, 226, 0.44);
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    position: relative; }
  .widget .widget-header, .widget-list .widget-header, .widget-list .widget-list-item {
    height: 16px;
    padding: 25px;
    text-align: center;
    text-transform: uppercase;
    font-weight: bold;
    letter-spacing: 0.94px;
    line-height: 16px;
    color: #1e2171;
    box-shadow: 0 2px 11px -3px rgba(95, 129, 226, 0.44); }
  @media screen and (max-width: 600px) {
    .widget .widget-footer, .widget-list .widget-footer {
      padding: 20px 20%; } }
  @media screen and (min-width: 600px) {
    .widget .widget-footer, .widget-list .widget-footer {
      padding: 20px; } }
  .widget .widget-footer, .widget-list .widget-footer {
    text-align: center;
    text-transform: uppercase;
    font-weight: bold;
    letter-spacing: 0.94px;
    line-height: 16px;
    color: #a0a0a0;
    box-shadow: 0 2px 11px -3px rgba(95, 129, 226, 0.44);
    margin-top: auto; }

.link-widget {
  display: flex;
  flex: 1 1;
  text-decoration: none; }

.widget-list {
  background: none;
  box-shadow: none;
  position: relative;
  min-width: 0; }
  @media only screen and (min-width: 1024px) {
    .widget-list {
      width: 100%; } }
  .widget-list .widget-header, .widget-list .widget-list-item {
    background-color: #fff;
    border-radius: 8px; }
  .widget-list .widget-list-item {
    margin-top: 18px;
    padding: 10px 15px;
    height: auto;
    height: initial; }

@charset "UTF-8";
.rbc-btn {
  color: inherit;
  font: inherit;
  margin: 0; }

button.rbc-btn {
  overflow: visible;
  text-transform: none;
  -webkit-appearance: button;
  cursor: pointer; }

button[disabled].rbc-btn {
  cursor: not-allowed; }

button.rbc-input::-moz-focus-inner {
  border: 0;
  padding: 0; }

.rbc-calendar {
  box-sizing: border-box;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch; }

.rbc-calendar *,
.rbc-calendar *:before,
.rbc-calendar *:after {
  box-sizing: inherit; }

.rbc-abs-full, .rbc-row-bg {
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0; }

.rbc-ellipsis, .rbc-event-label, .rbc-row-segment .rbc-event-content, .rbc-show-more {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.rbc-rtl {
  direction: rtl; }

.rbc-off-range {
  color: #999999; }

.rbc-off-range-bg {
  background: #e6e6e6; }

.rbc-header {
  overflow: hidden;
  flex: 1 0;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 0 3px;
  text-align: center;
  vertical-align: middle;
  font-weight: bold;
  font-size: 90%;
  min-height: 0;
  border-bottom: 1px solid #DDD; }
  .rbc-header + .rbc-header {
    border-left: 1px solid #DDD; }
  .rbc-rtl .rbc-header + .rbc-header {
    border-left-width: 0;
    border-right: 1px solid #DDD; }
  .rbc-header > a, .rbc-header > a:active, .rbc-header > a:visited {
    color: inherit;
    text-decoration: none; }

.rbc-row-content {
  position: relative;
  -moz-user-select: none;
   -ms-user-select: none;
       user-select: none;
  -webkit-user-select: none;
  z-index: 4; }

.rbc-today {
  background-color: #eaf6ff; }

.rbc-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
  font-size: 16px; }
  .rbc-toolbar .rbc-toolbar-label {
    flex-grow: 1;
    padding: 0 10px;
    text-align: center; }
  .rbc-toolbar button {
    color: #373a3c;
    display: inline-block;
    margin: 0;
    text-align: center;
    vertical-align: middle;
    background: none;
    background-image: none;
    border: 1px solid #ccc;
    padding: .375rem 1rem;
    border-radius: 4px;
    line-height: normal;
    white-space: nowrap; }
    .rbc-toolbar button:active, .rbc-toolbar button.rbc-active {
      background-image: none;
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
      background-color: #e6e6e6;
      border-color: #adadad; }
      .rbc-toolbar button:active:hover, .rbc-toolbar button:active:focus, .rbc-toolbar button.rbc-active:hover, .rbc-toolbar button.rbc-active:focus {
        color: #373a3c;
        background-color: #d4d4d4;
        border-color: #8c8c8c; }
    .rbc-toolbar button:focus {
      color: #373a3c;
      background-color: #e6e6e6;
      border-color: #adadad; }
    .rbc-toolbar button:hover {
      color: #373a3c;
      background-color: #e6e6e6;
      border-color: #adadad; }

.rbc-btn-group {
  display: inline-block;
  white-space: nowrap; }
  .rbc-btn-group > button:first-child:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0; }
  .rbc-btn-group > button:last-child:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0; }
  .rbc-rtl .rbc-btn-group > button:first-child:not(:last-child) {
    border-radius: 4px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0; }
  .rbc-rtl .rbc-btn-group > button:last-child:not(:first-child) {
    border-radius: 4px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0; }
  .rbc-btn-group > button:not(:first-child):not(:last-child) {
    border-radius: 0; }
  .rbc-btn-group button + button {
    margin-left: -1px; }
  .rbc-rtl .rbc-btn-group button + button {
    margin-left: 0;
    margin-right: -1px; }
  .rbc-btn-group + .rbc-btn-group,
  .rbc-btn-group + button {
    margin-left: 10px; }

.rbc-event {
  border: none;
  box-sizing: border-box;
  box-shadow: none;
  margin: 0;
  padding: 2px 5px;
  background-color: #3174ad;
  border-radius: 5px;
  color: #fff;
  cursor: pointer;
  width: 100%;
  text-align: left; }
  .rbc-slot-selecting .rbc-event {
    cursor: inherit;
    pointer-events: none; }
  .rbc-event.rbc-selected {
    background-color: #265985; }
  .rbc-event:focus {
    outline: 5px auto #3b99fc; }

.rbc-event-label {
  font-size: 80%; }

.rbc-event-overlaps {
  box-shadow: -1px 1px 5px 0px rgba(51, 51, 51, 0.5); }

.rbc-event-continues-prior {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.rbc-event-continues-after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.rbc-event-continues-earlier {
  border-top-left-radius: 0;
  border-top-right-radius: 0; }

.rbc-event-continues-later {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0; }

.rbc-row {
  display: flex;
  flex-direction: row; }

.rbc-row-segment {
  padding: 0 1px 1px 1px; }

.rbc-selected-cell {
  background-color: rgba(0, 0, 0, 0.1); }

.rbc-show-more {
  background-color: rgba(255, 255, 255, 0.3);
  z-index: 4;
  font-weight: bold;
  font-size: 85%;
  height: auto;
  line-height: normal; }

.rbc-month-view {
  position: relative;
  border: 1px solid #DDD;
  display: flex;
  flex-direction: column;
  flex: 1 0;
  width: 100%;
  -moz-user-select: none;
   -ms-user-select: none;
       user-select: none;
  -webkit-user-select: none;
  height: 100%; }

.rbc-month-header {
  display: flex;
  flex-direction: row; }

.rbc-month-row {
  display: flex;
  position: relative;
  flex-direction: column;
  flex: 1 0;
  flex-basis: 0px;
  overflow: hidden;
  height: 100%; }
  .rbc-month-row + .rbc-month-row {
    border-top: 1px solid #DDD; }

.rbc-date-cell {
  flex: 1 1;
  min-width: 0;
  padding-right: 5px;
  text-align: right; }
  .rbc-date-cell.rbc-now {
    font-weight: bold; }
  .rbc-date-cell > a, .rbc-date-cell > a:active, .rbc-date-cell > a:visited {
    color: inherit;
    text-decoration: none; }

.rbc-row-bg {
  display: flex;
  flex-direction: row;
  flex: 1 0;
  overflow: hidden; }

.rbc-day-bg {
  flex: 1 0; }
  .rbc-day-bg + .rbc-day-bg {
    border-left: 1px solid #DDD; }
  .rbc-rtl .rbc-day-bg + .rbc-day-bg {
    border-left-width: 0;
    border-right: 1px solid #DDD; }

.rbc-overlay {
  position: absolute;
  z-index: 5;
  border: 1px solid #e5e5e5;
  background-color: #fff;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.25);
  padding: 10px; }
  .rbc-overlay > * + * {
    margin-top: 1px; }

.rbc-overlay-header {
  border-bottom: 1px solid #e5e5e5;
  margin: -10px -10px 5px -10px;
  padding: 2px 10px; }

.rbc-agenda-view {
  display: flex;
  flex-direction: column;
  flex: 1 0;
  overflow: auto; }
  .rbc-agenda-view table.rbc-agenda-table {
    width: 100%;
    border: 1px solid #DDD;
    border-spacing: 0;
    border-collapse: collapse; }
    .rbc-agenda-view table.rbc-agenda-table tbody > tr > td {
      padding: 5px 10px;
      vertical-align: top; }
    .rbc-agenda-view table.rbc-agenda-table .rbc-agenda-time-cell {
      padding-left: 15px;
      padding-right: 15px;
      text-transform: lowercase; }
    .rbc-agenda-view table.rbc-agenda-table tbody > tr > td + td {
      border-left: 1px solid #DDD; }
    .rbc-rtl .rbc-agenda-view table.rbc-agenda-table tbody > tr > td + td {
      border-left-width: 0;
      border-right: 1px solid #DDD; }
    .rbc-agenda-view table.rbc-agenda-table tbody > tr + tr {
      border-top: 1px solid #DDD; }
    .rbc-agenda-view table.rbc-agenda-table thead > tr > th {
      padding: 3px 5px;
      text-align: left;
      border-bottom: 1px solid #DDD; }
      .rbc-rtl .rbc-agenda-view table.rbc-agenda-table thead > tr > th {
        text-align: right; }

.rbc-agenda-time-cell {
  text-transform: lowercase; }
  .rbc-agenda-time-cell .rbc-continues-after:after {
    content: ' »'; }
  .rbc-agenda-time-cell .rbc-continues-prior:before {
    content: '« '; }

.rbc-agenda-date-cell,
.rbc-agenda-time-cell {
  white-space: nowrap; }

.rbc-agenda-event-cell {
  width: 100%; }

.rbc-time-column {
  display: flex;
  flex-direction: column;
  min-height: 100%; }
  .rbc-time-column .rbc-timeslot-group {
    flex: 1 1; }

.rbc-timeslot-group {
  border-bottom: 1px solid #DDD;
  min-height: 40px;
  display: flex;
  flex-flow: column nowrap; }

.rbc-time-gutter,
.rbc-header-gutter {
  flex: none; }

.rbc-label {
  padding: 0 5px; }

.rbc-day-slot {
  position: relative; }
  .rbc-day-slot .rbc-events-container {
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    margin-right: 10px;
    top: 0; }
    .rbc-day-slot .rbc-events-container.rbc-rtl {
      left: 10px;
      right: 0; }
  .rbc-day-slot .rbc-event {
    border: 1px solid #265985;
    display: flex;
    max-height: 100%;
    min-height: 20px;
    flex-flow: column wrap;
    align-items: flex-start;
    overflow: hidden;
    position: absolute; }
  .rbc-day-slot .rbc-event-label {
    flex: none;
    padding-right: 5px;
    width: auto; }
  .rbc-day-slot .rbc-event-content {
    width: 100%;
    flex: 1 1;
    word-wrap: break-word;
    line-height: 1;
    height: 100%;
    min-height: 1em; }
  .rbc-day-slot .rbc-time-slot {
    border-top: 1px solid #f7f7f7; }

.rbc-time-view-resources .rbc-time-gutter,
.rbc-time-view-resources .rbc-time-header-gutter {
  position: -webkit-sticky;
  position: sticky;
  left: 0;
  background-color: white;
  border-right: 1px solid #DDD;
  z-index: 10;
  margin-right: -1px; }

.rbc-time-view-resources .rbc-time-header {
  overflow: hidden; }

.rbc-time-view-resources .rbc-time-header-content {
  min-width: auto;
  flex: 1 0;
  flex-basis: 0px; }

.rbc-time-view-resources .rbc-time-header-cell-single-day {
  display: none; }

.rbc-time-view-resources .rbc-day-slot {
  min-width: 140px; }

.rbc-time-view-resources .rbc-header,
.rbc-time-view-resources .rbc-day-bg {
  width: 140px;
  flex: 1 1;
  flex-basis: 0 px; }

.rbc-time-header-content + .rbc-time-header-content {
  margin-left: -1px; }

.rbc-time-slot {
  flex: 1 0; }
  .rbc-time-slot.rbc-now {
    font-weight: bold; }

.rbc-day-header {
  text-align: center; }

.rbc-slot-selection {
  z-index: 10;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  font-size: 75%;
  width: 100%;
  padding: 3px; }

.rbc-slot-selecting {
  cursor: move; }

.rbc-time-view {
  display: flex;
  flex-direction: column;
  flex: 1 1;
  width: 100%;
  border: 1px solid #DDD;
  min-height: 0; }
  .rbc-time-view .rbc-time-gutter {
    white-space: nowrap; }
  .rbc-time-view .rbc-allday-cell {
    box-sizing: content-box;
    width: 100%;
    height: 100%;
    position: relative; }
  .rbc-time-view .rbc-allday-cell + .rbc-allday-cell {
    border-left: 1px solid #DDD; }
  .rbc-time-view .rbc-allday-events {
    position: relative;
    z-index: 4; }
  .rbc-time-view .rbc-row {
    box-sizing: border-box;
    min-height: 20px; }

.rbc-time-header {
  display: flex;
  flex: 0 0 auto;
  flex-direction: row; }
  .rbc-time-header.rbc-overflowing {
    border-right: 1px solid #DDD; }
  .rbc-rtl .rbc-time-header.rbc-overflowing {
    border-right-width: 0;
    border-left: 1px solid #DDD; }
  .rbc-time-header > .rbc-row:first-child {
    border-bottom: 1px solid #DDD; }
  .rbc-time-header > .rbc-row.rbc-row-resource {
    border-bottom: 1px solid #DDD; }

.rbc-time-header-cell-single-day {
  display: none; }

.rbc-time-header-content {
  flex: 1 1;
  display: flex;
  min-width: 0;
  flex-direction: column;
  border-left: 1px solid #DDD; }
  .rbc-rtl .rbc-time-header-content {
    border-left-width: 0;
    border-right: 1px solid #DDD; }
  .rbc-time-header-content > .rbc-row.rbc-row-resource {
    border-bottom: 1px solid #DDD;
    flex-shrink: 0; }

.rbc-time-content {
  display: flex;
  flex: 1 0;
  align-items: flex-start;
  width: 100%;
  border-top: 2px solid #DDD;
  overflow-y: auto;
  position: relative; }
  .rbc-time-content > .rbc-time-gutter {
    flex: none; }
  .rbc-time-content > * + * > * {
    border-left: 1px solid #DDD; }
  .rbc-rtl .rbc-time-content > * + * > * {
    border-left-width: 0;
    border-right: 1px solid #DDD; }
  .rbc-time-content > .rbc-day-slot {
    width: 100%;
    -moz-user-select: none;
     -ms-user-select: none;
         user-select: none;
    -webkit-user-select: none; }

.rbc-current-time-indicator {
  position: absolute;
  z-index: 3;
  left: 0;
  right: 0;
  height: 1px;
  background-color: #74ad31;
  pointer-events: none; }

.rbc-calendar {
  padding: 100px 50px 0px; }
  .rbc-calendar .rbc-toolbar {
    position: relative;
    top: -25px;
    border: none; }
    .rbc-calendar .rbc-toolbar .rbc-btn-group {
      position: absolute;
      left: 0;
      top: -54px;
      margin: 25px auto;
      box-shadow: 0 2px 17px -5px rgba(95, 129, 226, 0.4); }
      .rbc-calendar .rbc-toolbar .rbc-btn-group button {
        color: rgba(28, 77, 107, 0.75);
        font-size: 16px;
        font-weight: bold;
        letter-spacing: 1px;
        line-height: 18px;
        text-align: center;
        border: 1px solid #cdcdcd;
        padding: 0.5rem 1.25rem;
        -webkit-transition: background-color ease-in-out 0.3s;
        transition: background-color ease-in-out 0.3s;
        cursor: pointer; }
        .rbc-calendar .rbc-toolbar .rbc-btn-group button:focus {
          outline: none; }
        .rbc-calendar .rbc-toolbar .rbc-btn-group button[disabled] {
          opacity: 0.5;
          color: rgba(28, 77, 107, 0.75); }
    .rbc-calendar .rbc-toolbar .rbc-toolbar-label {
      text-transform: uppercase;
      color: #184f6d;
      font-family: Foco;
      font-size: 16px;
      font-weight: bold;
      letter-spacing: 0.94px;
      line-height: 16px;
      margin-top: -25px; }
  .rbc-calendar .rbc-month-view {
    border: none;
    top: 0; }
    .rbc-calendar .rbc-month-view .rbc-row.rbc-month-header {
      background-color: #f9fbfc;
      height: 54px;
      margin: 0 -50px;
      padding: 0 50px;
      position: static;
      position: initial;
      width: auto;
      width: initial; }
      .rbc-calendar .rbc-month-view .rbc-row.rbc-month-header .rbc-header {
        border: none;
        color: #7c9fb0;
        font-size: 13px;
        font-weight: bold;
        letter-spacing: 0.81px;
        line-height: 54px;
        text-align: center;
        -webkit-transition: background-color ease-in-out 0.3s;
        transition: background-color ease-in-out 0.3s;
        cursor: default; }
        .rbc-calendar .rbc-month-view .rbc-row.rbc-month-header .rbc-header:hover {
          background-color: #e8f0f4; }
    .rbc-calendar .rbc-month-view .rbc-day-bg {
      border: none; }
    .rbc-calendar .rbc-month-view .rbc-off-range-bg,
    .rbc-calendar .rbc-month-view .rbc-today {
      background-color: inherit; }
    .rbc-calendar .rbc-month-view .rbc-row-content,
    .rbc-calendar .rbc-month-view .rbc-row {
      height: 100%;
      align-items: center;
      position: absolute;
      top: 0;
      width: 100%; }
      .rbc-calendar .rbc-month-view .rbc-row-content .rbc-date-cell,
      .rbc-calendar .rbc-month-view .rbc-row .rbc-date-cell {
        text-align: center;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 1; }
        .rbc-calendar .rbc-month-view .rbc-row-content .rbc-date-cell.rbc-off-range,
        .rbc-calendar .rbc-month-view .rbc-row .rbc-date-cell.rbc-off-range {
          pointer-events: none;
          color: #666666; }
        .rbc-calendar .rbc-month-view .rbc-row-content .rbc-date-cell a,
        .rbc-calendar .rbc-month-view .rbc-row .rbc-date-cell a {
          padding: 0;
          border-radius: 50%;
          -webkit-transition: padding ease-in-out 0.3s, background-color ease-in-out 0.3s;
          transition: padding ease-in-out 0.3s, background-color ease-in-out 0.3s;
          cursor: default; }
      .rbc-calendar .rbc-month-view .rbc-row-content .rbc-row-segment,
      .rbc-calendar .rbc-month-view .rbc-row .rbc-row-segment {
        padding: 0;
        padding-left: calc(100% / 14 - 17px) !important;
        padding-right: calc(100% / 14 - 12px) !important;
        z-index: 2; }
        .rbc-calendar .rbc-month-view .rbc-row-content .rbc-row-segment .rbc-event-wrapper,
        .rbc-calendar .rbc-month-view .rbc-row .rbc-row-segment .rbc-event-wrapper {
          margin-left: 31px;
          margin-right: 30px; }
          .rbc-calendar .rbc-month-view .rbc-row-content .rbc-row-segment .rbc-event-wrapper .rbc-event,
          .rbc-calendar .rbc-month-view .rbc-row .rbc-row-segment .rbc-event-wrapper .rbc-event {
            height: 30px;
            position: relative;
            border-radius: 0; }
    .rbc-calendar .rbc-month-view .rbc-month-row + .rbc-month-row {
      border: none; }

.calendar-container {
  position: relative; }
  .calendar-container .calendar-keys {
    left: 0;
    height: 100px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    border-top: 1px solid #cdcdcd;
    padding: 0 45px; }
    .calendar-container .calendar-keys .calendar-key {
      color: #9a9797;
      font-size: 15px;
      letter-spacing: 1.14px;
      line-height: 17px;
      vertical-align: middle;
      display: flex;
      align-items: center; }
    .calendar-container .calendar-keys .calendar-colour {
      margin-right: 10px;
      min-width: 20px;
      height: 20px;
      vertical-align: sub;
      border-radius: 50%;
      background-color: red;
      display: inline-block; }

.toggle-container {
  display: inline-block;
  height: 21px;
  min-width: 39px;
  border-radius: 12px;
  vertical-align: middle;
  margin-right: 10px;
  background-color: rgba(124, 159, 176, 0.2);
  -webkit-transition: background-color ease-in-out 0.3s;
  transition: background-color ease-in-out 0.3s;
  will-change: background-color;
  cursor: pointer; }
  .toggle-container .toggle-knob {
    height: 21px;
    width: 21px;
    border-radius: 12px;
    border: none;
    background-color: rgba(124, 159, 176, 0.4);
    -webkit-transition: background-color ease-in-out 0.3s, -webkit-transform ease-in-out 0.3s;
    transition: background-color ease-in-out 0.3s, -webkit-transform ease-in-out 0.3s;
    transition: background-color ease-in-out 0.3s, transform ease-in-out 0.3s;
    transition: background-color ease-in-out 0.3s, transform ease-in-out 0.3s, -webkit-transform ease-in-out 0.3s;
    will-change: background-color, transform;
    cursor: pointer;
    vertical-align: inherit; }
    .toggle-container .toggle-knob:focus {
      outline: none; }
    .toggle-container .toggle-knob:hover {
      box-shadow: 0 2px 4px 1px rgba(124, 159, 176, 0.2); }
  .toggle-container.active {
    background-color: #FFE2BB;
    box-shadow: 0 2px 51px 2px rgba(0, 0, 0, 0.08); }
    .toggle-container.active .toggle-knob {
      background-color: #FBB03C;
      -webkit-transform: translate(18px, 0);
              transform: translate(18px, 0); }
      .toggle-container.active .toggle-knob:hover {
        box-shadow: 0 2px 4px 1px #f1af4a; }
  .toggle-container.pending.active {
    background-color: #EBEBEB;
    box-shadow: 0 2px 51px 2px rgba(0, 0, 0, 0.08); }
    .toggle-container.pending.active .toggle-knob {
      background-color: rgba(124, 159, 176, 0.4);
      -webkit-transform: translate(18px, 0);
              transform: translate(18px, 0); }
      .toggle-container.pending.active .toggle-knob:hover {
        box-shadow: 0 2px 4px 1px #EBEBEB; }

.calendar-widget {
  height: 360px; }
  .calendar-widget .rbc-calendar {
    padding: 0px 25px 25px; }
    .calendar-widget .rbc-calendar .rbc-toolbar {
      display: none; }
    .calendar-widget .rbc-calendar .rbc-month-view .rbc-row.rbc-month-header {
      margin: 0 -25px;
      padding: 0 25px;
      height: 40px; }
      .calendar-widget .rbc-calendar .rbc-month-view .rbc-row.rbc-month-header .rbc-header {
        line-height: 40px; }
        .calendar-widget .rbc-calendar .rbc-month-view .rbc-row.rbc-month-header .rbc-header span {
          color: transparent;
          font-size: 0;
          display: inline-block; }
          .calendar-widget .rbc-calendar .rbc-month-view .rbc-row.rbc-month-header .rbc-header span::first-letter, .calendar-widget .rbc-calendar .rbc-month-view .rbc-row.rbc-month-header .rbc-header span:first-letter {
            color: #7c9fb0;
            font-size: 13px; }
    .calendar-widget .rbc-calendar .rbc-month-row {
      justify-content: center; }
    .calendar-widget .rbc-calendar .rbc-date-cell {
      pointer-events: none;
      font-size: 13px; }
  .calendar-widget .calendar-container {
    height: calc(100% - 50px);
    flex: 1 1;
    display: flex;
    flex-direction: column-reverse; }
    .calendar-widget .calendar-container .calendar-keys {
      top: 0;
      padding: 5px 5px;
      border-top: none; }
      .calendar-widget .calendar-container .calendar-keys .calendar-key {
        margin-left: 15px !important;
        margin-right: 0; }
    .calendar-widget .calendar-container .rbc-event {
      cursor: default; }
  .calendar-widget > a {
    color: #184f6d;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 1px;
    line-height: 18px;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
    display: block;
    margin: -10px 30px 10px;
    box-shadow: 0 2px 17px -5px rgba(95, 129, 226, 0.4);
    border: 1px solid #cdcdcd;
    border-radius: 4px;
    padding: 12px;
    -webkit-transition: 200ms ease-in-out;
    transition: 200ms ease-in-out; }
    .calendar-widget > a:hover {
      background-color: #f5f6fb; }

.welcome-widget .widget-contents {
  background-color: transparent;
  box-shadow: none;
  color: #FFFFFF;
  font-family: Foco;
  font-size: 32px;
  letter-spacing: 0.6px;
  line-height: 36px; }

.menu-contents {
  display: flex;
  margin: 0;
  overflow-x: auto; }
  .menu-contents .widget {
    margin-bottom: 20px;
    width: 168px;
    height: 200px; }
    @media only screen and (max-width: 1023px) {
      .menu-contents .widget {
        width: 135px; } }

@media only screen and (max-width: 1023px) {
  .menu-widget-container {
    margin-top: 50px; } }

.alert-container {
  padding: 20px;
  margin: 0 20px; }
  .alert-container p {
    text-align: left;
    color: #1e2171;
    margin: 0;
    font-size: 16px; }

.events-widget-item {
  display: flex;
  align-items: center; }
  .events-widget-item:hover {
    cursor: pointer; }
  .events-widget-item .event-image-container {
    overflow: hidden;
    border-radius: 5px;
    position: relative;
    min-width: 52px;
    max-width: 52px;
    height: 52px;
    display: flex;
    justify-content: center;
    align-items: center; }
    .events-widget-item .event-image-container img {
      height: 52px; }
  .events-widget-item .item-details {
    display: flex;
    flex-direction: column;
    font-weight: normal;
    text-align: left;
    text-transform: none;
    margin-left: 15px;
    width: 100%;
    /* need the min-width for the elipsis to work */
    min-width: 0; }
    .events-widget-item .item-details .item-title {
      line-height: 21px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      color: #184f6d; }
    .events-widget-item .item-details .item-description {
      color: #9a9797;
      font-size: 14px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis; }

.event-load-more {
  color: #184f6d;
  padding: 20px 0;
  text-decoration: none;
  cursor: pointer; }
  .event-load-more:hover {
    text-decoration: underline; }

.modal {
  padding: 30px;
  background-color: white;
  border-radius: 8px;
  box-shadow: 3px 8px 26px 0 rgba(0, 0, 0, 0.25);
  display: flex;
  flex-direction: column;
  align-items: center; }
  .modal:focus {
    outline: none; }
  .modal h1 {
    margin-top: 0;
    color: #1e2171; }
  .modal p {
    font-size: 13px;
    line-height: 19px;
    letter-spacing: 0.8px; }
    .modal p.error-message {
      font-size: 18px;
      line-height: 36px; }
  .modal .modal-header {
    color: #1e2171;
    font-size: 29px;
    line-height: 29px;
    letter-spacing: 0.43px;
    margin: 0 auto 30px auto;
    text-align: center; }
  .modal .modal-footer {
    display: flex;
    margin-top: 50px;
    width: 100%; }
    @media only screen and (max-width: 1023px) {
      .modal .modal-footer {
        margin-top: 20px; } }
    .modal .modal-footer .back-button {
      position: relative; }

.modal-overlay.mobile-modal-overlay {
  justify-content: flex-start; }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
    .modal-overlay.mobile-modal-overlay {
      justify-content: center;
      padding: 0 50px; }
      .modal-overlay.mobile-modal-overlay .modal {
        height: auto !important;
        height: initial !important; } }
  .modal-overlay.mobile-modal-overlay .modal {
    max-height: 850px;
    height: auto;
    box-sizing: border-box;
    padding: 40px 20px; }

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.75);
  z-index: 100;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; }

.message-modal, .confirm-modal {
  padding: 50px 80px;
  background-color: white;
  box-shadow: 3px 8px 26px 0 rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  position: relative; }
  .message-modal h1, .confirm-modal h1 {
    color: #1e2171;
    font-size: 22px;
    text-align: center;
    margin-top: 0; }
  .message-modal p, .confirm-modal p {
    color: rgba(27, 78, 107, 0.56);
    font-size: 13px;
    text-align: center;
    white-space: pre-line; }
  .message-modal .btn.btn-primary, .confirm-modal .btn.btn-primary {
    position: absolute;
    width: 100%;
    height: 50px;
    left: 0;
    bottom: 0;
    margin: 0;
    border-top-left-radius: 0;
    border-top-right-radius: 0; }
  .message-modal:focus, .confirm-modal:focus {
    outline: none; }
  .message-modal .important-info, .confirm-modal .important-info {
    padding: 8px;
    min-height: 36px;
    margin-bottom: 2vh;
    line-height: 36px;
    border-radius: 8px;
    border: 1px solid #ffa7a7;
    color: #ff0000;
    background-color: rgba(255, 167, 167, 0.15);
    width: 400px;
    display: flex;
    align-items: center; }
    .message-modal .important-info *, .confirm-modal .important-info * {
      display: inline-block;
      margin: 0;
      margin-left: 15px; }
    .message-modal .important-info p, .confirm-modal .important-info p {
      color: inherit;
      font-size: inherit;
      text-align: inherit;
      margin-right: 15px; }

.confirm-modal .confirm-button-container {
  display: flex;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: hidden; }
  .confirm-modal .confirm-button-container .btn {
    margin: 0;
    border: none;
    position: relative;
    border-radius: 0;
    box-shadow: none;
    color: #fff; }
  .confirm-modal .confirm-button-container .btn:first-child {
    border-bottom-left-radius: 8px; }
  .confirm-modal .confirm-button-container .btn:last-child {
    border-bottom-right-radius: 8px; }

.back-button {
  box-shadow: 0 5px 11px 0 rgba(185, 193, 197, 0.2);
  background-color: #dce7ec;
  color: rgba(28, 77, 107, 0.41);
  height: 50px;
  width: 50px;
  line-height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: flex-start;
  position: absolute;
  left: 0;
  -webkit-transition: 200ms ease-in-out;
  transition: 200ms ease-in-out; }
  .back-button:hover {
    cursor: pointer;
    background-color: #d1dce1; }

.ReactModal__Body--open {
  overflow: hidden;
  position: fixed;
  width: 100%;
  height: 100%; }

.item-details-modal {
  min-width: 450px;
  max-width: 675px;
  min-height: 350px;
  position: relative;
  width: 98%;
  overflow-y: auto; }
  .item-details-modal .item-details-content {
    width: 100%; }
    @media only screen and (max-width: 480px) {
      .item-details-modal .item-details-content {
        margin-top: 25px; } }
  .item-details-modal .item-details-main div:first-child {
    flex: 4 1; }
  .item-details-modal .item-details-main .event-image-container {
    flex: 3 1;
    max-height: 162px;
    overflow: hidden;
    border-radius: 5px;
    box-shadow: 0 2px 28px -5px rgba(95, 129, 226, 0.4); }
    .item-details-modal .item-details-main .event-image-container > img {
      margin: 0; }
    .item-details-modal .item-details-main .event-image-container .item-image {
      width: auto;
      height: 100%;
      object-fit: contain; }
    @media only screen and (max-width: 1023px) {
      .item-details-modal .item-details-main .event-image-container {
        min-width: 0;
        width: calc(100% + 40px);
        max-width: 100%; } }
  @media only screen and (max-width: 1023px) {
    .item-details-modal .item-details-main {
      padding: 0 !important; } }
  .item-details-modal .item-details-main,
  .item-details-modal .item-details-summary {
    padding: 2em 0;
    display: flex;
    flex-direction: row; }
    @media only screen and (max-width: 1023px) {
      .item-details-modal .item-details-main,
      .item-details-modal .item-details-summary {
        flex-direction: column-reverse; } }
  @media only screen and (max-width: 1023px) {
    .item-details-modal {
      min-width: 0;
      max-height: 100%;
      overflow-y: scroll; } }
  .item-details-modal .item-details-summary {
    padding-top: 0; }
  .item-details-modal .item-details-summary-column {
    width: 100%; }
  .item-details-modal .item-details {
    flex: 1 1;
    padding-right: 16px; }
    .item-details-modal .item-details .item-title {
      color: #80a2b2;
      font-size: 14px;
      font-weight: bold;
      margin: 1em 0;
      letter-spacing: 0.9px;
      width: 100%; }
    .item-details-modal .item-details .item-details-description {
      color: #184f6d;
      font-size: 16px;
      line-height: 22px;
      padding-right: 20px;
      white-space: pre-line; }
      @media only screen and (max-width: 1023px) {
        .item-details-modal .item-details .item-details-description {
          height: auto;
          height: initial;
          padding-right: 0; } }
    .item-details-modal .item-details .item-subheading {
      letter-spacing: 1.2px;
      line-height: 24px;
      font-size: 19px; }
    .item-details-modal .item-details .item-details-phone-number {
      color: #184f6d;
      font-size: 20px;
      line-height: 18px; }
    .item-details-modal .item-details .item-description {
      font-weight: bold;
      letter-spacing: 0.9px;
      white-space: pre-line; }

.back-button-calendar-items {
  position: relative; }

.action-button-calendar-items {
  float: right;
  margin: 0px 8px;
  min-width: 100px;
  max-width: 180px; }

.home-page-container {
  max-width: 1244px;
  height: calc(100% - 7em);
  margin: auto;
  position: relative; }
  .home-page-container .welcome-widget {
    text-align: left; }
  .home-page-container .reservation-links-widget-container .widget-contents {
    background: #ffffff;
    box-shadow: 0 2px 11px -3px rgba(95, 129, 226, 0.44); }
    .home-page-container .reservation-links-widget-container .widget-contents .reservations-container {
      padding-left: 20px;
      padding-right: 20px; }
  .home-page-container .reservations-container {
    justify-content: center; }
  .home-page-container .home-reservations-header {
    margin-bottom: 20px; }
  .home-page-container .home-park-image-widget-container .widget-contents .park-home-park-image-container {
    padding: 20px; }

.horizontal-background.home-page-background {
  background: url("/images/Desktop-PalmOverlay-Home.svg"), #3f417e;
  background-position-x: center;
  background-position-y: bottom;
  background-repeat: no-repeat;
  min-height: 0px;
  height: 20em; }

.home-navigation .widget-contents {
  flex-basis: 165px;
  border-radius: 12px;
  padding: 20px 20px;
  -webkit-transition: all 0.1s ease-in-out;
  transition: all 0.1s ease-in-out; }
  .home-navigation .widget-contents:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1); }

.park-news-title-home {
  color: #1e2171;
  letter-spacing: 0.4px;
  line-height: 26px;
  font-weight: bold;
  text-transform: uppercase;
  text-align: center;
  margin-bottom: 0; }

.nav-option-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; }
  @media only screen and (max-width: 1023px) {
    .nav-option-container {
      padding: 0; } }
  .nav-option-container p {
    font-size: 15px;
    letter-spacing: 0.95px;
    line-height: 20px;
    font-weight: bold;
    text-transform: uppercase;
    text-align: center;
    margin-bottom: 0;
    margin-top: auto;
    padding-top: 10px;
    flex: 1 1;
    width: 100%; }
    @media only screen and (max-width: 1023px) {
      .nav-option-container p {
        line-height: 17px;
        font-size: 15px;
        letter-spacing: 0.75px;
        flex: none; } }
  .nav-option-container .icon-container {
    flex: 5 1;
    display: flex;
    align-items: center; }
    .nav-option-container .icon-container .icon {
      background: rgba(235, 236, 241, 0.7);
      border-radius: 50%;
      box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0);
      width: 70px;
      height: 70px;
      display: flex;
      justify-content: center;
      align-items: center;
      overflow: hidden; }
      @media only screen and (max-width: 1023px) {
        .nav-option-container .icon-container .icon {
          width: 80px;
          height: 80px; } }
      .nav-option-container .icon-container .icon img {
        width: 50px;
        height: 50px; }
        @media only screen and (max-width: 1023px) {
          .nav-option-container .icon-container .icon img {
            width: 50px;
            height: 50px; } }

.transaction-item {
  padding: 15px;
  border-bottom: 2px solid #eeeeee;
  height: 46px; }
  .transaction-item:nth-child(6) {
    border-bottom: none; }
  .transaction-item .transaction-date-row {
    display: flex;
    justify-content: space-between; }
    .transaction-item .transaction-date-row .transaction-date {
      font-size: 12px;
      font-weight: bold;
      line-height: 14px;
      letter-spacing: 0.75px;
      color: #565656;
      margin: 0; }
    .transaction-item .transaction-date-row .invoice-link {
      color: #565656;
      opacity: 0.8;
      font-size: 12px;
      line-height: 14px;
      padding: 0; }
  .transaction-item .transaction-details {
    margin-top: 5px;
    display: flex;
    align-items: flex-end; }
    .transaction-item .transaction-details .transaction-type-container {
      flex: 1 1;
      margin: 0 10px;
      overflow: hidden; }
      .transaction-item .transaction-details .transaction-type-container .transaction-type {
        margin: 0;
        font-size: 14px;
        font-weight: bold;
        letter-spacing: 0.9px;
        line-height: 15px;
        color: #184f6d;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden; }
      .transaction-item .transaction-details .transaction-type-container .transaction-no {
        margin: 0;
        font-size: 12px;
        line-height: 15px;
        letter-spacing: 0.9px;
        color: #a0a0a0; }
    .transaction-item .transaction-details .transaction-amount {
      margin: 0;
      font-size: 16px;
      line-height: 18px;
      letter-spacing: 1px;
      color: #565656; }

.no-transactions {
  padding: 25px;
  flex: 1 1;
  display: flex;
  align-items: center;
  text-align: center;
  font-size: 16px;
  line-height: 21px;
  letter-spacing: 0.95px;
  border-bottom: 2px solid #eeeeee; }

.transaction-spinner {
  flex: 1 1;
  display: flex;
  align-items: center;
  justify-content: center; }

.transaction-balance {
  margin-top: auto;
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-top: 2px solid #eeeeee;
  border-bottom: 2px solid #eeeeee;
  font-weight: bold;
  font-size: 15px;
  line-height: 16px;
  letter-spacing: 0.95px;
  text-transform: uppercase;
  color: #7c9fb0; }

.view-account-container {
  padding: 12px 25px; }
  .view-account-container .view-account {
    display: block;
    box-shadow: 0 5px 7px -3px rgba(133, 133, 133, 0.5);
    color: #fff;
    background-color: #1b4e6b;
    font-size: 16px;
    font-weight: bold;
    line-height: 18px;
    letter-spacing: 1px;
    text-transform: uppercase;
    text-decoration: none;
    text-align: center;
    border-radius: 4px;
    padding: 10px;
    -webkit-transition: 200ms ease-in-out;
    transition: 200ms ease-in-out; }
    .view-account-container .view-account:hover {
      background-color: #143a50;
      box-shadow: 0 5px 20px 0 rgba(133, 133, 133, 0.5); }

.spinner {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px; }

.spinner div {
  position: absolute;
  border: 4px solid rgba(28, 77, 107, 0.75);
  opacity: 1;
  border-radius: 50%;
  -webkit-animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
          animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite; }

.spinner div:nth-child(2) {
  -webkit-animation-delay: -0.5s;
          animation-delay: -0.5s; }

@-webkit-keyframes lds-ripple {
  0% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 1; }
  100% {
    top: 0px;
    left: 0px;
    width: 72px;
    height: 72px;
    opacity: 0; } }

@keyframes lds-ripple {
  0% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 1; }
  100% {
    top: 0px;
    left: 0px;
    width: 72px;
    height: 72px;
    opacity: 0; } }

div.button-container {
  display: flex; }
  div.button-container .btn:first-child {
    margin-left: 0; }
  div.button-container .btn:last-child {
    margin-right: 0; }

div.input-container {
  display: flex;
  flex-direction: column; }

.icon-link-container {
  padding: 23px 15px;
  box-sizing: border-box;
  text-align: center;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  cursor: pointer;
  overflow: hidden; }
  .icon-link-container .image-container-wrapper {
    margin: 0 10px 20px 10px; }
  .icon-link-container .image-container {
    background-color: rgba(235, 236, 241, 0.7);
    border-radius: 50%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative; }
    .icon-link-container .image-container:after {
      content: '';
      display: block;
      padding-bottom: 100%; }
    .icon-link-container .image-container img {
      position: absolute;
      left: 50%;
      -webkit-transform: translateX(-50%) translateY(-50%);
              transform: translateX(-50%) translateY(-50%);
      top: 50%; }
      @media only screen and (max-width: 1023px) {
        .icon-link-container .image-container img {
          width: 50%; } }
  .icon-link-container a {
    color: inherit;
    text-decoration: none; }
  .icon-link-container .label {
    color: #6e7490;
    font-weight: bold;
    font-size: 14px;
    letter-spacing: 1px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden; }

.restaurant-button {
  display: flex;
  box-shadow: 0 2px 11px -3px rgba(95, 129, 226, 0.44);
  border-radius: 10px;
  max-height: 70px;
  padding: 10px;
  min-width: 330px;
  margin-bottom: 15px; }
  .restaurant-button:nth-child(odd) {
    margin-right: 25px; }
  @media only screen and (max-width: 1023px) {
    .restaurant-button {
      align-self: flex-end;
      min-width: 0;
      width: 100%; }
      .restaurant-button:nth-child(odd) {
        margin-right: 0; } }
  .restaurant-button:hover {
    cursor: pointer; }
  .restaurant-button .restaurant-image {
    border-radius: 9px;
    height: 52px;
    min-width: 116px;
    overflow: hidden;
    display: flex;
    align-items: center; }
    .restaurant-button .restaurant-image img {
      max-width: 116px; }
  .restaurant-button .restaurant-name {
    margin-bottom: 0;
    margin-left: 15px;
    font-size: 16px;
    line-height: 14px;
    color: #445870;
    align-self: center; }

.restaurant-modal {
  max-width: 730px;
  min-width: 600px;
  max-height: 650px;
  overflow-y: auto; }
  @media only screen and (max-width: 1023px) {
    .restaurant-modal {
      min-width: 0;
      width: 100%; } }
  .restaurant-modal .restaurant-modal-container {
    width: 100%; }
    .restaurant-modal .restaurant-modal-container .restaurant-message {
      margin-bottom: 0; }
    .restaurant-modal .restaurant-modal-container .restaurant-list {
      display: flex;
      flex-wrap: wrap;
      align-self: flex-start;
      justify-content: space-between;
      width: 100%; }

.my-calendar-reservation-header {
  color: #fff;
  font-size: 23px;
  letter-spacing: 0.43px;
  line-height: 26px;
  margin-bottom: 20px; }

.reservations-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  padding-bottom: 20px; }

.reservation-link {
  width: 33.33%;
  border-radius: 9.3px;
  background-color: #ffffff;
  text-transform: uppercase;
  box-shadow: 0 5px 7px 3px rgba(25, 25, 104, 0.17); }
  .reservation-link:first-child {
    margin-right: 20px; }
  .reservation-link:nth-child(2) {
    margin-right: 20px; }

.reservation-links-mobile {
  margin: 0 -17px;
  box-shadow: 0 2px 11px 0 rgba(84, 83, 177, 0.14); }
  .reservation-links-mobile .my-calendar-reservation-header {
    text-align: center;
    color: #1e2171;
    background-color: white;
    margin-bottom: 0;
    padding: 15px;
    font-size: 19px;
    letter-spacing: 0.43px;
    line-height: 26px; }
  .reservation-links-mobile .reservations-container {
    background: #3f417e;
    padding: 20px;
    margin: 10px 20px; }
  .reservation-links-mobile .icon-link-container {
    padding: 18px 0; }
    .reservation-links-mobile .icon-link-container .image-container-wrapper {
      margin: 0 16px 20px 16px; }

.restaurant-details {
  width: 100%;
  box-sizing: border-box; }
  .restaurant-details p {
    margin-bottom: 0; }
  .restaurant-details .details {
    display: flex;
    flex-wrap: wrap; }
    .restaurant-details .details .image {
      max-width: 400px;
      max-height: 180px;
      border-radius: 9px;
      overflow: hidden;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 25px; }
      @media only screen and (max-width: 767px) {
        .restaurant-details .details .image {
          margin: 0;
          margin-bottom: 18px; } }
      .restaurant-details .details .image img {
        max-width: 400px; }
    .restaurant-details .details .side-details > div:not(:last-child) {
      margin-bottom: 18px; }
    .restaurant-details .details .side-details .book-a-table {
      margin-left: 0;
      min-width: 220px; }
    @media only screen and (max-width: 767px) {
      .restaurant-details .details .side-details {
        display: flex;
        width: 100%;
        flex-wrap: wrap; }
        .restaurant-details .details .side-details .book-a-table {
          margin-top: 25px;
          width: 100%; }
        .restaurant-details .details .side-details > div {
          flex: 1 1; }
        .restaurant-details .details .side-details > div:not(:last-child) {
          margin-bottom: 0; } }
  .restaurant-details .title {
    font-size: 14px;
    font-weight: bold;
    line-height: 14px;
    color: #1e2171;
    margin-bottom: 10px; }
  .restaurant-details .description {
    font-size: 16px;
    line-height: 22px;
    color: #184f6d; }
  .restaurant-details .value {
    font-size: 20px;
    line-height: 18px;
    color: #184f6d; }
  .restaurant-details a.value {
    color: #008bff; }
  .restaurant-details .detail-margin {
    margin-top: 25px; }
  .restaurant-details .menu-list {
    margin-right: -10px;
    margin-bottom: -10px;
    max-width: 680px; }
    .restaurant-details .menu-list > button {
      margin: 0;
      margin-right: 10px;
      margin-bottom: 10px;
      display: inline-block;
      min-width: 160px; }

.selectable-chip {
  border: 1px solid #7c9fb0;
  color: #184f6d;
  background-color: rgba(27, 78, 107, 0.05);
  height: 35px;
  line-height: 35px;
  vertical-align: middle;
  border-radius: 30px;
  padding: 0 12px;
  margin: 5px 5px 5px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer; }
  .selectable-chip.selected {
    border: inherit;
    background-color: #3f417e;
    color: white; }

.select-container {
  position: relative; }

.book-table-modal {
  width: 650px;
  overflow-x: hidden;
  overflow-y: auto; }
  .book-table-modal .modal-header {
    margin-bottom: 20px; }
  .book-table-modal .info-message p {
    font-size: inherit;
    letter-spacing: inherit;
    margin-bottom: 0; }
  .book-table-modal .info-message {
    margin-bottom: 20px; }
  .book-table-modal .error-message {
    width: 92%;
    margin-bottom: 30px;
    align-self: flex-start; }
  .book-table-modal form {
    width: 100%;
    margin-bottom: 10px; }
    .book-table-modal form .add-booking-field, .book-table-modal form .add-booking-field-full {
      display: inline-block;
      width: 50%;
      margin-top: 15px;
      margin-bottom: 15px;
      vertical-align: top; }
      .book-table-modal form .add-booking-field label, .book-table-modal form .add-booking-field-full label {
        color: #7c9fb0;
        font-size: 14px;
        font-weight: bold;
        word-wrap: break-word;
        display: block; }
      .book-table-modal form .add-booking-field input, .book-table-modal form .add-booking-field-full input {
        width: 90%; }
      .book-table-modal form .add-booking-field .react-datepicker-wrapper, .book-table-modal form .add-booking-field-full .react-datepicker-wrapper {
        width: 100%; }
      .book-table-modal form .add-booking-field .select-container, .book-table-modal form .add-booking-field-full .select-container {
        margin: 8px 0;
        width: 90%; }
        .book-table-modal form .add-booking-field .select-container div [class*='control'], .book-table-modal form .add-booking-field-full .select-container div [class*='control'] {
          border: 1px solid #e0e7ee;
          height: 50px; }
      .book-table-modal form .add-booking-field-full, .book-table-modal form .add-booking-field-full-full {
        width: 100%; }
    .book-table-modal form div[data-radium] {
      margin-bottom: 2vh; }
    .book-table-modal form .helper-text {
      position: relative;
      top: -1vh;
      color: transparent; }
      .book-table-modal form .helper-text.show {
        color: inherit; }
    .book-table-modal form .timeslot-message {
      font-size: 16px;
      white-space: pre-wrap;
      height: 35px;
      line-height: 35px;
      vertical-align: middle;
      margin: 5px 5px 5px 0; }
  .book-table-modal .modal-footer {
    margin-top: 0; }

.book-table-modal-mobile {
  max-width: 100%;
  width: 100%;
  position: relative;
  overflow-x: hidden;
  overflow-y: auto;
  align-content: left; }
  .book-table-modal-mobile .info-message {
    width: 95%;
    margin-bottom: 20px;
    min-height: 50px; }
  .book-table-modal-mobile .add-booking-modal-mobile-form {
    width: 100%; }
    .book-table-modal-mobile .add-booking-modal-mobile-form .add-booking-field, .book-table-modal-mobile .add-booking-modal-mobile-form .add-booking-field-full {
      display: block;
      margin-bottom: 25px;
      width: 100%; }
      .book-table-modal-mobile .add-booking-modal-mobile-form .add-booking-field label, .book-table-modal-mobile .add-booking-modal-mobile-form .add-booking-field-full label {
        color: #7c9fb0;
        font-size: 14px;
        font-weight: bold;
        word-wrap: break-word;
        display: block; }
      .book-table-modal-mobile .add-booking-modal-mobile-form .add-booking-field input, .book-table-modal-mobile .add-booking-modal-mobile-form .add-booking-field-full input {
        width: 100%; }
      .book-table-modal-mobile .add-booking-modal-mobile-form .add-booking-field .select-container, .book-table-modal-mobile .add-booking-modal-mobile-form .add-booking-field-full .select-container {
        margin: 8px 0; }
        .book-table-modal-mobile .add-booking-modal-mobile-form .add-booking-field .select-container div [class*='control'], .book-table-modal-mobile .add-booking-modal-mobile-form .add-booking-field-full .select-container div [class*='control'] {
          border: 1px solid #e0e7ee;
          height: 50px; }
      .book-table-modal-mobile .add-booking-modal-mobile-form .add-booking-field:last-of-type, .book-table-modal-mobile .add-booking-modal-mobile-form .add-booking-field-full:last-of-type {
        margin-bottom: 45px; }
    .book-table-modal-mobile .add-booking-modal-mobile-form div[data-radium] {
      margin-bottom: 2vh; }
    .book-table-modal-mobile .add-booking-modal-mobile-form .timeslot-message {
      font-size: 16px;
      line-height: inherit;
      white-space: pre-wrap; }

.timeslot-container .spinner {
  margin-left: 40px; }

.timeslot-container .selectable-chip {
  display: inline-block;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content; }

.success-modal {
  max-width: 633px; }

.horizontal-background.home-page-background-mobile {
  background: url("/images/Mobile-PalmOverlay-Home.svg"), #3f417e;
  background-position-x: left;
  background-position-y: bottom;
  background-repeat: no-repeat;
  background-size: cover;
  height: 16.5em; }

.welcome-widget {
  text-align: center;
  z-index: 1; }

.navigation-scroll {
  overflow-x: scroll;
  display: flex;
  justify-content: flex-start;
  padding: 0 17px;
  flex: 1 1; }
  .navigation-scroll .widget-mobile-container {
    margin: auto;
    padding: 0 5px; }
  .navigation-scroll .widget.nav-option .widget-contents {
    box-shadow: none; }
  .navigation-scroll:after {
    content: '';
    min-width: 1px; }

.standard-widget-container-mobile .widget-contents {
  text-align: center;
  padding: 15px; }

.standard-widget-container-mobile div {
  text-transform: uppercase;
  color: #184f6d;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0.94px;
  line-height: 16px; }

.standard-widget-container-mobile .standard-widget-text {
  color: #7c9fb0;
  font-size: 14px;
  margin-top: 0.5em; }

.dropdown-list-widget {
  padding-top: 0; }

.dropdown-list-widget .widget-contents {
  background-color: unset;
  box-shadow: unset; }
  .dropdown-list-widget .widget-contents p {
    color: #90a1b5;
    font-size: 14px;
    line-height: 14px;
    margin-top: 0;
    margin-bottom: 10px; }
  .dropdown-list-widget .widget-contents .select-container {
    border: unset; }
  .dropdown-list-widget .widget-contents .mobile-select-container .mobile-select__option {
    height: 34px;
    background-color: unset; }
  .dropdown-list-widget .widget-contents .mobile-select-container .mobile-select__control {
    min-width: 15em;
    width: 100%;
    height: 3em;
    border: 1px solid #e0e7ee; }
    .dropdown-list-widget .widget-contents .mobile-select-container .mobile-select__control--is-focused {
      box-shadow: unset;
      border: 1px solid #2b8aed; }
  .dropdown-list-widget .widget-contents .mobile-select-container .mobile-select__menu {
    margin-top: 5px;
    padding: 4px 0;
    border-radius: 4px; }
  .dropdown-list-widget .widget-contents .mobile-select-container:focus {
    border: unset; }
  .dropdown-list-widget .widget-contents .mobile-select-container .mobile-select__indicator-separator {
    width: 0; }
  .dropdown-list-widget .widget-contents .btn {
    width: 100%;
    margin: 10px 0;
    color: #ffffff;
    font-weight: bold;
    background: -webkit-gradient(linear, left top, right top, from(#2686ed), to(#5ca7ed));
    background: linear-gradient(90deg, #2686ed 0%, #5ca7ed 100%);
    border: unset;
    box-shadow: 0 2px 17px -5px rgba(95, 129, 226, 0.4);
    text-transform: uppercase; }
    .dropdown-list-widget .widget-contents .btn:disabled {
      opacity: 0.4; }

.clickable-label-header {
  cursor: pointer; }
  .clickable-label-header label {
    cursor: pointer; }

.header .header-container {
  margin: 2.5em auto 0;
  width: 100%;
  max-width: 1244px;
  z-index: 1;
  position: relative;
  background: #ffffff;
  border-radius: 8px;
  display: flex; }
  .header .header-container .header-bars-container {
    min-width: 100px;
    display: flex;
    justify-content: center;
    align-items: center; }
    .header .header-container .header-bars-container .header-bars {
      color: #1b4e6b;
      opacity: 0.35;
      font-size: 28px; }
      .header .header-container .header-bars-container .header-bars:hover {
        cursor: pointer; }

.header .header-menu {
  max-width: 1244px;
  display: flex;
  justify-content: space-around; }
  .header .header-menu.open {
    margin: 60px auto 0 auto; }
  .header .header-menu .nav-option {
    height: 230px;
    flex-basis: 175px;
    margin-top: 0;
    -webkit-transition: all 0.1s ease-in-out;
    transition: all 0.1s ease-in-out; }
    .header .header-menu .nav-option:hover {
      -webkit-transform: scale(1.1);
              transform: scale(1.1); }
    .header .header-menu .nav-option .widget-contents {
      box-shadow: none; }

.horizontal-background {
  background: #3f417e;
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  top: 0;
  bottom: 60%;
  left: 0;
  right: 0;
  min-height: 385px;
  -webkit-transition: bottom ease 0.25s;
  transition: bottom ease 0.25s; }
  @media only screen and (min-width: 1024px) {
    .horizontal-background.header-open {
      bottom: 40%; } }
  @media only screen and (min-width: 1440px) {
    .horizontal-background.header-open {
      bottom: 50%; } }

.header-label {
  background-color: white; }

.header-logo-container {
  padding: 1em;
  height: 2.5em; }
  .header-logo-container img {
    height: 100%;
    -o-user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
        user-select: none; }

.header-item-container {
  height: 4.5em;
  display: flex;
  justify-content: flex-end;
  margin-left: auto; }
  .header-item-container .header-item, .header-item-container .header-item-dropdown {
    border-left: 1px solid #e3dede;
    text-align: center;
    color: #1e2171;
    font-size: 16px;
    display: flex;
    align-items: center;
    padding: 0 2vw; }
  .header-item-container .header-item-dropdown {
    padding: 0; }
  .header-item-container .my-details-container {
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    opacity: 0;
    visibility: hidden;
    margin-left: 10px; }
    .header-item-container .my-details-container svg {
      margin-right: 10px;
      font-size: 23px;
      vertical-align: middle; }
    .header-item-container .my-details-container.show {
      opacity: 1;
      visibility: visible; }
  .header-item-container .contract-container {
    height: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column; }
    .header-item-container .contract-container div {
      line-height: normal; }
    .header-item-container .contract-container .contract-end-date {
      color: #7c9fb0;
      font-size: 13px;
      text-transform: uppercase;
      margin-top: 1em; }
  .header-item-container > div {
    -webkit-transition: background-color ease-in-out 0.3s;
    transition: background-color ease-in-out 0.3s;
    will-change: background-color; }
    .header-item-container > div:last-child {
      border-top-right-radius: 8px;
      border-bottom-right-radius: 8px; }
    .header-item-container > div:hover {
      background-color: #f5f6fb; }

.header-select-container {
  min-width: 15em;
  line-height: normal !important; }
  .header-select-container .header-select__option {
    height: 34px;
    background-color: unset;
    color: #1b4e6b; }
    .header-select-container .header-select__option:hover {
      background: #f5f6fb; }
  .header-select-container .header-select__control {
    min-width: 15em;
    width: 100%;
    height: 4.5em;
    border-radius: 0;
    border: unset; }
    @media only screen and (max-width: 1023px) {
      .header-select-container .header-select__control {
        border-radius: 5px;
        height: 50px; } }
    .header-select-container .header-select__control--is-focused {
      box-shadow: unset;
      background-color: #f5f6fb; }
    .header-select-container .header-select__control:hover {
      background-color: #f5f6fb; }
    .header-select-container .header-select__control .header-select__single-value {
      color: #1e2171; }
  .header-select-container .header-select__menu {
    margin-top: 10px;
    padding: 4px 0;
    border-radius: 4px; }
  .header-select-container:focus {
    border: unset; }
  .header-select-container .header-select__indicator-separator {
    width: 0; }

.mobile-header-container {
  display: flex;
  width: 100%;
  z-index: 1;
  position: relative;
  color: #fff;
  justify-content: space-between;
  align-items: center;
  height: 50px;
  margin-top: 2em;
  padding: 10px;
  text-transform: uppercase;
  font-size: 14px;
  box-sizing: border-box; }
  .mobile-header-container .mobile-header-lodge {
    flex: 1 1;
    text-align: center; }

.mobile-side-nav {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  overflow-y: scroll; }
  .mobile-side-nav .mobile-side-nav-content {
    background: rgba(63, 65, 126, 0.8);
    padding: 10px;
    padding-top: 28px;
    min-height: calc(100% - 38px);
    display: flex;
    overflow-x: hidden; }
    .mobile-side-nav .mobile-side-nav-content .mobile-side-nav-close {
      color: #fff;
      font-size: 35px;
      padding: 7px;
      width: 31px;
      text-align: center; }
      .mobile-side-nav .mobile-side-nav-content .mobile-side-nav-close.hover {
        cursor: pointer; }
    .mobile-side-nav .mobile-side-nav-content .mobile-side-nav-options {
      overflow: hidden;
      display: flex;
      align-content: flex-start;
      flex-wrap: wrap; }
      .mobile-side-nav .mobile-side-nav-content .mobile-side-nav-options .widget-contents {
        box-shadow: none; }
      .mobile-side-nav .mobile-side-nav-content .mobile-side-nav-options .select-container {
        flex-basis: 100%;
        border-left: none;
        margin-bottom: 25px; }
      .mobile-side-nav .mobile-side-nav-content .mobile-side-nav-options > * {
        flex-basis: 120px; }
      .mobile-side-nav .mobile-side-nav-content .mobile-side-nav-options .nav-option {
        margin-right: 25px;
        margin-bottom: 10px; }
    .mobile-side-nav .mobile-side-nav-content .mobile-side-nav-widget-container-mobile {
      padding-left: 0; }
      .mobile-side-nav .mobile-side-nav-content .mobile-side-nav-widget-container-mobile .widget-contents div {
        text-transform: uppercase;
        color: #184f6d;
        font-size: 16px;
        font-weight: bold;
        letter-spacing: 0.94px;
        line-height: 50px;
        min-width: 15em;
        width: 100%;
        height: 50px;
        text-align: center;
        vertical-align: middle; }

.nav-option {
  box-shadow: 0 5px 8px 4px rgba(25, 25, 104, 0.17);
  height: 170px;
  border-radius: 12px;
  background: #fff;
  margin-top: 15px; }
  .nav-option:hover {
    cursor: pointer; }

.contact-modal {
  width: 650px;
  align-items: flex-start;
  overflow: auto; }
  @media only screen and (max-width: 1023px) {
    .contact-modal {
      width: 100%;
      position: relative; } }
  .contact-modal .contact-details-section {
    max-width: 100%; }
    .contact-modal .contact-details-section .description {
      font-size: 16px;
      line-height: 22px; }
    .contact-modal .contact-details-section .contact-details {
      display: flex;
      flex-wrap: wrap; }
      @media only screen and (max-width: 1023px) {
        .contact-modal .contact-details-section .contact-details {
          flex-direction: column; }
          .contact-modal .contact-details-section .contact-details > div {
            margin-bottom: 25px; } }
      .contact-modal .contact-details-section .contact-details .title {
        font-weight: bold;
        font-size: 14px;
        line-height: 14px;
        color: #7c9fb0;
        margin-bottom: 10px; }
      .contact-modal .contact-details-section .contact-details .value {
        font-size: 20px;
        line-height: 18px;
        margin-bottom: 10px; }
        .contact-modal .contact-details-section .contact-details .value.address {
          white-space: pre;
          line-height: 25px; }
      .contact-modal .contact-details-section .contact-details a.value {
        color: #008bff; }
      .contact-modal .contact-details-section .contact-details > div {
        flex-basis: 50%; }

.owner-contact-details-modal {
  width: 650px;
  overflow-x: hidden;
  overflow-y: auto; }
  .owner-contact-details-modal form {
    width: 100%; }
  .owner-contact-details-modal .requirement {
    display: inline-flex;
    width: 50%; }
  .owner-contact-details-modal p.update-password-title {
    color: #1e2171;
    font-size: 29px;
    line-height: 29px;
    letter-spacing: 0.43px;
    margin-bottom: 0; }
  .owner-contact-details-modal .info-message.show {
    line-height: 36px; }

.marketing-modal-field-container p {
  text-align: center; }

.marketing-modal-field-container .marketing-preferences-container {
  padding: 0 20% 0 25%;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 30px; }
  .marketing-modal-field-container .marketing-preferences-container .contact-details-modal-field {
    padding-top: 5px;
    margin: 0;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
    width: 50%;
    padding-right: 5%;
    min-width: 18ch; }
    .marketing-modal-field-container .marketing-preferences-container .contact-details-modal-field label {
      margin-bottom: 0; }

.contact-details-modal-field {
  display: inline-block;
  width: 50%;
  margin-top: 25px;
  margin-bottom: 25px;
  vertical-align: top; }
  .contact-details-modal-field label {
    color: #7c9fb0;
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 10px;
    word-wrap: break-word;
    display: block; }
  .contact-details-modal-field span {
    color: #184f6d;
    font-size: 20px;
    word-wrap: break-word;
    margin-right: 5px; }
  .contact-details-modal-field.password-field {
    width: 100%;
    margin-bottom: 0px; }

.owner-contact-details-modal-mobile {
  max-width: 100%;
  width: 100%;
  position: relative; }
  .owner-contact-details-modal-mobile .contact-details-modal-field {
    margin-bottom: 25px; }
    .owner-contact-details-modal-mobile .contact-details-modal-field.password-field {
      margin-bottom: 0px; }
  .owner-contact-details-modal-mobile .email-field,
  .owner-contact-details-modal-mobile .address1-field,
  .owner-contact-details-modal-mobile .address2-field,
  .owner-contact-details-modal-mobile .city-field {
    width: 100%; }
  .owner-contact-details-modal-mobile .react-tabs__tab-list {
    margin-left: -60px;
    margin-right: -20px; }
  .owner-contact-details-modal-mobile .requirement {
    display: flex;
    width: 100%; }

.react-tabs__tab-list {
  margin-left: -80px;
  margin-right: -41px;
  margin-top: 0;
  margin-bottom: 25px;
  display: flex; }

.react-tabs__tab {
  height: 70px;
  line-height: 70px;
  vertical-align: middle;
  color: #184f6d;
  font-family: Foco;
  font-size: 17px;
  border: none;
  flex: 1 1;
  text-align: center;
  vertical-align: middle;
  list-style-type: none;
  outline-width: 0;
  cursor: pointer;
  opacity: 0.3;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  border-bottom: 3px solid #1c4d6b; }

.react-tabs__tab--selected {
  border-bottom: 3px solid #1c4d6b;
  opacity: 1; }

.react-tabs__tab-panel {
  display: none; }

.react-tabs__tab-panel--selected {
  display: block; }

.owner-contact-details-input {
  width: 90%; }

.owner-contact-details-password-input {
  width: 100%; }

.requirements-container .requirements-title {
  color: #1e2171;
  margin-bottom: 10px;
  letter-spacing: 0.5px;
  font-size: 16px; }

@media only screen and (max-width: 1023px) {
  .requirements-container {
    background-color: rgba(255, 255, 255, 0.5);
    color: #184f6d;
    margin-bottom: 8px;
    width: 100%; }
    .requirements-container .requirements-title {
      color: #1e2171; } }

.requirement {
  display: flex;
  align-items: center;
  margin-bottom: 10px; }
  .requirement p {
    color: #184f6d;
    margin-bottom: 0;
    margin-left: 9px;
    font-size: 16px; }
  @media only screen and (max-width: 1023px) {
    .requirement:last-child {
      margin-bottom: 0; } }

.owner-contact-details-modal {
  width: 600px;
  overflow-x: hidden;
  overflow-y: auto; }
  .owner-contact-details-modal form {
    width: 100%; }
  .owner-contact-details-modal .requirement {
    display: inline-flex;
    width: 50%; }
  .owner-contact-details-modal p.update-password-title {
    color: #1e2171;
    font-size: 29px;
    line-height: 29px;
    letter-spacing: 0.43px;
    margin-bottom: 0; }
  .owner-contact-details-modal .info-message.show {
    line-height: 36px; }

.marketing-modal-field-container p {
  text-align: center; }

.marketing-modal-field-container .marketing-preferences-container {
  padding: 0 20% 0 25%;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 30px; }
  .marketing-modal-field-container .marketing-preferences-container .contact-details-modal-field {
    padding-top: 5px;
    margin: 0;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
    width: 50%;
    padding-right: 5%;
    min-width: 18ch; }
    .marketing-modal-field-container .marketing-preferences-container .contact-details-modal-field label {
      margin-bottom: 0; }

.contact-details-modal-field {
  display: inline-block;
  width: 50%;
  margin-top: 25px;
  margin-bottom: 25px;
  vertical-align: top; }
  .contact-details-modal-field label {
    color: #7c9fb0;
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 10px;
    word-wrap: break-word;
    display: block; }
  .contact-details-modal-field span {
    color: #184f6d;
    font-size: 20px;
    word-wrap: break-word;
    margin-right: 5px; }
  .contact-details-modal-field.password-field {
    width: 100%;
    margin-bottom: 0px; }

.owner-contact-details-modal-mobile {
  max-width: 100%;
  width: 90%;
  height: 60%;
  margin-top: 30%;
  position: relative; }
  .owner-contact-details-modal-mobile .contact-details-modal-field {
    margin-bottom: 25px; }
    .owner-contact-details-modal-mobile .contact-details-modal-field.password-field {
      margin-bottom: 0px; }
  .owner-contact-details-modal-mobile .email-field,
  .owner-contact-details-modal-mobile .address1-field,
  .owner-contact-details-modal-mobile .address2-field,
  .owner-contact-details-modal-mobile .city-field {
    width: 100%; }
  .owner-contact-details-modal-mobile .react-tabs__tab-list {
    margin-left: -60px;
    margin-right: -20px; }
  .owner-contact-details-modal-mobile .requirement {
    display: flex;
    width: 100%; }

.react-tabs__tab-list {
  margin-left: -80px;
  margin-right: -41px;
  margin-top: 0;
  margin-bottom: 25px;
  display: flex; }

.react-tabs__tab {
  height: 70px;
  line-height: 70px;
  vertical-align: middle;
  color: #184f6d;
  font-family: Foco;
  font-size: 17px;
  border: none;
  flex: 1 1;
  text-align: center;
  vertical-align: middle;
  list-style-type: none;
  outline-width: 0;
  cursor: pointer;
  opacity: 0.3;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  border-bottom: 3px solid #1c4d6b; }

.react-tabs__tab--selected {
  border-bottom: 3px solid #1c4d6b;
  opacity: 1; }

.react-tabs__tab-panel {
  display: none; }

.react-tabs__tab-panel--selected {
  display: block; }

.owner-contact-details-input {
  width: 90%; }

.owner-contact-details-password-input {
  width: 100%; }

.button-container-modal .container {
  text-align: center; }

.footer-button {
  margin: 10px;
  width: 175px;
  height: 50px; }

/*
#add {
  margin-left: 10%;
}
*/
@media screen and (min-width: 600px) {
  .refermodal {
    width: 30%; }
  .referAlertmodal {
    width: 18%; } }

@media screen and (max-width: 600px) {
  .refermodal {
    padding-top: 30%; } }

@media screen and (max-width: 600px) {
  .button-center-mobile {
    padding: 0px 20%; } }

.news-card {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 28px -5px rgba(95, 129, 226, 0.4);
  overflow: hidden;
  margin-bottom: 35px;
  max-width: 360px;
  margin-left: auto;
  margin-right: auto; }
  .news-card:hover {
    cursor: pointer; }
  .news-card .news-image {
    height: 130px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center; }
    .news-card .news-image img {
      width: 100%;
      height: auto; }
  .news-card .description-container {
    padding: 0 20px; }
    .news-card .description-container .title {
      font-size: 20px;
      font-weight: bold;
      line-height: 25px;
      letter-spacing: 0.6px;
      margin: 0;
      padding: 15px 0;
      border-bottom: 1px solid #eeeeee; }
    .news-card .description-container .publish-date {
      font-size: 14px;
      font-weight: bold;
      line-height: 25px;
      letter-spacing: 0.4px;
      color: #80a2b2;
      margin: 0;
      padding: 5px 0; }

@media only screen and (max-width: 1023px) {
  .news-error {
    text-align: center; } }

.image-background-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; }
  .image-background-container img.image-background {
    position: fixed;
    width: 100%;
    height: 100%; }
  .image-background-container .image-background-children {
    z-index: 1;
    width: 85%; }

.vertical-background-container {
  flex: 50% 1;
  height: 100%; }
  .vertical-background-container .vertical-background-image {
    position: absolute;
    height: 100%;
    bottom: 0;
    left: 0; }

.version-number {
  color: #a6a8b1;
  font-size: 10px; }

.login-welcome-message {
  color: #fff; }
  .login-welcome-message p {
    color: #fff; }

.welcome-background {
  background: #3f417e;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-direction: column;
  color: #fff;
  overflow: hidden; }
  .welcome-background img.image-background {
    width: unset;
    left: 0;
    bottom: 0; }
  .welcome-background h1 {
    font-size: 32px;
    width: 200px;
    letter-spacing: 0.6px;
    line-height: 36px; }
  .welcome-background p {
    width: 250px;
    letter-spacing: 1px;
    line-height: 19px;
    font-size: 16px; }
  .welcome-background .btn {
    margin-top: 10%;
    margin-bottom: 15%; }

.login-background {
  background: #3f417e;
  display: flex;
  align-items: center;
  flex-direction: column;
  color: #fff;
  overflow: auto; }
  .login-background img.image-background {
    width: unset;
    left: 0;
    bottom: 0; }
  .login-background .register-button-container {
    width: 50%;
    margin-left: 50%;
    margin-bottom: 15%; }
    .login-background .register-button-container .btn {
      margin-bottom: 0;
      margin-left: 8px !important; }
  .login-background .btn {
    margin-top: 10%;
    margin-bottom: 5%; }

.login-failure-message {
  color: transparent; }
  .login-failure-message.show {
    color: #ffffff; }

.login-back-mobile a {
  color: #fff;
  position: absolute;
  right: 7.5%;
  top: 20px; }

.login-page-vertical-background {
  background: #3f417e;
  position: relative;
  overflow: hidden; }
  .login-page-vertical-background img {
    align-self: flex-start; }

.login-page-container {
  display: flex;
  height: 100%;
  background-color: #fff; }
  .login-page-container .login-popup-container {
    flex: 50% 1;
    overflow-y: auto; }
    .login-page-container .login-popup-container .login-back {
      margin: 5vh 5vw 0 auto; }
    .login-page-container .login-popup-container form,
    .login-page-container .login-popup-container .login-input-outer-container {
      margin-left: 5vw;
      margin-right: 5vw; }
    .login-page-container .login-popup-container .button-container:last-child {
      margin-right: 5vw;
      justify-content: flex-end;
      align-items: flex-end; }
      .login-page-container .login-popup-container .button-container:last-child .btn-secondary {
        border: 1px solid #cdcdcd;
        width: 175px;
        flex: none; }
  .login-page-container .vertical-background-container {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-direction: column;
    color: #fff; }
    .login-page-container .vertical-background-container .vertical-background-children {
      margin-bottom: 40%;
      min-width: 385px;
      align-self: flex-start;
      margin-left: 15%;
      width: 400px; }
      .login-page-container .vertical-background-container .vertical-background-children h1 {
        font-size: 69px;
        letter-spacing: 1.29px;
        line-height: 79px; }
      .login-page-container .vertical-background-container .vertical-background-children p {
        font-size: 23px;
        letter-spacing: 1.44px;
        line-height: 30px; }
  .login-page-container .link-right {
    font-size: 14px;
    line-height: 19px;
    letter-spacing: 0.88px; }
  .login-page-container .heading-center {
    color: #184f6d;
    font-size: 14px;
    line-height: 19px;
    letter-spacing: 0.88px;
    margin-top: 10px;
    text-align: center; }
  .login-page-container .heading-right {
    color: #184f6d;
    font-size: 14px;
    line-height: 19px;
    letter-spacing: 0.88px;
    margin-top: 10px;
    text-align: right; }

.login-input-outer-container {
  margin-bottom: 2vh;
  margin-top: 16vh; }
  .login-input-outer-container label {
    margin-bottom: 1vh; }
  .login-input-outer-container .select-container {
    z-index: 3;
    color: #184f6d; }
  .login-input-outer-container.mobile {
    margin-top: 10%; }

.forgotten-password-link {
  text-align: right;
  margin-bottom: 5px; }

.login-park-dropdown > div > div {
  min-height: 50px; }

.separator {
  margin-top: 8px;
  line-height: 30px;
  display: flex;
  flex-direction: row;
  justify-content: space-between; }
  .separator .text {
    text-align: center;
    margin: 0 10px;
    padding: 0 10px;
    position: relative;
    z-index: 1;
    color: #fff; }
  .separator .line {
    width: 100%;
    border-top: 1px solid #fff;
    margin-top: 15px; }
  .separator.dark .text {
    color: #a0a0a0; }
  .separator.dark .line {
    border-top: 1px solid #a0a0a0; }

.external-login-container {
  margin-left: 5vw;
  margin-right: 5vw; }
  .external-login-container.mobile {
    margin-left: 0;
    margin-right: 0; }
  .external-login-container .google-login-button-container .google-login-button {
    color: #184f6d;
    text-align: center;
    background-color: white;
    border-radius: 4px;
    border: solid 1px rgba(151, 151, 151, 0.48);
    padding: 15px;
    width: 100%;
    font-family: "Foco", Arial, sans-serif;
    font-size: 16px;
    text-transform: uppercase;
    font-weight: bold;
    cursor: pointer;
    line-height: 18px; }
    .external-login-container .google-login-button-container .google-login-button span {
      display: inline-block;
      text-align: left; }
    .external-login-container .google-login-button-container .google-login-button img {
      height: 18px;
      margin-right: 20px;
      vertical-align: bottom; }
    .external-login-container .google-login-button-container .google-login-button:hover {
      background-color: #f5f6fb;
      border: solid 1px #cdcdcd; }
    .external-login-container .google-login-button-container .google-login-button:disabled {
      opacity: 0.5;
      cursor: not-allowed; }
  .external-login-container .facebook-login-button-container {
    margin-bottom: 2vh; }
    .external-login-container .facebook-login-button-container .facebook-login-button {
      color: white;
      text-align: center;
      background-color: #3b5998;
      border-radius: 4px;
      border: solid 1px rgba(151, 151, 151, 0.48);
      padding: 15px;
      width: 100%;
      font-family: "Foco", Arial, sans-serif;
      font-size: 16px;
      text-transform: uppercase;
      font-weight: bold;
      cursor: pointer;
      line-height: 18px; }
      .external-login-container .facebook-login-button-container .facebook-login-button span {
        display: inline-block;
        text-align: left; }
      .external-login-container .facebook-login-button-container .facebook-login-button img {
        height: 18px;
        margin-right: 20px;
        vertical-align: bottom; }
      .external-login-container .facebook-login-button-container .facebook-login-button:disabled {
        opacity: 0.5;
        cursor: not-allowed; }

.add-booking {
  right: 50px;
  top: -20px;
  z-index: 2; }
  @media only screen and (max-width: 1023px) {
    .add-booking {
      right: 30px;
      top: 200px; } }

.rbc-event.events {
  background-color: #e662bb;
  margin-left: -1px;
  height: 10px !important;
  width: 10px;
  -webkit-transform: translate(10px, 25px);
          transform: translate(10px, 25px);
  padding: 0; }

.my-calendar {
  height: 700px; }
  .my-calendar:not(.mobile-calendar) .rbc-month-view .rbc-row-content .rbc-row-segment .rbc-event-wrapper,
  .my-calendar:not(.mobile-calendar) .rbc-calendar .rbc-month-view .rbc-row .rbc-row-segment .rbc-event-wrapper {
    margin-left: 37px;
    margin-right: 40px; }
    .my-calendar:not(.mobile-calendar) .rbc-month-view .rbc-row-content .rbc-row-segment .rbc-event-wrapper .rbc-event,
    .my-calendar:not(.mobile-calendar) .rbc-calendar .rbc-month-view .rbc-row .rbc-row-segment .rbc-event-wrapper .rbc-event {
      height: 45px; }
      .my-calendar:not(.mobile-calendar) .rbc-month-view .rbc-row-content .rbc-row-segment .rbc-event-wrapper .rbc-event.my-holidays:before, .my-calendar:not(.mobile-calendar) .rbc-month-view .rbc-row-content .rbc-row-segment .rbc-event-wrapper .rbc-event.sublet-bookings:before, .my-calendar:not(.mobile-calendar) .rbc-month-view .rbc-row-content .rbc-row-segment .rbc-event-wrapper .rbc-event.my-holidays.pending:before,
      .my-calendar:not(.mobile-calendar) .rbc-calendar .rbc-month-view .rbc-row .rbc-row-segment .rbc-event-wrapper .rbc-event.my-holidays:before,
      .my-calendar:not(.mobile-calendar) .rbc-calendar .rbc-month-view .rbc-row .rbc-row-segment .rbc-event-wrapper .rbc-event.sublet-bookings:before,
      .my-calendar:not(.mobile-calendar) .rbc-calendar .rbc-month-view .rbc-row .rbc-row-segment .rbc-event-wrapper .rbc-event.my-holidays.pending:before {
        top: 18px;
        left: -43px; }
      .my-calendar:not(.mobile-calendar) .rbc-month-view .rbc-row-content .rbc-row-segment .rbc-event-wrapper .rbc-event.my-holidays:after, .my-calendar:not(.mobile-calendar) .rbc-month-view .rbc-row-content .rbc-row-segment .rbc-event-wrapper .rbc-event.sublet-bookings:after, .my-calendar:not(.mobile-calendar) .rbc-month-view .rbc-row-content .rbc-row-segment .rbc-event-wrapper .rbc-event.my-holidays.pending:after,
      .my-calendar:not(.mobile-calendar) .rbc-calendar .rbc-month-view .rbc-row .rbc-row-segment .rbc-event-wrapper .rbc-event.my-holidays:after,
      .my-calendar:not(.mobile-calendar) .rbc-calendar .rbc-month-view .rbc-row .rbc-row-segment .rbc-event-wrapper .rbc-event.sublet-bookings:after,
      .my-calendar:not(.mobile-calendar) .rbc-calendar .rbc-month-view .rbc-row .rbc-row-segment .rbc-event-wrapper .rbc-event.my-holidays.pending:after {
        top: -5px;
        right: -43px; }
      .my-calendar:not(.mobile-calendar) .rbc-month-view .rbc-row-content .rbc-row-segment .rbc-event-wrapper .rbc-event.my-holidays:before, .my-calendar:not(.mobile-calendar) .rbc-month-view .rbc-row-content .rbc-row-segment .rbc-event-wrapper .rbc-event.my-holidays:after, .my-calendar:not(.mobile-calendar) .rbc-month-view .rbc-row-content .rbc-row-segment .rbc-event-wrapper .rbc-event.sublet-bookings:before, .my-calendar:not(.mobile-calendar) .rbc-month-view .rbc-row-content .rbc-row-segment .rbc-event-wrapper .rbc-event.sublet-bookings:after, .my-calendar:not(.mobile-calendar) .rbc-month-view .rbc-row-content .rbc-row-segment .rbc-event-wrapper .rbc-event.my-holidays.pending:before, .my-calendar:not(.mobile-calendar) .rbc-month-view .rbc-row-content .rbc-row-segment .rbc-event-wrapper .rbc-event.my-holidays.pending:after,
      .my-calendar:not(.mobile-calendar) .rbc-calendar .rbc-month-view .rbc-row .rbc-row-segment .rbc-event-wrapper .rbc-event.my-holidays:before,
      .my-calendar:not(.mobile-calendar) .rbc-calendar .rbc-month-view .rbc-row .rbc-row-segment .rbc-event-wrapper .rbc-event.my-holidays:after,
      .my-calendar:not(.mobile-calendar) .rbc-calendar .rbc-month-view .rbc-row .rbc-row-segment .rbc-event-wrapper .rbc-event.sublet-bookings:before,
      .my-calendar:not(.mobile-calendar) .rbc-calendar .rbc-month-view .rbc-row .rbc-row-segment .rbc-event-wrapper .rbc-event.sublet-bookings:after,
      .my-calendar:not(.mobile-calendar) .rbc-calendar .rbc-month-view .rbc-row .rbc-row-segment .rbc-event-wrapper .rbc-event.my-holidays.pending:before,
      .my-calendar:not(.mobile-calendar) .rbc-calendar .rbc-month-view .rbc-row .rbc-row-segment .rbc-event-wrapper .rbc-event.my-holidays.pending:after {
        border-width: 32px; }
      .my-calendar:not(.mobile-calendar) .rbc-month-view .rbc-row-content .rbc-row-segment .rbc-event-wrapper .rbc-event.rbc-event.rbc-event-continues-after:after,
      .my-calendar:not(.mobile-calendar) .rbc-calendar .rbc-month-view .rbc-row .rbc-row-segment .rbc-event-wrapper .rbc-event.rbc-event.rbc-event-continues-after:after {
        top: 0;
        right: -47px; }
      .my-calendar:not(.mobile-calendar) .rbc-month-view .rbc-row-content .rbc-row-segment .rbc-event-wrapper .rbc-event.rbc-event.rbc-event-continues-after.rbc-event-starts-on-week-end:before,
      .my-calendar:not(.mobile-calendar) .rbc-calendar .rbc-month-view .rbc-row .rbc-row-segment .rbc-event-wrapper .rbc-event.rbc-event.rbc-event-continues-after.rbc-event-starts-on-week-end:before {
        right: -40px; }
      .my-calendar:not(.mobile-calendar) .rbc-month-view .rbc-row-content .rbc-row-segment .rbc-event-wrapper .rbc-event.rbc-event.rbc-event-continues-prior:after,
      .my-calendar:not(.mobile-calendar) .rbc-calendar .rbc-month-view .rbc-row .rbc-row-segment .rbc-event-wrapper .rbc-event.rbc-event.rbc-event-continues-prior:after {
        right: -43px; }
      .my-calendar:not(.mobile-calendar) .rbc-month-view .rbc-row-content .rbc-row-segment .rbc-event-wrapper .rbc-event.rbc-event.rbc-event-continues-prior.rbc-event-ends-on-week-start:after,
      .my-calendar:not(.mobile-calendar) .rbc-calendar .rbc-month-view .rbc-row .rbc-row-segment .rbc-event-wrapper .rbc-event.rbc-event.rbc-event-continues-prior.rbc-event-ends-on-week-start:after {
        right: -33px; }
      .my-calendar:not(.mobile-calendar) .rbc-month-view .rbc-row-content .rbc-row-segment .rbc-event-wrapper .rbc-event.rbc-event.rbc-event-continues-prior.rbc-event-continues-after:before,
      .my-calendar:not(.mobile-calendar) .rbc-calendar .rbc-month-view .rbc-row .rbc-row-segment .rbc-event-wrapper .rbc-event.rbc-event.rbc-event-continues-prior.rbc-event-continues-after:before {
        width: 49px;
        left: -49px; }
      .my-calendar:not(.mobile-calendar) .rbc-month-view .rbc-row-content .rbc-row-segment .rbc-event-wrapper .rbc-event.rbc-event.rbc-event-continues-prior.rbc-event-continues-after:after,
      .my-calendar:not(.mobile-calendar) .rbc-calendar .rbc-month-view .rbc-row .rbc-row-segment .rbc-event-wrapper .rbc-event.rbc-event.rbc-event-continues-prior.rbc-event-continues-after:after {
        right: -47px; }
      .my-calendar:not(.mobile-calendar) .rbc-month-view .rbc-row-content .rbc-row-segment .rbc-event-wrapper .rbc-event.rbc-event.rbc-event-continues-prior:before,
      .my-calendar:not(.mobile-calendar) .rbc-calendar .rbc-month-view .rbc-row .rbc-row-segment .rbc-event-wrapper .rbc-event.rbc-event.rbc-event-continues-prior:before {
        top: 0;
        width: 49px;
        left: -49px; }

.rbc-event:after {
  content: '';
  position: absolute;
  width: 0;
  height: 0;
  top: -3px;
  right: -28px;
  background-color: transparent;
  border-left: 21px solid transparent;
  border-right: 21px solid transparent;
  -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg); }

.rbc-event:before {
  content: '';
  position: absolute;
  width: 0;
  height: 0;
  top: 12px;
  left: -28px;
  border-left: 21px solid transparent;
  border-right: 21px solid transparent;
  -webkit-transform: rotate(135deg);
          transform: rotate(135deg); }

.rbc-event.rbc-event-continues-after.sublet-bookings:after {
  background-color: #7296ff; }

.rbc-event.rbc-event-continues-after.my-holidays:after {
  background-color: #f3ad70; }

.rbc-event.rbc-event-continues-after.pending.my-holidays:after {
  background-color: #b8b8b8; }

.rbc-event.rbc-event-continues-after:not(.rbc-event-continues-prior):after {
  border-bottom: none;
  width: 30px;
  -webkit-transform: none;
          transform: none;
  top: 0;
  right: -34px;
  height: 100%; }

.rbc-event.rbc-event-continues-after:not(.rbc-event-continues-prior).rbc-event-starts-on-week-end {
  padding: 0; }
  .rbc-event.rbc-event-continues-after:not(.rbc-event-continues-prior).rbc-event-starts-on-week-end:after {
    content: none; }

.rbc-event.rbc-event-continues-prior.sublet-bookings:before {
  border: none;
  background-color: #7296ff; }

.rbc-event.rbc-event-continues-prior.my-holidays:before {
  border: none;
  background-color: #f3ad70; }

.rbc-event.rbc-event-continues-prior.pending.my-holidays:before {
  border: none;
  background-color: #b8b8b8; }

.rbc-event.rbc-event-continues-prior:not(.rbc-event-continues-after):before {
  width: 32px;
  -webkit-transform: none;
          transform: none;
  top: 0;
  height: 100%;
  left: -32px; }

.rbc-event.rbc-event-continues-prior:not(.rbc-event-continues-after).rbc-event-ends-on-week-start {
  padding: 0;
  left: -38px; }
  .rbc-event.rbc-event-continues-prior:not(.rbc-event-continues-after).rbc-event-ends-on-week-start:before {
    content: none; }

.rbc-event.rbc-event-continues-prior.rbc-event-continues-after:before {
  top: 0;
  height: 100%;
  -webkit-transform: none;
          transform: none;
  width: 32px;
  left: -32px; }

.rbc-event.rbc-event-continues-prior.rbc-event-continues-after:after {
  -webkit-transform: none;
          transform: none;
  right: -34px;
  height: 100%;
  top: 0; }

.rbc-event.rbc-event-continues-prior.rbc-event-ends-on-week-start:after {
  right: -34px; }

.rbc-event.rbc-event-continues-after.rbc-event-starts-on-week-end:before {
  left: -27px; }

.rbc-event.sublet-bookings {
  background-color: #7296ff;
  opacity: 0.3;
  cursor: default; }
  .rbc-event.sublet-bookings.clickable {
    cursor: pointer; }
  .rbc-event.sublet-bookings:after {
    border-bottom: 21px solid #7296ff; }
  .rbc-event.sublet-bookings:before {
    border-bottom: 21px solid #7296ff; }

.rbc-event.my-holidays {
  background-color: #f3ad70;
  opacity: 0.3;
  cursor: default; }
  .rbc-event.my-holidays:after {
    border-bottom: 21px solid #f3ad70; }
  .rbc-event.my-holidays:before {
    border-bottom: 21px solid #f48120; }

.rbc-event.my-holidays.pending {
  background-color: #b8b8b8;
  opacity: 0.3;
  cursor: default; }
  .rbc-event.my-holidays.pending:after {
    border-bottom: 21px solid #b8b8b8; }
  .rbc-event.my-holidays.pending:before {
    border-bottom: 21px solid #9e9e9e; }

.calendar-key.sublet-bookings {
  margin-left: 43px !important; }

.calendar-key.sublet-bookings .calendar-colour {
  background-color: #b7c9f8; }

.calendar-key.my-holidays {
  margin-left: auto !important; }
  .calendar-key.my-holidays .calendar-colour {
    background-color: #f9b881; }
  .calendar-key.my-holidays.pending .calendar-colour {
    background-color: #d1d1d1; }

.calendar-key.events .calendar-colour {
  background-color: #e662bb; }

.mobile-widget-container .calendar-container {
  margin-top: 40px; }

.calendar-container {
  z-index: 0; }

.mobile-widget-container .calendar-container .calendar-keys {
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  bottom: 0px;
  padding: 10px 0; }

.mobile-widget-container .calendar-key {
  margin: 5px 0; }

.mobile-widget-container .calendar-key.my-holidays {
  margin: 5px 0;
  margin-left: 25px !important; }

.mobile-calendar.rbc-calendar {
  padding: 25px 25px 150px 25px;
  margin-bottom: 5px; }

.mobile-calendar .rbc-month-view {
  height: auto;
  top: 50px; }

.mobile-calendar.rbc-calendar .rbc-toolbar {
  margin-bottom: -50px;
  top: -10px; }

.mobile-calendar.rbc-calendar .rbc-toolbar .rbc-btn-group {
  position: relative;
  top: 0;
  left: 0;
  margin: 15px auto; }

.mobile-calendar.rbc-calendar .rbc-month-view .rbc-row.rbc-month-header {
  margin: 0 -25px;
  padding: 0 25px; }
  .mobile-calendar.rbc-calendar .rbc-month-view .rbc-row.rbc-month-header .rbc-header {
    line-height: 40px; }
    .mobile-calendar.rbc-calendar .rbc-month-view .rbc-row.rbc-month-header .rbc-header span {
      color: transparent;
      font-size: 0;
      display: inline-block; }
      .mobile-calendar.rbc-calendar .rbc-month-view .rbc-row.rbc-month-header .rbc-header span::first-letter, .mobile-calendar.rbc-calendar .rbc-month-view .rbc-row.rbc-month-header .rbc-header span:first-letter {
        color: #7c9fb0;
        font-size: 13px; }

.mobile-calendar.rbc-calendar .rbc-toolbar .rbc-toolbar-label {
  width: 100%;
  display: inline-block; }

.my-calendar-events-header {
  color: #1e2171;
  font-size: 23px;
  letter-spacing: 0.43px;
  line-height: 26px;
  margin-bottom: 20px; }

.mobile-events-header {
  background-color: #fff;
  margin: 0 -17px 0 -17px;
  padding: 20px 0;
  box-shadow: 0 2px 11px 0 rgba(84, 83, 177, 0.14); }
  .mobile-events-header .my-calendar-events-header {
    text-align: center;
    padding-bottom: 20px;
    border-bottom: 1px solid #eee;
    font-size: 19px; }
  .mobile-events-header .button-group {
    padding: 0 17px; }
    .mobile-events-header .button-group .btn {
      max-width: 100px; }

.reservation-links-widget-container .widget-contents {
  background: transparent;
  box-shadow: none; }

.guarantee {
  margin: 0 45px;
  margin-bottom: 20px;
  line-height: 21px;
  text-align: center; }
  @media only screen and (max-width: 1023px) {
    .guarantee {
      margin: 0 25px;
      margin-bottom: 25px; } }

.sublet-booking-info-modal {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  flex-direction: row;
  position: relative;
  width: 380px;
  height: auto !important; }
  .sublet-booking-info-modal .sublet-booking-info-header {
    width: 100%;
    display: flex;
    align-items: center;
    margin-bottom: 20px; }
    .sublet-booking-info-modal .sublet-booking-info-header .home-icon-container {
      display: block;
      font-size: 15px; }
  .sublet-booking-info-modal h1 {
    text-align: center;
    margin-bottom: 0;
    margin-left: 10px;
    margin-top: 10px;
    color: #184F6D; }
  .sublet-booking-info-modal span {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin-right: 10px;
    color: #141A32; }
  .sublet-booking-info-modal p {
    width: 60%;
    margin-bottom: 5px;
    color: black;
    font-weight: bold;
    font-size: 14px; }
  .sublet-booking-info-modal .sublet-booking-info-note {
    width: 100%;
    margin-top: 15px;
    color: #808390;
    font-style: normal;
    font-weight: normal; }
  .sublet-booking-info-modal .sublet-booking-info-back-button {
    width: 10px;
    height: 10px;
    position: absolute;
    right: 30px;
    top: 15px;
    cursor: pointer; }

.fab {
  width: 65px;
  height: 65px;
  border-radius: 50%;
  box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.19);
  font-size: 30px;
  line-height: 33px;
  color: #1b4e6b;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  -webkit-transition: 200ms ease-in-out;
  transition: 200ms ease-in-out; }
  .fab:hover {
    background-color: #f5f6fb;
    cursor: pointer; }

.add-booking-modal {
  width: 650px;
  overflow-x: hidden;
  overflow-y: auto; }
  .add-booking-modal form {
    width: 100%; }
    .add-booking-modal form .modal-header {
      margin-bottom: 20px; }
    .add-booking-modal form .add-booking-field, .add-booking-modal form .add-booking-field-full {
      display: inline-block;
      width: 50%;
      margin-top: 25px;
      margin-bottom: 15px;
      vertical-align: top; }
      .add-booking-modal form .add-booking-field label, .add-booking-modal form .add-booking-field-full label {
        color: #7c9fb0;
        font-size: 14px;
        font-weight: bold;
        word-wrap: break-word;
        display: block; }
      .add-booking-modal form .add-booking-field input, .add-booking-modal form .add-booking-field-full input {
        width: 90%; }
      .add-booking-modal form .add-booking-field .react-datepicker-wrapper, .add-booking-modal form .add-booking-field-full .react-datepicker-wrapper {
        width: 100%; }
      .add-booking-modal form .add-booking-field .select-container, .add-booking-modal form .add-booking-field-full .select-container {
        margin: 8px 0;
        width: 90%; }
        .add-booking-modal form .add-booking-field .select-container div [class*='control'], .add-booking-modal form .add-booking-field-full .select-container div [class*='control'] {
          border: 1px solid #e0e7ee;
          height: 50px; }
      .add-booking-modal form .add-booking-field-full, .add-booking-modal form .add-booking-field-full-full {
        width: 100%; }
    .add-booking-modal form div[data-radium] {
      margin-bottom: 2vh; }
    .add-booking-modal form .helper-text {
      position: relative;
      top: -1vh;
      color: transparent; }
      .add-booking-modal form .helper-text.show {
        color: inherit; }
    .add-booking-modal form .error-message {
      font-size: 16px;
      line-height: inherit;
      white-space: pre-wrap; }
  .add-booking-modal .modal-footer {
    margin-top: 0; }

.add-booking-modal-mobile {
  max-width: 100%;
  width: 100%;
  position: relative;
  overflow-x: hidden;
  overflow-y: auto; }
  .add-booking-modal-mobile .add-booking-modal-mobile-form .info-message {
    width: 95%;
    margin-bottom: 20px; }
  .add-booking-modal-mobile .add-booking-modal-mobile-form .add-booking-field, .add-booking-modal-mobile .add-booking-modal-mobile-form .add-booking-field-full {
    display: block;
    margin-bottom: 25px;
    width: 100%; }
    .add-booking-modal-mobile .add-booking-modal-mobile-form .add-booking-field label, .add-booking-modal-mobile .add-booking-modal-mobile-form .add-booking-field-full label {
      color: #7c9fb0;
      font-size: 14px;
      font-weight: bold;
      word-wrap: break-word;
      display: block; }
    .add-booking-modal-mobile .add-booking-modal-mobile-form .add-booking-field input, .add-booking-modal-mobile .add-booking-modal-mobile-form .add-booking-field-full input {
      width: 100%; }
    .add-booking-modal-mobile .add-booking-modal-mobile-form .add-booking-field .select-container, .add-booking-modal-mobile .add-booking-modal-mobile-form .add-booking-field-full .select-container {
      margin: 8px 0; }
      .add-booking-modal-mobile .add-booking-modal-mobile-form .add-booking-field .select-container div [class*='control'], .add-booking-modal-mobile .add-booking-modal-mobile-form .add-booking-field-full .select-container div [class*='control'] {
        border: 1px solid #e0e7ee;
        height: 50px; }
    .add-booking-modal-mobile .add-booking-modal-mobile-form .add-booking-field:last-of-type, .add-booking-modal-mobile .add-booking-modal-mobile-form .add-booking-field-full:last-of-type {
      margin-bottom: 45px; }
  .add-booking-modal-mobile .add-booking-modal-mobile-form div[data-radium] {
    margin-bottom: 2vh; }
  .add-booking-modal-mobile .add-booking-modal-mobile-form .error-message {
    font-size: 16px;
    line-height: inherit;
    white-space: pre-wrap; }

.add-booking-calendar-widget {
  height: 340px; }
  .add-booking-calendar-widget .add-booking-calendar {
    padding: 50px 0px 0px 0px; }
    .add-booking-calendar-widget .add-booking-calendar .rbc-toolbar {
      display: flex; }
    .add-booking-calendar-widget .add-booking-calendar .rbc-event {
      cursor: default; }
  .add-booking-calendar-widget .calendar-keys {
    border-top: none;
    height: 0 !important; }
  .add-booking-calendar-widget.mobile {
    margin-bottom: 20px; }

.chip {
  background-color: #3f417e;
  color: white;
  height: 4vh;
  line-height: 4vh;
  vertical-align: middle;
  border-radius: 30px;
  padding: 0 12px;
  margin: 5px 5px 5px 0;
  display: flex;
  justify-content: center;
  align-items: center; }
  .chip .chip-remove {
    margin-top: 0.25vh;
    margin-left: 5px; }
  .chip.invalid {
    border: 1px solid #ffa7a7;
    color: #ff0000;
    background-color: rgba(255, 167, 167, 0.15); }

.add-pass {
  right: 50px;
  top: -20px; }
  @media only screen and (max-width: 1023px) {
    .add-pass {
      right: 10px;
      top: -15px; } }

.passes-description-container, .passes-description-container-mobile {
  display: flex;
  flex-direction: column;
  align-content: flex-start;
  justify-content: flex-start;
  padding: 2vh 1vw 1vh 2vw;
  border-top: 1px solid #eeeeee;
  margin-top: 15px; }
  .passes-description-container .passes-description-header, .passes-description-container-mobile .passes-description-header {
    color: #80a2b2;
    font-size: 14px;
    line-height: 14px;
    font-weight: bold;
    margin-bottom: 1vh; }
  .passes-description-container .passes-description, .passes-description-container-mobile .passes-description {
    font-size: 16px;
    line-height: 22px;
    margin-bottom: 0;
    margin-right: 5px; }

.passes-description-container-mobile {
  padding: 15px 15px 15px 15px;
  border-top: none;
  border-bottom: 1px solid #eeeeee;
  width: 96%; }

.renew-pass-btn {
  min-width: 250px;
  max-height: 50px;
  margin: 20px 20px 20px auto; }

.renew-pass {
  align-self: flex-end; }

.renew-pass-notallowed {
  box-sizing: border-box;
  max-height: 29px;
  margin: 5% 5%;
  width: 90%;
  font-family: Foco;
  font-size: 16px;
  color: #ff0000;
  letter-spacing: 1px;
  line-height: 19px;
  text-align: center;
  border: 1px solid #ffa7a7;
  border-radius: 4px;
  background-color: rgba(255, 167, 167, 0.15); }

.renew-pass-notallowed-mobile {
  box-sizing: border-box;
  max-height: 60px;
  margin: 5% 5%;
  width: 90%;
  font-family: Foco;
  font-size: 16px;
  color: #ff0000;
  letter-spacing: 1px;
  line-height: 19px;
  text-align: center;
  border: 1px solid #ffa7a7;
  border-radius: 4px;
  background-color: rgba(255, 167, 167, 0.15); }

.renew-pass-mobile {
  align-self: center; }
  .renew-pass-mobile .renew-pass-btn {
    margin-right: 0; }

.widget-inner-title {
  color: #1e2171; }

.table {
  display: flex;
  flex-direction: column;
  height: 100%;
  flex: 1 1; }
  .table .table-header,
  .table .row,
  .table .title,
  .table .footer {
    display: flex; }
  .table .header {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    padding-top: 15px; }
    .table .header .header-filter {
      display: flex;
      padding: 0 40px; }
  .table .title {
    padding: 34px 0;
    color: #1e2171;
    font-size: 23px;
    letter-spacing: 0.43px;
    line-height: 26px;
    min-width: 25%;
    box-sizing: border-box; }
  .table .mobile-title {
    color: #1e2171;
    font-size: 19px;
    letter-spacing: 1.19px;
    line-height: 20px;
    text-align: center;
    padding: 32px;
    border-bottom: 1px solid #eeeeee; }
  .table .filter {
    width: 50%; }
  .table .description {
    width: 100%; }
  .table .mobile-filter {
    width: unset;
    padding: 18px 5px;
    border-bottom: 1px solid #eeeeee; }
  .table .table-header {
    background-color: #f9fbfc;
    padding: 18px 40px;
    border-top: 1px solid #eeeeee; }
  .table .row {
    padding: 15px 40px;
    border-bottom: 1px solid #eeeeee;
    -webkit-transition: background-color ease-in-out 0.3s;
    transition: background-color ease-in-out 0.3s;
    will-change: background-color;
    flex-wrap: wrap; }
    .table .row:hover {
      background-color: #f5f6fb; }
    .table .row.clickable:hover {
      cursor: pointer; }
  .table .mobile-row {
    padding: 15px 20px;
    flex-direction: row; }
  .table .no-results-message {
    color: #a0a0a0;
    text-align: center;
    font-size: 23px;
    margin: auto;
    padding: 20px; }
  .table .footer {
    margin-top: auto;
    margin-bottom: 38px;
    padding: 17px;
    color: #a0a0a0;
    font-size: 16px;
    letter-spacing: 0.26px;
    text-align: center;
    display: flex;
    justify-content: flex-start;
    align-items: center; }
    .table .footer span {
      margin-left: 33%;
      width: 33%;
      height: 1em; }
    .table .footer div {
      width: 33%;
      display: flex;
      justify-content: flex-end; }
  .table .column-header,
  .table .cell {
    flex: 1 1; }
  .table .column-header {
    color: #8c95a1;
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 0.88px;
    line-height: 15px; }
  .table .cell {
    color: #565656;
    font-size: 16px;
    letter-spacing: 2px;
    line-height: 18px;
    margin: auto; }
    .table .cell.bold {
      font-weight: bold; }
  .table .mobile-cell.cell {
    color: #565656;
    font-size: 12px;
    letter-spacing: 0.75px;
    line-height: 14px;
    margin-right: 5px; }
    .table .mobile-cell.cell.bold {
      margin-top: 10px;
      margin-right: 0;
      color: #184f6d;
      font-size: 16px;
      letter-spacing: 1px;
      line-height: 18px;
      font-weight: bold; }

.loader {
  text-align: center;
  padding: 30px; }

.button-group.passes-filter-buttons {
  height: 100%;
  justify-content: center; }
  .button-group.passes-filter-buttons .btn {
    max-width: 200px;
    min-height: 90px;
    border: 1px solid #e1e3e8;
    border-radius: 8px;
    background-color: rgba(255, 255, 255, 0.56);
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.17);
    font-size: 14px;
    margin-right: 15px;
    flex-basis: 200px;
    flex-grow: 1; }
    .button-group.passes-filter-buttons .btn .btn-alternate-text {
      margin-top: 1vh; }

.mobile-filter .button-group.passes-filter-buttons {
  justify-content: space-around; }
  .mobile-filter .button-group.passes-filter-buttons .btn {
    font-size: 13px;
    min-width: 28vw;
    max-width: 28vw;
    margin-right: 0; }

.add-pass-modal-header {
  margin: 50px auto; }

.add-pass-modal-footer {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  position: relative; }
  .add-pass-modal-footer .add-pass-modal-footer-alternate {
    margin-bottom: 2vh; }
  .add-pass-modal-footer p {
    margin-top: 40px;
    align-self: center; }

.add-pass-photo {
  width: calc(100% - 20%);
  position: relative;
  text-align: center;
  margin-left: 10%; }
  .add-pass-photo .upload-button {
    background-color: #dce7ec;
    color: rgba(28, 77, 107, 0.41);
    height: 50px;
    width: 50px;
    line-height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: flex-start;
    opacity: 0.6;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    border-radius: 50%;
    cursor: pointer; }
    .add-pass-photo .upload-button.hide-button {
      display: none; }
    .add-pass-photo .upload-button:hover {
      opacity: 1;
      color: #184f6d; }
  .add-pass-photo .crop-container {
    border-radius: 4px;
    max-width: 100%;
    max-height: 45vh;
    display: block; }
    .add-pass-photo .crop-container img {
      max-width: 100%;
      max-height: 45vh;
      width: auto;
      height: auto;
      margin-left: auto;
      margin-right: auto;
      display: block; }
  .add-pass-photo .action-area {
    margin: 8px 0;
    display: inline-flex; }
    .add-pass-photo .action-area .action-button {
      margin-left: 8px;
      background-color: #dce7ec;
      color: rgba(28, 77, 107, 0.41);
      height: 50px;
      width: 50px;
      line-height: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      align-self: flex-start;
      border-radius: 4px;
      cursor: pointer; }
      .add-pass-photo .action-area .action-button.remove-button {
        background-color: rgba(255, 167, 167, 0.15);
        border: 1px solid #ffa7a7; }
      .add-pass-photo .action-area .action-button.hide-button {
        display: none; }
  .add-pass-photo.no-photo {
    margin-top: 8px;
    margin-bottom: 8px;
    padding-top: 35.25%;
    border-radius: 4px;
    border: 2px solid #E0E7EE;
    background-color: white;
    cursor: pointer; }
    .add-pass-photo.no-photo:hover .upload-button {
      opacity: 1;
      color: #184f6d; }
    .add-pass-photo.no-photo img,
    .add-pass-photo.no-photo .action-area {
      display: none; }

.add-pass-photo-modal-footer {
  width: 100%;
  text-align: left; }
  .add-pass-photo-modal-footer .action-area {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    position: relative;
    min-height: 50px; }
  .add-pass-photo-modal-footer .submit-button {
    margin: 0;
    padding: 0 8px; }

.add-pass-photo-modal-header {
  text-align: left;
  width: 100%; }
  .add-pass-photo-modal-header h1 {
    text-align: center;
    margin: 20px auto; }

.add-pass-photo-modal {
  max-width: 2000px;
  overflow-y: auto;
  overflow-x: hidden; }
  .add-pass-photo-modal .error-message {
    width: calc(100% - 18px); }

.add-pass-modal {
  position: relative;
  overflow-y: auto;
  max-height: 650px; }
  .add-pass-modal button {
    flex: none;
    width: 170px;
    margin-top: 0; }
    @media only screen and (max-width: 1023px) {
      .add-pass-modal button {
        align-self: flex-end; } }
  .add-pass-modal .react-datepicker button {
    width: 10px; }
  .add-pass-modal .add-pass-modal-header {
    text-align: left; }
  .add-pass-modal .error-message {
    margin-bottom: 0;
    line-height: 36px;
    position: absolute;
    bottom: 250px; }
  .add-pass-modal.mobile-modal {
    overflow: auto; }
    .add-pass-modal.mobile-modal .add-pass-input {
      width: 100%; }
    .add-pass-modal.mobile-modal .error-message {
      position: static;
      line-height: 20px; }
    .add-pass-modal.mobile-modal .add-pass-modal-footer {
      min-height: 250px; }
    .add-pass-modal.mobile-modal .add-pass-field {
      flex-basis: 100% !important;
      margin-left: 0 !important;
      margin-right: 0 !important;
      padding: 10px 0 !important; }
    .add-pass-modal.mobile-modal .select-container {
      margin: 8px 0;
      border-left: 0;
      width: 100%; }
  .add-pass-modal .add-pass-row {
    display: flex;
    flex-wrap: wrap; }
  .add-pass-modal .add-pass-field {
    display: flex;
    flex-direction: column;
    padding: 10px;
    box-sizing: border-box; }
    .add-pass-modal .add-pass-field label {
      color: #7c9fb0;
      font-size: 14px;
      font-weight: bold;
      line-height: 14px; }
  .add-pass-modal .delete-pass-row {
    display: flex;
    flex: 1 1;
    justify-content: flex-end;
    align-items: flex-end;
    padding: 10px; }
    .add-pass-modal .delete-pass-row div {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 50px;
      width: 50px;
      max-width: 50px;
      margin: 8px 0;
      border: 1px solid #ffa7a7;
      border-radius: 4px;
      background-color: rgba(255, 167, 167, 0.15);
      cursor: pointer; }
      .add-pass-modal .delete-pass-row div svg {
        color: rgba(28, 77, 107, 0.2);
        font-size: 20px; }
  .add-pass-modal .add-pass-input {
    font-size: 24px;
    text-align: center;
    width: 400px;
    padding: 8px 0;
    box-sizing: content-box; }
    .add-pass-modal .add-pass-input:active, .add-pass-modal .add-pass-input:focus {
      border-width: 2px; }
  .add-pass-modal .select-container {
    margin: 8px 0;
    border-left: 0;
    width: 225px;
    height: 50px; }
    .add-pass-modal .select-container > div:first-of-type {
      height: 100%; }
      .add-pass-modal .select-container > div:first-of-type .pass-length__control {
        height: 100%; }
        .add-pass-modal .select-container > div:first-of-type .pass-length__control .pass-length__indicator-separator {
          background-color: #e0e7ee; }
  .add-pass-modal .add-pass-photo {
    width: calc(100% -2px);
    position: relative; }
    .add-pass-modal .add-pass-photo .upload-button {
      background-color: #dce7ec;
      color: rgba(28, 77, 107, 0.41);
      height: 50px;
      width: 50px;
      line-height: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      align-self: flex-start;
      opacity: 0.6;
      position: absolute;
      top: 50%;
      left: 50%;
      -webkit-transform: translate(-50%, -50%);
              transform: translate(-50%, -50%);
      border-radius: 50%;
      cursor: pointer; }
      .add-pass-modal .add-pass-photo .upload-button:hover {
        opacity: 1;
        color: #184f6d; }
      .add-pass-modal .add-pass-photo .upload-button.disabled:hover {
        cursor: auto;
        opacity: 0.6;
        color: rgba(28, 77, 107, 0.41); }
    .add-pass-modal .add-pass-photo img {
      width: 100%;
      margin: 8px 0;
      border-radius: 4px;
      border: 1px solid #E0E7EE; }
    .add-pass-modal .add-pass-photo.no-photo {
      margin: 8px 0;
      padding-top: 128%;
      border-radius: 4px;
      border: 1px solid #E0E7EE;
      background-color: white;
      cursor: pointer; }
      .add-pass-modal .add-pass-photo.no-photo img {
        display: none; }
  .add-pass-modal .upload-check-box input {
    accent-color: #1b4e6b;
    width: 20px;
    height: 20px; }
  .add-pass-modal .upload-check-box label {
    font-weight: normal; }
  .add-pass-modal .add-pass-modal-fields-seperator {
    border: 1px solid #eeeeee;
    margin-top: 2vh;
    margin-bottom: 2vh; }

.description-container {
  display: flex;
  flex-direction: column;
  flex: 1 1; }

.description-container-mobile {
  display: flex;
  flex-direction: column;
  text-align: center; }

.pass-status-cell, .pass-status-cell-mobile {
  display: flex;
  justify-content: flex-start; }
  .pass-status-cell svg.awaiting-approval, .pass-status-cell-mobile svg.awaiting-approval {
    color: inherit; }
  .pass-status-cell .pass-status-cell-value, .pass-status-cell-mobile .pass-status-cell-value {
    margin-left: 1vw;
    color: inherit;
    font-size: 16px; }

.pass-status-cell-mobile-container {
  margin: 0 !important;
  overflow: visible !important; }

.pass-status-cell-mobile {
  align-items: center; }
  .pass-status-cell-mobile .pass-status-cell-value {
    margin-left: 3vw;
    color: inherit;
    font-size: 16px; }

.awaiting-approval {
  color: #ffaf00; }

.expired {
  color: #eb3f3f; }

.approved {
  color: #2acaa2; }

.delete-btn {
  width: 26px;
  height: 26px;
  box-shadow: 0 1px 1px 0 rgba(37, 130, 237, 0.5);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #1b4e6b;
  opacity: 0.63;
  margin-left: auto;
  -webkit-transition: -webkit-transform 0.1s ease-in-out;
  transition: -webkit-transform 0.1s ease-in-out;
  transition: transform 0.1s ease-in-out;
  transition: transform 0.1s ease-in-out, -webkit-transform 0.1s ease-in-out; }
  .delete-btn:hover {
    cursor: pointer;
    -webkit-transform: scale(1.1);
            transform: scale(1.1); }
  @media only screen and (max-width: 1023px) {
    .delete-btn {
      margin-top: 5px; } }

.remove-pass-cell {
  width: 30px; }

.remove-pass-cell-header {
  flex-basis: 30px !important; }

.my-haulfryn-access-dropdown {
  display: flex;
  justify-content: flex-start; }
  .my-haulfryn-access-dropdown .select-container {
    width: 80%; }

.renew-checkbox {
  color: #565656;
  font-family: Foco;
  font-size: 16px;
  letter-spacing: 2px;
  line-height: 18px;
  display: flex;
  align-items: center;
  width: 30px;
  border-color: red; }

.renew-checkbox-header {
  flex-basis: 30px !important; }

.renew-checkbox input {
  box-sizing: border-box;
  height: 16px;
  width: 16px; }

.pass-cell {
  display: flex;
  padding-bottom: 2px;
  align-items: flex-end; }
  .pass-cell .pass-date {
    font-size: 12px;
    font-weight: bold;
    letter-spacing: 0.75px;
    color: #565656;
    line-height: 14px;
    width: 100%;
    margin-bottom: 1vh; }
  .pass-cell .pass-name {
    font-size: 16px;
    letter-spacing: 1px;
    color: #1b4e6b;
    line-height: 18px;
    margin: 0;
    width: 100%; }
  .pass-cell .renew-checkbox {
    box-sizing: border-box;
    height: 16px;
    width: 16px;
    margin: 0 10px 2px 2px; }

.member-table {
  width: 100%;
  padding: 20px;
  text-align: center; }
  .member-table table {
    width: 95%;
    border-color: #1e2171;
    border-width: 5px;
    border-style: solid; }
    .member-table table tr {
      margin-top: 50px; }
      .member-table table tr th {
        border-color: #1e2171;
        border-width: 1px;
        border-style: solid; }
      .member-table table tr td {
        border-color: #1e2171;
        border-width: 1px;
        border-style: solid; }

.memberships-spinner {
  text-align: center;
  margin: 10% 40%; }

.float-right {
  float: right;
  text-align: right; }

.description-inline .inline-p {
  display: inline-block;
  padding: 0px 40px; }

.filter-parent {
  text-align: right;
  display: inline-block;
  padding-left: 170px; }
  .filter-parent .filter-child {
    display: inline-block;
    padding: 0px 10px; }

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@-webkit-keyframes fadeOut {
  0% {
    opacity: 1; }
  100% {
    opacity: 0; } }

@keyframes fadeOut {
  0% {
    opacity: 1; }
  100% {
    opacity: 0; } }

@-webkit-keyframes moveDown {
  0% {
    max-height: 0px; }
  100% {
    max-height: 1000px; } }

@keyframes moveDown {
  0% {
    max-height: 0px; }
  100% {
    max-height: 1000px; } }

.gridDisplay {
  display: flex;
  flex-wrap: wrap;
  -webkit-animation: moveDown 1s;
          animation: moveDown 1s; }

@media screen and (max-width: 600px) {
  .sign-agreement-button {
    justify-content: center; }
  .container-box {
    width: 90%;
    border: 1px solid darkgray;
    padding: 10px;
    padding-bottom: 50px;
    -webkit-animation: fadeIn 0.5s;
            animation: fadeIn 0.5s;
    border-radius: 10px;
    margin-bottom: 10px;
    position: relative; }
    .container-box h4 {
      margin-top: 0px;
      margin-bottom: 5px;
      font-size: 16px; }
    .container-box p {
      font-size: 14px;
      margin-bottom: 2px;
      margin-left: 2%; }
  .container-title {
    text-align: center; }
  .dashboard-owner-memberships {
    justify-content: center; } }

@media screen and (min-width: 600px) {
  .sign-agreement-button {
    float: right; }
  .container-box {
    width: 500px;
    border: 1px solid darkgray;
    padding: 10px;
    padding-bottom: 30px;
    margin: 10px;
    -webkit-animation: fadeIn 0.5s;
            animation: fadeIn 0.5s;
    border-radius: 10px;
    position: relative; }
    .container-box h4 {
      margin-top: 0px;
      margin-bottom: 5px;
      font-size: 16px; }
    .container-box p {
      font-size: 14px;
      margin-bottom: 2px;
      margin-left: 2%; }
  .container-title {
    text-align: left; }
  .dashboard-owner-memberships {
    float: right; } }

.no-membership-text p {
  margin: 0px;
  margin-bottom: 10px;
  text-align: center;
  color: gray; }

.memberships-used-box {
  width: 240px;
  height: 20px;
  border: 1px solid darkgray;
  padding: 5px;
  margin: 10px;
  background-color: #b6d7a8;
  margin-right: 5%; }

@media screen and (min-width: 600px) {
  .used-box-display {
    float: right; } }

@media screen and (max-width: 600px) {
  .used-box-display {
    float: none;
    margin-left: 8%;
    margin-right: 8%; } }

.details-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  border-top: 1px solid #eeeeee;
  border-bottom: 1px solid #eeeeee;
  flex: 1 1;
  width: 100%; }
  .details-container .detail-row {
    width: calc(50% - 2em); }
  .details-container .detail-row:last-child {
    border-bottom: none; }

.details-container-mobile {
  display: flex;
  flex-direction: column;
  border-top: 1px solid #eeeeee;
  margin-right: 1em; }
  .details-container-mobile .detail-row {
    padding-left: 0; }

.detail-row {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  border-bottom: 1px solid #eeeeee;
  padding-left: 2em; }
  .detail-row span {
    min-height: 2em;
    width: 50%;
    display: inline-flex;
    align-items: center; }
  .detail-row span:first-child {
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 1px;
    line-height: 18px;
    padding: 1em 0; }
  .detail-row span:last-child {
    font-size: 16px;
    letter-spacing: 1px;
    line-height: 18px;
    padding: 1em 1em 1em 0; }

.important-info-container {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: 136px;
  width: 228px;
  border-radius: 8px;
  box-shadow: 0 1px 13px -2px rgba(95, 129, 226, 0.4);
  margin: 0 15px 15px 0;
  max-width: 228px;
  background-color: #fff; }
  .important-info-container:hover {
    cursor: pointer; }
  .important-info-container.sidebar {
    margin-right: 0;
    max-height: 160px; }

.important-info-container:last-child:after {
  margin: 0; }

.important-info-header {
  padding: 0 0 0 1.3em;
  color: #1e2171;
  font-size: 23px;
  letter-spacing: 0.43px;
  line-height: 26px; }

.important-info-icon {
  height: 50%;
  color: #1e2171;
  font-size: 51px;
  letter-spacing: 0.95px;
  line-height: 51px;
  text-align: center;
  flex: 1 1;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 85px; }

.important-info-title {
  padding: 5px 15px;
  height: 40px;
  font-family: Foco;
  font-size: 16px;
  font-weight: bold;
  border-top: 1px solid #eeeeee;
  letter-spacing: 0.47px;
  line-height: 17px;
  display: flex;
  align-items: center; }

.important-info-header-mobile {
  color: #184f6d;
  font-size: 18px;
  letter-spacing: 0.88px;
  line-height: 25px;
  margin: 25px 2em 25px 2.5em;
  text-align: center; }

.important-info-container-mobile {
  display: flex;
  flex-direction: column;
  flex: 1 1;
  height: 136px;
  width: 100%;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 1px 13px -2px rgba(95, 129, 226, 0.4);
  margin: 0 auto 35px auto; }
  .important-info-container-mobile:last-child {
    margin-bottom: 0; }

.important-info-title-mobile {
  padding: 5px 15px;
  height: 40px;
  font-family: Foco;
  font-size: 16px;
  font-weight: bold;
  border-top: 1px solid #eeeeee;
  letter-spacing: 0.47px;
  line-height: 17px;
  display: flex;
  align-items: center; }

.information-row {
  display: flex;
  flex-direction: row;
  margin-bottom: 1em;
  padding: 2em 2em 0 2em;
  flex-wrap: wrap; }

.information-row.details {
  padding: 2em 0; }

.park-home-title {
  color: #1e2171;
  font-size: 23px;
  letter-spacing: 0.43px;
  line-height: 26px; }

.park-home-sub-title {
  color: #80a2b2;
  font-size: 14px;
  font-weight: bold;
  margin-top: 1em; }

.park-home-description {
  font-size: 16px;
  line-height: 22px;
  margin-top: 1em;
  width: 90%; }

.park-home-title-mobile {
  color: #1e2171;
  font-size: 18px;
  letter-spacing: 0.88px;
  line-height: 25px;
  margin: 2em 2em 0.5em 2.5em; }

.park-home-sub-title-mobile {
  color: #80a2b2;
  font-size: 14px;
  font-weight: bold;
  margin-top: 1em;
  text-align: left;
  margin-left: 2.5em; }

.park-home-description-mobile {
  color: #184f6d;
  font-size: 16px;
  letter-spacing: 0.88px;
  line-height: 25px;
  margin: 0.5em 2em; }

.park-home-details-mobile {
  margin-top: 1em;
  margin-right: 0 !important; }

.park-home-lodge-details-key-mobile {
  color: #80a2b2 !important;
  font-size: 14px !important;
  letter-spacing: 0.88px !important;
  line-height: 25px !important;
  padding: 1em 0 1em 2.5em !important; }

.park-home-lodge-details-value-mobile {
  color: #1b4e6b !important;
  font-size: 14px !important;
  font-weight: bold;
  letter-spacing: 0.88px !important;
  line-height: 25px !important;
  padding: 1em 1em 1em 0 !important; }

.contact-row {
  display: flex;
  flex-direction: column;
  flex: 1 1; }

.park-home-contact-park {
  margin: 1em 0 1.5em 0;
  width: 87%;
  max-height: 3em;
  min-height: 3em; }

.park-home-contact-park-mobile {
  margin: 0.5em 2em 0 2.5em;
  min-height: 3em; }

.park-home-park-image-container {
  flex: 1 1; }
  .park-home-park-image-container img {
    width: 100%; }

.park-home-park-image-mobile {
  width: 100%; }

.park-home-main-title-mobile {
  border-bottom: 1px solid #eeeeee;
  margin: 0 0 0.5em 0;
  padding: 1em 0;
  font-weight: bold; }

.faq-title {
  color: #1e2171;
  font-size: 23px;
  letter-spacing: 0.43px;
  line-height: 26px;
  padding: 32px; }

.faq-accordion {
  border: none;
  margin-bottom: 8px;
  background-color: #ffffff; }
  .faq-accordion .accordion__item {
    border-top: 1px solid rgba(0, 0, 0, 0.1); }
    .faq-accordion .accordion__item .accordion__button {
      width: auto;
      background-color: #ffffff;
      vertical-align: middle;
      outline: none;
      font-size: 20px;
      display: flex;
      align-content: flex-start;
      align-items: center;
      padding-left: 32px;
      min-height: 50px;
      height: 10px; }
      .faq-accordion .accordion__item .accordion__button img {
        height: 15px; }
      .faq-accordion .accordion__item .accordion__button span {
        margin-left: 50px;
        line-height: 15px;
        color: #1e2171; }
      .faq-accordion .accordion__item .accordion__button:before {
        content: none; }
    .faq-accordion .accordion__item .accordion__panel {
      font-size: 15px;
      padding-top: 10px;
      padding-right: 55px;
      padding-bottom: 32px;
      padding-left: 98px;
      background-color: white; }
      .faq-accordion .accordion__item .accordion__panel p {
        color: #184f6d;
        font-size: 16px; }

.my-park-and-home-mobile .faq-title {
  text-align: center;
  font-size: 19px; }

.my-park-and-home-mobile .accordion__item .accordion__button {
  font-size: 16px; }
  .my-park-and-home-mobile .accordion__item .accordion__button span {
    margin-left: 10px; }

.my-park-and-home-mobile .accordion__item .accordion__panel {
  padding-left: 58px; }

.park-news-title-mobile {
  text-align: center; }

.park-news-title {
  color: #fff;
  font-size: 23px;
  letter-spacing: 0.4px;
  line-height: 26px; }

.my-bank-details-modal {
  width: 650px; }
  .my-bank-details-modal form {
    width: 100%; }

.bank-details-modal-group {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-bottom: 25px; }
  .bank-details-modal-group .bank-details-modal-field {
    display: flex;
    flex-direction: column;
    width: 50%; }
    .bank-details-modal-group .bank-details-modal-field label {
      color: #80a2b2;
      font-size: 14px;
      font-weight: bold;
      margin-bottom: 10px; }
    .bank-details-modal-group .bank-details-modal-field span {
      font-size: 20px; }

.my-bank-details-modal-mobile {
  max-width: 100%;
  width: 100%;
  position: relative; }
  .my-bank-details-modal-mobile .bank-details-modal-group {
    flex-direction: row; }
  .my-bank-details-modal-mobile .bank-details-modal-field {
    margin-bottom: 25px; }

.view-bank-details-box {
  padding: 12px 0;
  margin-bottom: 12px;
  color: #a0a0a0;
  text-align: center;
  font-family: Foco;
  font-size: 14px;
  text-transform: uppercase;
  border-bottom: 1px solid #eeeeee;
  border-top: 1px solid #eeeeee;
  cursor: pointer; }

.my-account-table > .widget-contents {
  overflow: visible; }

.owner-account-number {
  text-align: center;
  padding-top: 12px;
  font-size: 23px;
  line-height: 26px;
  letter-spacing: 0.43px;
  color: #1e2171; }

.payment-result {
  text-align: center;
  width: 350px; }
  .payment-result p {
    color: #1e2171;
    font-size: 20px;
    padding: 10px; }
  .payment-result button {
    width: 100%; }
  .payment-result button.mobile {
    width: 80%; }

.my-account-table-margin {
  margin-bottom: 20px; }

.transaction-balance-widget {
  text-align: center;
  padding-top: 0;
  padding-left: 20px;
  padding-right: 20px; }
  .transaction-balance-widget .title {
    margin-top: 20px;
    font-size: 23px;
    line-height: 26px;
    letter-spacing: 0.43px;
    color: #1e2171; }
  .transaction-balance-widget .balance {
    margin-top: 10px;
    margin-bottom: 20px;
    font-size: 32px;
    line-height: 36px;
    letter-spacing: 0.6px;
    color: #1e2171; }

.transaction-balance-widget > .btn-tertiary {
  margin-top: 20px;
  margin-left: -20px;
  margin-right: -20px; }

.payment-modal {
  width: 650px;
  align-items: flex-start;
  overflow: auto; }
  @media only screen and (max-width: 1023px) {
    .payment-modal {
      width: 100%;
      position: relative; } }
  .payment-modal .payment-details-section {
    width: 100%; }
    .payment-modal .payment-details-section .description {
      font-size: 16px;
      line-height: 22px;
      color: #445870; }
    .payment-modal .payment-details-section .payment-details {
      display: flex;
      flex-wrap: wrap; }
      .payment-modal .payment-details-section .payment-details > div {
        flex-basis: 50%; }
      @media only screen and (max-width: 1023px) {
        .payment-modal .payment-details-section .payment-details > div {
          margin-bottom: 25px;
          flex-basis: 100%; } }
      .payment-modal .payment-details-section .payment-details .title {
        font-weight: bold;
        font-size: 14px;
        line-height: 14px;
        color: #80a2b2;
        margin-bottom: 10px; }
      .payment-modal .payment-details-section .payment-details label.title {
        width: 100%;
        text-align: center; }
      .payment-modal .payment-details-section .payment-details .value {
        font-size: 20px;
        line-height: 18px;
        margin-bottom: 25px; }
      .payment-modal .payment-details-section .payment-details a.value {
        color: #008bff; }
    .payment-modal .payment-details-section .payment-details.card {
      justify-content: center; }
      .payment-modal .payment-details-section .payment-details.card input {
        width: 50%;
        text-align: center;
        padding-right: 3%; }
      .payment-modal .payment-details-section .payment-details.card input[type=number]::-webkit-inner-spin-button,
      .payment-modal .payment-details-section .payment-details.card input[type=number]::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0; }
      .payment-modal .payment-details-section .payment-details.card input[type=number] {
        -moz-appearance: textfield; }
    .payment-modal .payment-details-section .payment-details.card-address {
      height: 72vh;
      display: block;
      flex-wrap: unset; }
      @media only screen and (max-width: 1023px) {
        .payment-modal .payment-details-section .payment-details.card-address {
          height: auto; } }
      .payment-modal .payment-details-section .payment-details.card-address .payment-details-card-address-field {
        display: inline-block;
        width: 50%;
        margin-top: 15px;
        margin-bottom: 15px;
        vertical-align: top; }
        @media only screen and (max-width: 1023px) {
          .payment-modal .payment-details-section .payment-details.card-address .payment-details-card-address-field {
            width: 100%; } }
        .payment-modal .payment-details-section .payment-details.card-address .payment-details-card-address-field label {
          color: #7c9fb0;
          font-size: 14px;
          font-weight: bold;
          word-wrap: break-word;
          display: block; }
        .payment-modal .payment-details-section .payment-details.card-address .payment-details-card-address-field label.error {
          color: red; }
        .payment-modal .payment-details-section .payment-details.card-address .payment-details-card-address-field input[type=text] {
          width: 90%; }
        .payment-modal .payment-details-section .payment-details.card-address .payment-details-card-address-field .select-container {
          width: 90%;
          margin-top: 8px;
          margin-bottom: 8px; }
          .payment-modal .payment-details-section .payment-details.card-address .payment-details-card-address-field .select-container > div:first-of-type > div:first-of-type {
            height: 50px;
            width: 100%; }
      .payment-modal .payment-details-section .payment-details.card-address .payment-details-card-address-field.country {
        width: 106%; }

.filter-container {
  padding: 23px 40px;
  border-top: 1px solid #eeeeee;
  display: flex;
  flex-wrap: wrap; }
  @media only screen and (max-width: 1023px) {
    .filter-container {
      border: none;
      padding: 0 20px; } }
  .filter-container .filter-date {
    display: flex;
    flex-direction: column;
    margin-right: 10px;
    max-width: 160px;
    width: 100%; }
    .filter-container .filter-date label {
      color: #7c9fb0;
      font-weight: bold;
      line-height: 14px;
      font-size: 14px; }
    @media only screen and (max-width: 767px) {
      .filter-container .filter-date {
        flex-direction: column;
        margin-right: 0;
        flex: 1 1;
        max-width: 100%; } }
    @media only screen and (max-width: 1023px) {
      .filter-container .filter-date input {
        width: 100%; } }
  .filter-container .search-button {
    min-width: 90px;
    padding-top: 14px;
    padding-right: 10px; }
    @media only screen and (max-width: 767px) {
      .filter-container .search-button {
        width: 100%;
        padding-right: 0; } }
    .filter-container .search-button button {
      border: 1px solid #e0e7ee; }
  .filter-container .filter-type {
    display: flex;
    flex-direction: column;
    margin-right: 10px;
    margin-left: 15px; }
    .filter-container .filter-type label {
      color: #7c9fb0;
      font-weight: bold;
      line-height: 14px;
      font-size: 14px; }
    @media only screen and (max-width: 767px) {
      .filter-container .filter-type {
        flex-direction: column;
        margin-right: 0;
        flex: 1 1;
        margin-left: 0px;
        margin-top: 20px; } }
  .filter-container .dropdown__control {
    min-height: 50px;
    width: 240px;
    height: 100%;
    margin-top: 8px;
    border: 1px solid #e0e7ee;
    -webkit-transition: color ease-in-out 0.3s, border-color ease-in-out 0.3s, box-shadow ease-in-out 0.3s;
    transition: color ease-in-out 0.3s, border-color ease-in-out 0.3s, box-shadow ease-in-out 0.3s; }
    @media only screen and (max-width: 1023px) {
      .filter-container .dropdown__control {
        width: 210px; } }
    @media only screen and (max-width: 767px) {
      .filter-container .dropdown__control {
        width: 100%;
        margin-right: 0;
        flex: 1 1; } }
  .filter-container .dropdown__value-container {
    min-height: 50px; }
  .filter-container .date-filter {
    width: 100%; }

.download-invoice {
  color: #565656; }
  .download-invoice :hover {
    cursor: pointer; }

.type-container {
  display: flex;
  align-items: center; }
  .type-container .description {
    margin-left: 10px; }

.mobile-cell.cell {
  overflow: hidden; }
  .mobile-cell.cell .transaction-item {
    border-bottom: none;
    padding: 0;
    height: 51px; }

.request-and-queries-ticket {
  text-align: center;
  padding-top: 12px;
  font-size: 23px;
  line-height: 26px;
  letter-spacing: 0.43px;
  color: #1e2171; }

.request-and-queries-btn {
  max-height: 3em;
  min-height: 3em;
  margin: 0 1.5em 0; }

.request-and-queries-btn-mobile {
  min-height: 3em; }

.request-and-queries-details {
  text-align: center;
  padding-top: 12px;
  font-size: 20px;
  line-height: 26px;
  letter-spacing: 0.43px;
  color: #184f6d;
  margin-bottom: 0; }

.request-and-queries-request-details {
  text-align: center;
  font-size: 20px;
  line-height: 26px;
  letter-spacing: 0.43px;
  color: #184f6d; }

.conversations-container {
  background-color: #f1f2f5 !important; }
  .conversations-container .ticket-info {
    border-spacing: 0px;
    border-radius: 8px;
    overflow: hidden; }
    .conversations-container .ticket-info tr {
      height: 50px; }
      .conversations-container .ticket-info tr th,
      .conversations-container .ticket-info tr td {
        font-size: 14px;
        letter-spacing: 0.88px;
        line-height: 15px;
        text-align: center; }
      .conversations-container .ticket-info tr td {
        background-color: #dce7ec; }
      .conversations-container .ticket-info tr.header-row {
        background-color: #f9fbfc; }
        .conversations-container .ticket-info tr.header-row th {
          color: #8c95a1;
          font-weight: bold; }
  .conversations-container .conversations {
    padding: 5px;
    padding-bottom: 80px; }
    .conversations-container .conversations .quick-reply-button {
      margin: 15px;
      margin-bottom: 0;
      padding: 1px 10px; }
    .conversations-container .conversations .conversation {
      background-color: white;
      margin: 15px;
      padding: 10px 20px;
      padding-bottom: 20px;
      border-radius: 8px;
      position: relative; }
      .conversations-container .conversations .conversation.private {
        background-color: #ffe5e5;
        border: 1px solid #ff5252; }
      .conversations-container .conversations .conversation h3 {
        color: #3f417e;
        margin: 10px 0px;
        margin-bottom: 0; }
      .conversations-container .conversations .conversation .strapline {
        font-size: 15px;
        color: #565656;
        margin-bottom: 10px; }
      .conversations-container .conversations .conversation .content {
        color: black;
        margin-bottom: 10px; }
      .conversations-container .conversations .conversation time {
        color: #707a8e; }
      .conversations-container .conversations .conversation .time-desk {
        position: absolute;
        right: 20px;
        top: 20px; }
      .conversations-container .conversations .conversation .time-mob {
        display: flex;
        flex-direction: column; }
        .conversations-container .conversations .conversation .time-mob time {
          align-self: flex-end; }
      .conversations-container .conversations .conversation .attachment-container {
        display: flex; }
        .conversations-container .conversations .conversation .attachment-container .selectable-chip {
          font-size: 15px;
          height: 30px; }

.requests-and-queries .back-button {
  bottom: 20px;
  left: 50%;
  position: fixed; }

.requests-and-queries.layout-mobile .widget-layout-message {
  grid-row-start: 3; }

.requests-and-queries.layout-mobile .widget-layout-title {
  margin-top: 0;
  grid-column-end: span 12; }

.quick-reply-modal {
  width: 650px; }
  .quick-reply-modal form {
    width: 100%; }

.quick-reply-modal-group {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-bottom: 25px; }
  .quick-reply-modal-group .quick-reply-modal-field {
    display: flex;
    flex-direction: column;
    width: 50%; }
    .quick-reply-modal-group .quick-reply-modal-field label {
      color: #80a2b2;
      font-size: 14px;
      font-weight: bold;
      margin-bottom: 10px; }
    .quick-reply-modal-group .quick-reply-modal-field span {
      font-size: 20px; }

.quick-reply-modal-mobile {
  max-width: 100%;
  width: 100%;
  position: relative; }
  .quick-reply-modal-mobile form footer button {
    margin-left: 5%; }
  .quick-reply-modal-mobile .quick-reply-modal-group {
    flex-direction: row; }
  .quick-reply-modal-mobile .quick-reply-modal-field {
    margin-bottom: 25px; }

.message-body {
  width: 100% !important; }
  .message-body textarea {
    min-height: 180px;
    line-height: 20px; }

.layout-container {
  max-width: 1244px;
  height: calc(100% - 7em);
  margin: auto;
  position: relative; }
  .layout-container .layout-container-children {
    display: flex;
    height: 100%; }

.layout-container-large {
  width: 66%; }

.layout-container-small {
  width: 33%; }

.confirm-page-vertical-background {
  background: #3f417e;
  position: relative;
  overflow: hidden; }
  .confirm-page-vertical-background img {
    align-self: flex-start; }

.confirm-page-container {
  display: flex;
  height: 100%;
  background-color: #fff; }
  .confirm-page-container .vertical-background-container {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-direction: column;
    color: #fff; }
    .confirm-page-container .vertical-background-container .vertical-background-children {
      margin-bottom: 40%;
      min-width: 385px;
      align-self: flex-start;
      margin-left: 15%;
      width: 400px; }
      .confirm-page-container .vertical-background-container .vertical-background-children h1 {
        font-size: 69px;
        letter-spacing: 1.29px;
        line-height: 79px; }
      .confirm-page-container .vertical-background-container .vertical-background-children p {
        font-size: 23px;
        letter-spacing: 1.44px;
        line-height: 30px;
        color: #fff; }

.confirm-page-message-container {
  flex: 50% 1; }
  .confirm-page-message-container div {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 3vw; }
    .confirm-page-message-container div h1 {
      color: #1e2171;
      font-size: 23px; }
    .confirm-page-message-container div h1.request-failed {
      color: red; }
    .confirm-page-message-container div h3 {
      color: #1e2171;
      font-size: 16px;
      margin-top: 0;
      width: 100%; }
    .confirm-page-message-container div h3.request-failed {
      color: red; }

.waiting:after {
  overflow: hidden;
  display: inline-block;
  vertical-align: bottom;
  -webkit-animation: ellipsis steps(4, end) 900ms infinite;
  animation: ellipsis steps(4, end) 900ms infinite;
  content: '\2026';
  /* ascii code for the ellipsis character */
  width: 0px; }

@keyframes ellipsis {
  to {
    width: 20px; } }

@-webkit-keyframes ellipsis {
  to {
    width: 20px; } }

.request-link {
  flex: none;
  width: 20vw;
  height: 5vh;
  line-height: 5vh;
  margin-left: 0; }

.confirm-mobile-background {
  background: #3f417e;
  display: flex;
  align-items: center;
  flex-direction: column;
  color: #fff;
  overflow: auto; }
  .confirm-mobile-background img.image-background {
    width: unset;
    left: 0;
    bottom: 0; }

.confirm-page-message-mobile-container div {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; }
  .confirm-page-message-mobile-container div h1 {
    color: #ffffff;
    font-size: 23px; }
  .confirm-page-message-mobile-container div h1.request-failed {
    color: #ffffff; }
  .confirm-page-message-mobile-container div h3 {
    color: #ffffff;
    font-size: 16px;
    margin-top: 0; }
  .confirm-page-message-mobile-container div h3.request-failed {
    color: #ffffff; }

.request-link-mobile {
  flex: none;
  height: 7vh;
  width: 50vw;
  line-height: 7vh; }

.welcome-background {
  background: #3f417e;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-direction: column;
  color: #fff;
  overflow: hidden; }
  .welcome-background img.image-background {
    width: unset;
    left: 0;
    bottom: 0; }
  .welcome-background h1 {
    font-size: 32px;
    width: 200px;
    letter-spacing: 0.6px;
    line-height: 36px; }
  .welcome-background p {
    width: 250px;
    letter-spacing: 1px;
    line-height: 19px;
    font-size: 16px; }
  .welcome-background .btn {
    margin-top: 10%;
    margin-bottom: 15%; }

.login-background {
  background: #3f417e;
  display: flex;
  align-items: center;
  flex-direction: column;
  color: #fff;
  overflow: auto; }
  .login-background img.image-background {
    width: unset;
    left: 0;
    bottom: 0; }
  .login-background .btn {
    margin-top: 10%;
    margin-bottom: 5%; }
  .login-background .forgot-pw-mobile form {
    margin-top: 150px; }

.forgot-password-page-vertical-background {
  background: #3f417e;
  position: relative;
  overflow: hidden; }
  .forgot-password-page-vertical-background img {
    align-self: flex-start; }

.forgot-password-page-container {
  display: flex;
  height: 100%;
  background-color: #fff; }
  .forgot-password-page-container .forgot-password-container {
    flex: 50% 1; }
    .forgot-password-page-container .forgot-password-container h1 {
      color: #1e2171;
      font-size: 23px; }
    .forgot-password-page-container .forgot-password-container h1.request-failed {
      color: red; }
    .forgot-password-page-container .forgot-password-container h3 {
      font-weight: normal;
      color: #1e2171;
      font-size: 16px;
      margin-top: 0;
      margin-bottom: 1em;
      width: 100%; }
    .forgot-password-page-container .forgot-password-container .error {
      color: red;
      font-size: 18px;
      margin-top: 0;
      width: 100%; }
    .forgot-password-page-container .forgot-password-container h3.request-failed {
      color: red; }
    .forgot-password-page-container .forgot-password-container .login-back {
      margin: 5vh 5vw 0 auto; }
    .forgot-password-page-container .forgot-password-container form {
      margin-top: 15vh;
      margin-left: 5vw;
      margin-right: 5vw; }
    .forgot-password-page-container .forgot-password-container .button-container:last-child {
      flex: 1 1;
      justify-content: flex-end;
      align-items: flex-end; }
      .forgot-password-page-container .forgot-password-container .button-container:last-child .btn-secondary {
        border: 1px solid #cdcdcd;
        width: 175px;
        flex: none; }
    .forgot-password-page-container .forgot-password-container .requirements-container {
      margin-bottom: 20px; }
  .forgot-password-page-container .vertical-background-container {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-direction: column;
    color: #fff; }
    .forgot-password-page-container .vertical-background-container .vertical-background-children {
      margin-bottom: 40%;
      min-width: 385px;
      align-self: flex-start;
      margin-left: 15%;
      width: 400px; }
      .forgot-password-page-container .vertical-background-container .vertical-background-children h1 {
        font-size: 69px;
        letter-spacing: 1.29px;
        line-height: 79px; }
      .forgot-password-page-container .vertical-background-container .vertical-background-children p {
        font-size: 23px;
        letter-spacing: 1.44px;
        line-height: 30px; }
  .forgot-password-page-container .link-right {
    color: #184f6d;
    font-size: 14px;
    line-height: 19px;
    letter-spacing: 0.88px; }
  .forgot-password-page-container .heading-right {
    color: #184f6d;
    font-size: 14px;
    line-height: 19px;
    letter-spacing: 0.88px;
    margin-top: 10px; }

.forgotten-password-link {
  text-align: right;
  margin-bottom: 5px; }

.resendEmail {
  color: #184f6d; }
  @media only screen and (max-width: 1023px) {
    .resendEmail {
      color: #fff; } }
  .resendEmail a {
    color: #004bff;
    cursor: pointer; }

.important-info-body {
  padding: 38px 38px 0 38px; }
  .important-info-body > .title {
    color: #1e2171;
    margin-top: 8px;
    margin-bottom: 38px; }
  .important-info-body p {
    margin-bottom: 16px; }
  @media only screen and (max-width: 767px) {
    .important-info-body {
      padding: 20px; }
      .important-info-body p {
        font-size: 15px;
        line-height: 24px;
        letter-spacing: 0.94px; } }

.info-back-button {
  padding: 38px; }
  @media only screen and (max-width: 767px) {
    .info-back-button {
      padding: 20px; } }

.park-news-body {
  padding: 38px 38px 0 38px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start; }
  .park-news-body img {
    height: auto;
    width: 100%; }
  .park-news-body > .title {
    color: #1e2171;
    margin-top: 8px;
    margin-bottom: 38px; }
  .park-news-body p {
    margin-bottom: 16px; }
  .park-news-body .title:first-of-type {
    color: #1e2171;
    font-family: Foco;
    font-size: 27px;
    font-weight: bold;
    letter-spacing: 0.5px;
    line-height: 31px; }
  .park-news-body .park-news-date {
    color: #80a2b2;
    font-family: Foco;
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 0.41px;
    line-height: 25px;
    margin-top: 1em; }
  .park-news-body .park-news-content-wrapper {
    display: flex;
    flex-direction: column;
    flex: 1 1;
    margin-left: 1em; }
  .park-news-body .park-news-content {
    font-family: Foco;
    font-size: 16px;
    line-height: 22px;
    margin-top: 1em; }
  .park-news-body .park-news-img {
    flex: 1 1; }
  @media only screen and (max-width: 767px) {
    .park-news-body {
      flex-direction: column;
      padding: 20px 20px 0 20px; }
      .park-news-body img {
        max-width: 100%; }
      .park-news-body p,
      .park-news-body ul,
      .park-news-body ol {
        color: #184f6d; }
      .park-news-body p {
        font-size: 15px;
        line-height: 24px;
        letter-spacing: 0.94px; }
      .park-news-body .park-news-content-wrapper {
        margin-left: 0; } }

.park-spinner-container {
  display: flex;
  justify-content: center; }

.news-back-button {
  padding: 38px; }
  @media only screen and (max-width: 767px) {
    .news-back-button {
      padding: 20px; } }

.register-popup-container {
  flex: 50% 1;
  overflow-y: auto; }
  .register-popup-container .login-back {
    margin: 5vh 5vw 0 auto; }
  .register-popup-container .external-login-container.register {
    margin-top: 5vh; }
  .register-popup-container .external-login-container.register.mobile {
    margin-top: 0; }
  .register-popup-container .register-explanation {
    margin-top: 5vh;
    margin-left: 10vw;
    margin-right: 10vw;
    text-align: center; }
  .register-popup-container .register-explanation.mobile {
    color: #ffffff;
    margin: 1vh 0 3vh 0; }
  .register-popup-container .register-login-error {
    margin-top: 25px; }
  .register-popup-container form {
    margin-left: 5vw;
    margin-right: 5vw;
    flex: 1 1;
    display: flex;
    flex-direction: column;
    justify-content: center; }
    @media only screen and (max-width: 1023px) {
      .register-popup-container form {
        margin: 0; } }
  .register-popup-container .button-container:last-child {
    margin-right: 0;
    justify-content: flex-end;
    align-items: flex-end; }
  @media only screen and (max-width: 1023px) {
    .register-popup-container .register-button {
      margin-top: 0 !important;
      margin-bottom: 20px; }
      .register-popup-container .register-button button {
        margin-top: 0;
        margin-bottom: 0; } }
  .register-popup-container .register-step-title {
    font-size: 23px;
    letter-spacing: 0.4px;
    color: #1c4d6b;
    line-height: 26px; }
    .register-popup-container .register-step-title.complete {
      margin-bottom: 20px; }
    @media only screen and (max-width: 1023px) {
      .register-popup-container .register-step-title {
        color: #fff;
        text-align: center; } }
  .register-popup-container .registration-complete {
    margin-left: 5vw;
    margin-right: 5vw;
    flex: 1 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    margin-bottom: 40%; }
    @media only screen and (max-width: 1023px) {
      .register-popup-container .registration-complete {
        text-align: center;
        margin-top: 20%;
        margin-left: 0;
        margin-right: 0; }
        .register-popup-container .registration-complete > p {
          color: #fff !important; } }
    .register-popup-container .registration-complete .register-complete-text {
      color: #445870;
      font-size: 16px;
      line-height: 22px;
      margin: 0; }
    .register-popup-container .registration-complete .register-complete-thank {
      margin-bottom: 30px; }
    .register-popup-container .registration-complete .register-complete-resend {
      color: #184f6d;
      font-size: 16px;
      line-height: 19px;
      margin-top: 40px;
      margin-bottom: 0;
      letter-spacing: 1px; }

.register-error {
  margin-top: 0;
  margin-bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center; }

.add-form-input {
  height: 50px;
  width: 90%; }

.center-input {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px; }

.center-content {
  display: flex;
  justify-content: center;
  align-items: center; }

.select-text {
  font-size: 18px;
  text-align: center;
  margin-left: 5px; }

.row-text {
  display: inline-flex; }

.membership-grid {
  display: grid;
  grid-column-gap: 10px;
  -webkit-column-gap: 10px;
     -moz-column-gap: 10px;
          column-gap: 10px; }

@media screen and (max-width: 600px) {
  .parQ-form .widget {
    padding: 0px; }
    .parQ-form .widget .widget-contents {
      position: relative; }
  .parQ-form .parq-column {
    margin-bottom: 5%; }
    .parQ-form .parq-column textarea {
      width: 90%;
      margin-left: 2%; } }

@media screen and (min-width: 600px) {
  .parQ-form .parq-column {
    float: left;
    width: 45%;
    padding: 0px 2.5%; }
    .parQ-form .parq-column textarea {
      width: 90%; } }

.column-md3 {
  float: left;
  width: 27%;
  padding: 1.5% 2.5%;
  border-radius: 8px;
  margin-top: 0.9%;
  margin-left: 0.9%;
  font-size: medium; }
  .column-md3 h1 {
    color: #1e2171;
    text-align: center;
    margin-top: 0px; }
  .column-md3 ul:li {
    text-align: left; }
  .column-md3 h3 {
    color: #1e2171; }

.contentful-text-membership-type {
  padding: 0px 15px; }
  .contentful-text-membership-type h1 {
    color: #1e2171;
    text-align: center;
    margin-top: 0px; }
  .contentful-text-membership-type ul:li {
    text-align: left; }
  .contentful-text-membership-type h3 {
    color: #1e2171; }
  .contentful-text-membership-type p {
    margin: 0;
    font-size: medium; }

.column-mobile {
  background-color: #ecf2f9;
  border-radius: 8px;
  padding: 1.5% 4.5%; }

.column-md3-type {
  float: left;
  padding: 1.5% 1.5% 5% 2.5%;
  background-color: #ecf2f9;
  font-size: medium;
  padding-top: 20px; }
  .column-md3-type h1 {
    color: #1e2171;
    text-align: center;
    margin-top: 0px; }
  .column-md3-type ul:li {
    text-align: left; }
  .column-md3-type h3 {
    color: #1e2171; }

.column-md1 {
  float: left;
  width: 100%;
  padding: 0px 2.5%; }
  .column-md1 h1 {
    color: #1e2171;
    text-align: center;
    margin-top: 0px; }
  .column-md1 h3 {
    color: #1e2171; }

.content-column {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px; }

.button-column {
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px; }

.mobile-table {
  table-layout: fixed;
  width: 100%; }

.mobile-table tr td {
  padding: 8px;
  word-wrap: break-word; }

@media screen and (min-width: 1000px) {
  .desktopVersion {
    display: block; }
  .mobileVersion {
    display: none; } }

.title-header {
  text-align: center; }

@media screen and (max-width: 1000px) {
  .desktopVersion {
    display: none; }
  .mobileVersion {
    display: block; } }

.summary-image {
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  border-radius: 8px; }

td {
  text-align: left; }

/* Clear floats after the columns */
.row:after {
  clear: both; }

.input {
  margin-top: 15px; }

.pad-preferences {
  margin: 10px; }

.float-left {
  float: left; }

.float-right {
  float: right; }

.vehicle-table {
  width: 100%; }

.vehicle-table th {
  text-align: left;
  font-size: 18px; }

.summary-table {
  width: 100%;
  text-align: center; }

.vehicle-table tbody > tr > td {
  border: none;
  padding: 0px; }

.summary-table tbody > tr > td {
  border: none; }

.summary-table th {
  font-size: 18px; }

.summary-table td {
  padding: 20px 20px; }

.add-membership-button {
  width: 150px; }

.autocomplete-input {
  padding: 0px;
  height: 48px;
  width: 90%; }
  .autocomplete-input .css-yk16xz-control {
    height: 48px; }
  .autocomplete-input .css-1pahdxg-control {
    height: 48px; }
  .autocomplete-input .css-1hwfws3 {
    height: 48px; }
  .autocomplete-input .css-1g6gooi {
    height: 48px;
    line-height: 28px; }

@media screen and (min-width: 600px) {
  .payment-data {
    width: 90%;
    margin-left: 5%;
    margin-right: 5%; } }

.grid-container-payment {
  display: grid;
  justify-content: center;
  grid-template-columns: 100%;
  grid-row-gap: 50px;
  row-gap: 50px; }

details {
  border: 1px solid #184f6d;
  border-radius: 4px;
  padding: 0.5em 0.5em 0;
  margin-bottom: 15px; }
  details ul {
    -webkit-padding-start: 10%;
            padding-inline-start: 10%; }

summary {
  margin: -0.5em -0.5em 0;
  padding: 0.5em;
  background-color: #184f6d;
  color: white;
  padding-left: 4%;
  list-style: none;
  font-size: 18px;
  border-radius: 4px; }
  summary p {
    color: white;
    margin-bottom: 0px; }

details[open] summary .details-chevron {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg); }

details[open] {
  padding: 0.5em; }

details[open] summary {
  border-bottom: 1px solid #184f6d;
  margin-bottom: 0.5em; }

.payment-total {
  border-radius: 4px;
  text-align: right;
  padding-right: 20px; }

.member-pay-details {
  float: left;
  margin: 0;
  width: 80%;
  padding: 5px;
  display: block; }

.member-name {
  font-weight: bold;
  color: black; }

.member-breakdown-payment {
  color: grey;
  margin-top: -10px;
  margin-left: 5px; }

.member-total-payment {
  font-weight: bold;
  color: black; }

.membership-review-details-header {
  padding-bottom: 5px; }

.membership-review-personal-details {
  padding-bottom: 20px; }

@media screen and (min-width: 1000px) {
  .payment-breakdown {
    display: flex; } }

.next-payment-date {
  font-size: 15px;
  text-align: right;
  margin-bottom: 5px; }

.member-breakdown-container {
  margin: 5px;
  max-width: 600px;
  padding: 5px; }
  .member-breakdown-container h4 {
    font-size: 15px;
    margin: 0px;
    margin-bottom: 5px; }
  .member-breakdown-container p {
    margin: 0px;
    margin-right: 10px;
    font-size: 13px;
    color: gray; }
  .member-breakdown-container .fee {
    margin-left: 20px; }

.temp-text {
  color: #1e2171;
  font-weight: 800; }

.text-grey {
  color: gray; }

.breadcrumb {
  margin: 1px;
  padding: 1px 1px;
  text-align: center; }
  .breadcrumb .breadcrumb-child {
    display: inline-block;
    padding: 5px 5px;
    vertical-align: middle;
    font-size: 16px; }

.form {
  margin: 20px 20px 1px;
  padding: 1px 1px; }

.save-cnt-btn {
  float: right; }

.exit-btn {
  float: left; }

.membership-btn {
  width: 200px; }

.selected-button {
  background-color: #5EBC5E !important;
  color: white !important; }

.back-button {
  padding: 10px;
  background-color: transparent;
  box-shadow: none; }

.addM-column {
  float: left;
  width: 50%; }

/* Clear floats after the columns */
.row:after {
  content: "";
  display: table;
  clear: both; }

@media screen and (max-width: 600px) {
  .breadcrumb {
    display: none; }
  .form-container {
    padding: 0px 20px 30px; } }

@media screen and (min-width: 600px) {
  .form-container {
    padding: 0px 50px 20px 30px; } }

.simple-image-cropper {
  width: calc(100% - 20%);
  position: relative;
  text-align: center;
  margin-left: 10%; }
  .simple-image-cropper .upload-button {
    background-color: #dce7ec;
    color: rgba(28, 77, 107, 0.41);
    height: 50px;
    width: 50px;
    line-height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: flex-start;
    opacity: 0.6;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    border-radius: 50%;
    cursor: pointer; }
    .simple-image-cropper .upload-button.hide-button {
      display: none; }
    .simple-image-cropper .upload-button:hover {
      opacity: 1;
      color: #184f6d; }
  .simple-image-cropper .crop-container {
    border-radius: 4px;
    max-width: 100%;
    max-height: 45vh;
    display: block; }
    .simple-image-cropper .crop-container img {
      max-width: 100%;
      max-height: 45vh;
      width: auto;
      height: auto;
      margin-left: auto;
      margin-right: auto;
      display: block; }
  .simple-image-cropper .action-area {
    margin: 8px 0;
    display: inline-flex; }
    .simple-image-cropper .action-area .action-button {
      margin-left: 8px;
      background-color: #dce7ec;
      color: rgba(28, 77, 107, 0.41);
      height: 50px;
      width: 50px;
      line-height: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      align-self: flex-start;
      border-radius: 4px;
      cursor: pointer; }
      .simple-image-cropper .action-area .action-button.remove-button {
        background-color: rgba(255, 167, 167, 0.15);
        border: 1px solid #ffa7a7; }
      .simple-image-cropper .action-area .action-button.hide-button {
        display: none; }
  .simple-image-cropper.no-photo {
    margin-top: 8px;
    margin-bottom: 8px;
    padding-top: 35.25%;
    border-radius: 4px;
    border: 2px solid #E0E7EE;
    background-color: white; }
    .simple-image-cropper.no-photo img,
    .simple-image-cropper.no-photo .action-area {
      display: none; }

.add-pass {
  right: 50px;
  top: -20px; }
  @media only screen and (max-width: 1023px) {
    .add-pass {
      right: 10px;
      top: -15px; } }

.passes-description-container, .passes-description-container-mobile {
  display: flex;
  flex-direction: column;
  align-content: flex-start;
  justify-content: flex-start;
  padding: 2vh 1vw 1vh 2vw;
  border-top: 1px solid #eeeeee;
  margin-top: 15px; }
  .passes-description-container .passes-description-header, .passes-description-container-mobile .passes-description-header {
    color: #80a2b2;
    font-size: 14px;
    line-height: 14px;
    font-weight: bold;
    margin-bottom: 1vh; }
  .passes-description-container .passes-description, .passes-description-container-mobile .passes-description {
    font-size: 16px;
    line-height: 22px;
    margin-bottom: 0;
    margin-right: 5px; }

.passes-description-container-mobile {
  padding: 15px 15px 15px 15px;
  border-top: none;
  border-bottom: 1px solid #eeeeee;
  width: 96%; }

.renew-pass-btn {
  min-width: 250px;
  max-height: 50px;
  margin: 20px 20px 20px auto; }

.renew-pass {
  align-self: flex-end; }

.renew-pass-notallowed {
  box-sizing: border-box;
  max-height: 29px;
  margin: 5% 5%;
  width: 90%;
  font-family: Foco;
  font-size: 16px;
  color: #ff0000;
  letter-spacing: 1px;
  line-height: 19px;
  text-align: center;
  border: 1px solid #ffa7a7;
  border-radius: 4px;
  background-color: rgba(255, 167, 167, 0.15); }

.renew-pass-notallowed-mobile {
  box-sizing: border-box;
  max-height: 60px;
  margin: 5% 5%;
  width: 90%;
  font-family: Foco;
  font-size: 16px;
  color: #ff0000;
  letter-spacing: 1px;
  line-height: 19px;
  text-align: center;
  border: 1px solid #ffa7a7;
  border-radius: 4px;
  background-color: rgba(255, 167, 167, 0.15); }

.renew-pass-mobile {
  align-self: center; }
  .renew-pass-mobile .renew-pass-btn {
    margin-right: 0; }

.temp-text {
  color: #1e2171; }

.text-grey {
  color: gray; }

.breadcrumb {
  margin: 1px 3px;
  padding: 1px;
  text-align: center; }
  .breadcrumb .breadcrumb-child {
    display: inline-block;
    padding: 3px 3px;
    vertical-align: middle;
    font-size: 16px; }

.save-btn {
  margin-right: 0;
  margin-top: 0;
  margin-bottom: 0;
  margin-left: 80%;
  width: 200px;
  max-height: 50px; }

.row-text {
  display: inline-flex; }

.column {
  float: left;
  width: 45%;
  padding: 0px 2.5%; }
  .column h1 {
    color: #1e2171;
    text-align: center;
    margin-top: 0px; }
  .column h3 {
    color: #1e2171; }

/* Clear floats after the columns */
.row:after {
  content: "";
  display: table;
  clear: both; }

.input {
  margin-top: 15px; }

.pad-preferences {
  margin: 10px; }

.float-left {
  float: left; }

.float-right {
  float: right; }

.vehicle-table {
  width: 100%; }

.vehicle-table th {
  text-align: left;
  font-size: 18px; }

.summary-table {
  width: 100%;
  text-align: center; }

.vehicle-table tbody > tr > td {
  border: none;
  padding: 0px; }

.summary-table tbody > tr > td {
  border: none; }

.summary-table th {
  font-size: 18px; }

.summary-table td {
  padding: 20px 20px; }

.add-membership-button {
  width: 150px; }

.autocomplete-input {
  padding: 0px;
  height: 48px;
  width: 90%; }
  .autocomplete-input .css-yk16xz-control {
    height: 48px; }
  .autocomplete-input .css-1pahdxg-control {
    height: 48px; }
  .autocomplete-input .css-1hwfws3 {
    height: 48px; }
  .autocomplete-input .css-1g6gooi {
    height: 48px;
    line-height: 28px; }

.transfer-mem {
  margin-top: -25px; }

.referred-drop-down {
  margin-left: 5px;
  margin-right: 5px;
  margin-bottom: 10px;
  margin-top: 7px;
  width: 60px;
  display: inline-block; }

.end-date-text {
  margin-top: 10px;
  margin-bottom: 5px;
  margin-left: 20px; }

.end-date-label {
  margin-top: 10px;
  margin-bottom: 0px; }

.end-date-text-desktop {
  margin-top: 20px; }

.end-date-label-desktop {
  margin-top: 15px;
  margin-bottom: 0px; }

.agreement-container {
  margin-left: 10%; }

.agreement-container-mobile {
  margin-left: 5%; }

.agreement-box-mobile {
  border: 1px solid black;
  width: 90%;
  height: 400px;
  margin-top: 3%;
  padding: 10px;
  border-radius: 8px;
  overflow-y: scroll; }

.agreement-box {
  border: 1px solid black;
  width: 85%;
  height: 600px;
  margin-top: 3%;
  padding: 10px;
  border-radius: 8px;
  overflow-y: scroll; }

.agreement-checkbox {
  margin-top: 2%;
  text-align: center; }

.agreement-checkbox-mobile {
  margin-top: 3%;
  text-align: center; }

.agreement-continue-button {
  width: 200px;
  margin-left: 0;
  margin-top: 2%; }

.agreement-continue-button-mobile {
  width: 200px;
  margin-left: 0;
  margin-top: 4%;
  text-align: center; }

.align-div {
  margin: auto;
  width: 70%;
  padding: 20px;
  padding-top: 30%; }

.confirmButton {
  margin-left: 23%;
  margin-top: 10px; }

.desktopVersion {
  width: 100%; }

.renew-membership-confirm-button {
  padding-left: 10px;
  padding-right: 10px;
  float: right; }

