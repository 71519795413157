@import '../../../variables';

.park-news-body {
    padding: 38px 38px 0 38px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;

    img {
        height: auto;
        width: 100%;
    }

    > .title {
        color: $corporate-blue;
        margin-top: 8px;
        margin-bottom: 38px;
    }

    p {
        margin-bottom: 16px;
    }

    .title:first-of-type {
        color: $corporate-blue;
        font-family: Foco;
        font-size: 27px;
        font-weight: bold;
        letter-spacing: 0.5px;
        line-height: 31px;
    }

    .park-news-date {
        color: #80a2b2;
        font-family: Foco;
        font-size: 14px;
        font-weight: bold;
        letter-spacing: 0.41px;
        line-height: 25px;
        margin-top: 1em;
    }

    .park-news-content-wrapper {
        display: flex;
        flex-direction: column;
        flex: 1 1;
        margin-left: 1em;
    }

    .park-news-content {
        font-family: Foco;
        font-size: 16px;
        line-height: 22px;
        margin-top: 1em;
    }
    
    .park-news-img {
        flex: 1 1;
    }

    @media only screen and (max-width: 767px) {
        flex-direction: column;
        padding: 20px 20px 0 20px;

        img {
            max-width: 100%;
        }

        p,
        ul,
        ol {
            color: $body-colour;
        }

        p {
            font-size: 15px;
            line-height: 24px;
            letter-spacing: 0.94px;
        }

        .park-news-content-wrapper {
            margin-left: 0;
        }
    }
}
.park-spinner-container{
    display: flex;
    justify-content: center;
}
.news-back-button {
    padding: 38px;

    @media only screen and (max-width: 767px) {
        padding: 20px;
    }
}
