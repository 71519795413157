.restaurant-modal {
    max-width: 730px;
    min-width: 600px;
    max-height: 650px;
    overflow-y: auto;

    @media only screen and (max-width: 1023px) {
        min-width: 0;
        width: 100%;
    }
    .restaurant-modal-container {
        width: 100%;

        .restaurant-message {
            margin-bottom: 0;
        }

        .restaurant-list {
            display: flex;
            flex-wrap: wrap;
            align-self: flex-start;
            justify-content: space-between;
            width: 100%;
        }
    }
}
