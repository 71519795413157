@import '../../../variables';

.temp-text {
    color: $corporate-blue;
    font-weight: 800;
  }
  
  .text-grey {
    color: gray;
  }

.breadcrumb {
    margin: 1px;
    padding: 1px 1px;
    text-align: center;
  
    .breadcrumb-child {
      display: inline-block;
      padding: 5px 5px;
      vertical-align: middle;
      font-size: 16px;
    }
  }
  
  .form {
    margin: 20px 20px 1px;
    padding: 1px 1px;
    
  }