.description-container {
    display: flex;
    flex-direction: column;
    flex: 1;
}

.description-container-mobile {
    display: flex;
    flex-direction: column;
    text-align: center;
}

