.button-group.passes-filter-buttons {
    height: 100%;
    justify-content: center;

    .btn {
        max-width: 200px;
        min-height: 90px;
        border: 1px solid #e1e3e8;
        border-radius: 8px;
        background-color: rgba(255, 255, 255, 0.56);
        box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.17);
        font-size: 14px;
        margin-right: 15px;
        flex-basis: 200px;
        flex-grow: 1;

        .btn-alternate-text {
            margin-top: 1vh;
        }
    }
}

.mobile-filter {
    .button-group.passes-filter-buttons {
        justify-content: space-around;

        .btn {
            font-size: 13px;
            min-width: 28vw;
            max-width: 28vw;
            margin-right: 0;
        }
    }
}
