.my-bank-details-modal {
    width: 650px;

    form {
        width: 100%;
    }
}

.bank-details-modal-group {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin-bottom: 25px;

    .bank-details-modal-field {
        display: flex;
        flex-direction: column;
        width: 50%;

        label {
            color: #80a2b2;
            font-size: 14px;
            font-weight: bold;
            margin-bottom: 10px;
        }

        span {
            font-size: 20px;
        }
    }
}

.my-bank-details-modal-mobile {
    max-width: 100%;
    width: 100%;
    position: relative;

    .bank-details-modal-group {
        flex-direction: row;
    }

    .bank-details-modal-field {
        margin-bottom: 25px;
    }
}
