@import '../../../variables';

.agreement-container{
    margin-left: 10%;
}

.agreement-container-mobile{
    margin-left: 5%;
}

.agreement-box-mobile{
    border: 1px solid black;
    width: 90%;
    height: 400px;
    margin-top: 3%;
    padding: 10px;
    border-radius: 8px;
    overflow-y: scroll;
}

.agreement-box{
    border: 1px solid black;
    width: 85%;
    height: 600px;
    margin-top: 3%;
    padding: 10px;
    border-radius: 8px;
    overflow-y: scroll;
}

.agreement-checkbox{
    margin-top: 2%;
    text-align: center;
}

.agreement-checkbox-mobile{
    margin-top: 3%;
    text-align: center;
}

.agreement-continue-button{
    width: 200px;
    margin-left: 0; 
    margin-top: 2%;
}

.agreement-continue-button-mobile{
    width: 200px;
    margin-left: 0; 
    margin-top: 4%;
    text-align: center;
}