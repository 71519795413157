@import '../../../variables';

.register-popup-container {
    flex: 50%;
    overflow-y: auto;

    .login-back {
        margin: 5vh 5vw 0 auto;
    }

    .external-login-container.register {
        margin-top: 5vh;
    }

    .external-login-container.register.mobile {
        margin-top: 0;
    }


    .register-explanation {
        margin-top: 5vh;
        margin-left: 10vw;
        margin-right: 10vw;
        text-align: center;
    }

    .register-explanation.mobile {
        color: #ffffff;
        margin: 1vh 0 3vh 0;
    }

    .register-login-error {
        margin-top: 25px;
    }

    form {
        margin-left: 5vw;
        margin-right: 5vw;
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;

        @media only screen and (max-width: 1023px) {
            margin: 0;
        }
    }

    .button-container:last-child {
        margin-right: 0;
        justify-content: flex-end;
        align-items: flex-end;
    }

    .register-button {
        @media only screen and (max-width: 1023px) {
            margin-top: 0 !important;
            margin-bottom: 20px;

            button {
                margin-top: 0;
                margin-bottom: 0;
            }
        }
    }

    .register-step-title {
        font-size: 23px;
        letter-spacing: 0.4px;
        color: #1c4d6b;
        line-height: 26px;

        &.complete {
            margin-bottom: 20px;
        }

        @media only screen and (max-width: 1023px) {
            color: #fff;
            text-align: center;
        }
    }

    .registration-complete {
        margin-left: 5vw;
        margin-right: 5vw;
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        margin-bottom: 40%;

        @media only screen and (max-width: 1023px) {
            text-align: center;
            margin-top: 20%;
            margin-left: 0;
            margin-right: 0;

            > p {
                color: #fff !important;
            }
        }

        .register-complete-text {
            color: #445870;
            font-size: 16px;
            line-height: 22px;
            margin: 0;
        }

        .register-complete-thank {
            margin-bottom: 30px;
        }

        .register-complete-resend {
            color: $body-colour;
            font-size: 16px;
            line-height: 19px;
            margin-top: 40px;
            margin-bottom: 0;
            letter-spacing: 1px;
        }
    }
}

.register-error {
    margin-top: 0;
    margin-bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}
