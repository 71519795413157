@import '../../../variables';

.widget {
    padding: 20px 20px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;

    @media only screen and (max-width: 1023px) {
        padding: 20px 17px;
    }

    .widget-contents {
        flex: 1;
        background-color: #fff;
        box-shadow: 0 2px 11px -3px rgba(95, 129, 226, 0.44);
        border-radius: 8px;
        display: flex;
        flex-direction: column;
        position: relative;
    }

    .widget-header {
        height: 16px;
        padding: 25px;
        text-align: center;
        text-transform: uppercase;
        font-weight: bold;
        letter-spacing: 0.94px;
        line-height: 16px;
        color: $corporate-blue;
        box-shadow: 0 2px 11px -3px rgba(95, 129, 226, 0.44);
    }

    @media screen and (max-width: 600px) {
        .widget-footer {
            padding: 20px 20%;
        }
    }

    @media screen and (min-width: 600px) {
        .widget-footer {
            padding: 20px;
        }
    }

    .widget-footer {
        text-align: center;
        text-transform: uppercase;
        font-weight: bold;
        letter-spacing: 0.94px;
        line-height: 16px;
        color: #a0a0a0;
        box-shadow: 0 2px 11px -3px rgba(95, 129, 226, 0.44);
        margin-top: auto;
    }
}

.link-widget {
    display: flex;
    flex: 1;
    text-decoration: none;
}

.widget-list {
    @extend .widget;
    background: none;
    box-shadow: none;
    position: relative;
    min-width: 0;

    @media only screen and (min-width: 1024px) {
        width: 100%;
    }

    .widget-header {
        @extend .widget-header;
        background-color: #fff;
        border-radius: 8px;
    }

    .widget-list-item {
        @extend .widget-header;
        margin-top: 18px;
        padding: 10px 15px;
        height: initial;
    }
}
