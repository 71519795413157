.restaurant-button {
    display: flex;
    box-shadow: 0 2px 11px -3px rgba(95, 129, 226, 0.44);
    border-radius: 10px;
    max-height: 70px;
    padding: 10px;
    min-width: 330px;
    margin-bottom: 15px;

    &:nth-child(odd) {
        margin-right: 25px;
    }

    @media only screen and (max-width: 1023px) {
        align-self: flex-end;
        min-width: 0;
        width: 100%;

        &:nth-child(odd) {
            margin-right: 0;
        }
    }

    &:hover {
        cursor: pointer;
    }

    .restaurant-image {
        border-radius: 9px;
        height: 52px;
        min-width: 116px;
        overflow: hidden;
        display: flex;
        align-items: center;

        img {
            max-width: 116px;
        }
    }

    .restaurant-name {
        margin-bottom: 0;
        margin-left: 15px;
        font-size: 16px;
        line-height: 14px;
        color: #445870;
        align-self: center;
    }
}
