$cell-width: 30px;

.renew-checkbox {
    color: #565656;
    font-family: Foco;
    font-size: 16px;
    letter-spacing: 2px;
    line-height: 18px;
    display: flex;
    align-items: center;
    width: $cell-width;
    border-color: red;
}

.renew-checkbox-header {
    flex-basis: $cell-width !important;
}

.renew-checkbox input {
    box-sizing: border-box;
    height: 16px;
    width: 16px;
}
