@import '../../../variables';

.no-transactions {
    padding: 25px;
    flex: 1;
    display: flex;
    align-items: center;
    text-align: center;

    font-size: 16px;
    line-height: 21px;
    letter-spacing: 0.95px;
    border-bottom: 2px solid #eeeeee;
}

.transaction-spinner {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
}

.transaction-balance {
    margin-top: auto;
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-top: 2px solid #eeeeee;
    border-bottom: 2px solid #eeeeee;

    font-weight: bold;
    font-size: 15px;
    line-height: 16px;
    letter-spacing: 0.95px;
    text-transform: uppercase;
    color: #7c9fb0;
}
.view-account-container {
    padding: 12px 25px;
    .view-account {
        display: block;

        box-shadow: 0 5px 7px -3px rgba(133, 133, 133, 0.5);
        color: #fff;
        background-color: $primary-button-colour;
        font-size: 16px;
        font-weight: bold;
        line-height: 18px;
        letter-spacing: 1px;
        text-transform: uppercase;
        text-decoration: none;
        text-align: center;
        border-radius: 4px;
        padding: 10px;

        transition: 200ms ease-in-out;
        &:hover {
            background-color: #143a50;
            box-shadow: 0 5px 20px 0 rgba(133, 133, 133, 0.5);
        }
    }
}
