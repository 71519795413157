@import "./fonts.scss";
@import "./variables";

html,
body {
  height: 100%;
  background-color: #f5f7f9;
}

#root {
  height: 100%;
  margin-top: -2.5em;
  padding-top: 2.5em;
  // this prevents content moving when going from a page with a scrollbar, to one without
  margin-left: calc(100vw - 100%);
}

body {
  margin: 0;
  font-family: "Foco", Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: $body-colour;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

h1 {
  color: #fff;
  font-size: 29px;
  letter-spacing: 0.54px;
  line-height: 32px;
  font-weight: normal;
  margin-top: 37px;
  margin-bottom: 12px;
}

p {
  color: $body-colour;
  font-size: 18px;
  line-height: 22px;
  margin-top: 0;
  margin-bottom: 30px;
}

.contentful-text {
  
  p {
    margin-bottom: 0px;
    font-size: 14px;
    text-align: left;
  }
  ul {
    li {
      p {
        margin-bottom: 0px;
        font-size: 14px;
      }
    }
  }
}

.contentful-text-payment {
  
  p {
    margin-bottom: 0px;
    font-size: 16px;
    text-align: left;
  }
  ul {
    li {
      p {
        margin-bottom: 0px;
        font-size: 16px;
      }
    }
  }
}

button::-moz-focus-inner {
  border: 0;
}

table {
  border-spacing: 0;
}

thead {
  background-color: #f9fbfc;
}

thead th {
  color: #8c95a1;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0.88px;
  line-height: 15px;
  text-align: left;
  padding: 18px 40px;
  border-top: 1px solid #eeeeee;

  @media only screen and (max-width: 1023px) {
    padding: 18px 20px;
  }
}

tbody td {
  padding: 14px 40px;
  border-bottom: 1px solid #eeeeee;
  line-height: 22px;
  color: $primary-button-colour;

  @media only screen and (max-width: 1023px) {
    padding: 14px 20px;
  }
}

.link-right {
  color: $primary-button-colour;
  text-decoration: none;

  @media only screen and (max-width: 1023px) {
    color: #fff;
  }
}

.heading-center {
  display: block;
  text-align: center;
  color: $primary-button-colour;
  position: relative;
  margin-bottom: 0;

  @media only screen and (max-width: 1023px) {
    color: #fff;
  }
}

.heading-right {
  display: block;
  text-align: right;
  color: $primary-button-colour;
  position: relative;
  margin-bottom: 0;

  @media only screen and (max-width: 1023px) {
    color: #fff;
  }
}

.form-input {
  margin: 8px 0;
  border-radius: 4px;
  padding-left: 16px;
  box-sizing: border-box;
  font-size: 16px;
  letter-spacing: 1px;
  height: 40px;
  line-height: 50px;
  font-family: "Foco", Arial, sans-serif;
  background-color: #fff;
  border: 1px solid #e0e7ee;
  color: $body-colour;
  transition: color ease-in-out 0.3s, border-color ease-in-out 0.3s,
    box-shadow ease-in-out 0.3s;
  will-change: color, border-color, box-shadow;

  &::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #a0a0a0;
    opacity: 1;
    /* Firefox */
  }

  &:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #a0a0a0;
  }

  &::-ms-input-placeholder {
    /* Microsoft Edge */
    color: #a0a0a0;
  }

  &:active,
  &:focus {
    outline: none;
    border: 1px solid #45417b;
    box-shadow: 0 2px 4px 0 #c2bfde;
  }

  &.invalid {
    border: 1px solid #ff5252;
    box-shadow: 0 2px 4px 0 rgba(255, 82, 82, 0.3);
    color: #ff647c;
  }
}

.form-input-alternative {
  @extend .form-input;
  border: 1px solid #fff;
  background-color: #e8f0fe;
  color: $body-colour;

  &::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: rgba($body-colour, 0.6);
    opacity: 1;
    /* Firefox */
  }

  &:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: rgba($body-colour, 0.6);
  }

  &::-ms-input-placeholder {
    /* Microsoft Edge */
    color: rgba($body-colour, 0.6);
  }

  &:active,
  &:focus {
    outline: none;
  }
}

.widget-container {
  display: -ms-grid;
  display: grid;
  width: calc(100% + 20px);
  //height: 100%;
  -ms-grid-columns: #{"(minmax(max-content, 1fr))[12]"};
  grid-template-columns: repeat(12, minmax(auto, 1fr));
  -ms-grid-rows: #{"( minmax(75px, max-content) )[12]"};
  //grid-template-rows: repeat(12, minmax(30px, auto));
}

.widget-container-top {
  @extend .widget-container;
  -ms-grid-rows: #{"( minmax(75px, max-content) )[1]"};
  grid-template-rows: repeat(1, minmax(75px, auto));
  height: initial;
  margin-bottom: 30px;
}

.mobile-widget-container {
  grid-template-rows: repeat(12, auto);
  width: 100%;
  margin: 0;

  .widget-layout-title {
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-column-span: 12;
    grid-column-end: span 12;
    -ms-grid-row: 1;
    grid-row-start: 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    text-align: center;
    margin-top: 15px;
  }

  .widget-layout-message {
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-column-span: 12;
    grid-column-end: span 12;
    -ms-grid-row: 2;
    grid-row-start: 2;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    margin: 0 22px 30px;
    letter-spacing: 0.8px;
    line-height: 25px;
    height: 125px;
  }

  .widget {
    margin: 0;
  }
}

.mobile-home-widget-container {
  width: 100%;
  margin: auto;
  max-width: 500px;
}

.widget-layout-title {
  -ms-grid-column: 1;
  grid-column-start: 1;
  -ms-grid-column-span: 8;
  grid-column-end: span 8;
  -ms-grid-row: 1;
  grid-row-start: 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  margin-left: 17px;
  margin-right: 17px;
}

.widget-layout-message {
  -ms-grid-column: 1;
  grid-column-start: 1;
  -ms-grid-column-span: 12;
  grid-column-end: span 12;
  -ms-grid-row: 2;
  grid-row-start: 2;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  margin-left: 17px;
  margin-right: 17px;
  color: #fff;

  p {
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-column-span: 12;
    grid-column-end: span 12;
    -ms-grid-row: 2;
    grid-row-start: 2;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    margin-left: 17px;
    margin-right: 17px;
    color: #fff;
  }
}

.error-message {
  padding: 8px;
  min-height: 36px;
  margin-bottom: 2vh;
  line-height: 36px;
  border-radius: 4px;
  text-align: center;
  color: transparent;

  &.show {
    border-radius: 8px;
    border: 1px solid #ffa7a7;
    color: #ff0000;
    background-color: rgba(255, 167, 167, 0.15);
  }
}

.error-message-mobile {
  min-height: 54px;
  margin: 15px 0 -15px;
  text-align: center;
  padding: 8px;
  color: transparent;

  &.show {
    color: $body-colour;
    font-weight: bold;
  }
}

.info-message {
  @extend .error-message;

  &.show {
    font-size: 16px;
    border-radius: 8px;
    border: 1px solid #7c9fb0;
    color: $body-colour;
    background-color: rgba(27, 78, 107, 0.05);
  }
}

.info-message-mobile {
  @extend .error-message-mobile;

  &.show {
    color: $body-colour;
    font-weight: bold;
  }
}

.horizontal-background.mobile-background {
  min-height: 285px;
}

.menu-item-title {
  background-color: #fff;
  text-align: center;
  margin-top: auto;
  text-transform: uppercase;
  font-size: 15px;
  font-weight: bold;
  letter-spacing: 0.94px;
  line-height: 16px;
  height: 16px;
  color: #74757a;
  padding: 17px 0;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  display: block;
  width: 100%;
}

.message-modal.mobile-success-modal {
  padding: 40px 50px;
}

.button-group {
  display: flex;
  align-items: center;

  .btn,
  .btn.btn-secondary {
    max-width: 128px;
    max-height: 30px;
    margin: 0;
    border-radius: 0;
    border: 1px solid rgba(28, 77, 107, 0.19);
    text-transform: none;
    font-size: 15px;
    font-weight: normal;
    color: $body-colour;

    &:hover {
      background-color: darken(#f1f5fb, 5%) !important;
    }

    &.active:hover {
      box-shadow: 0 2px 17px -5px rgba(95, 129, 226, 0.4);
    }

    &.active {
      box-shadow: unset;
      border: 1px solid #e1e3e8;
      background-color: #e7effc;
    }

    &:first-child {
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
    }

    &:last-child {
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
    }
  }
}

.pagination-icons {
  display: flex;
  position: absolute;
  top: 20px;
  right: 20px;

  .icon-previous,
  .icon-next {
    border: 1px solid #cdcdcd;
    border-radius: 4px;
    background-color: #ffffff;
    box-shadow: 0 2px 17px -5px rgba(95, 129, 226, 0.4);
    padding: 5px;
  }
}

.mobile-widget-container {
  .pagination-icons {
    top: 102px;
    right: 20px;
  }
}

.mobile-select__menu {
  z-index: 1000 !important;
}

.kill-scroll {
  overflow: hidden;
  -webkit-overflow-scrolling: touch;
  // needed for safari
  position: fixed;
}

.widget-ie-fix {
  display: block !important;

  >.widget-contents {
    display: block !important;
    overflow: hidden;
  }
}

.cookie-button {
  width: 175px;
}