@import '../../../variables';

.calendar-widget {
    height: 360px;

    .rbc-calendar {
        padding: 0px 25px 25px;

        .rbc-toolbar {
            display: none;
        }

        .rbc-month-view .rbc-row.rbc-month-header {
            margin: 0 -25px;
            padding: 0 25px;
            height: 40px;

            .rbc-header {
                line-height: 40px;

                span {
                    color: transparent;
                    font-size: 0;
                    display: inline-block;

                    &::first-letter,
                    &:first-letter {
                        color: #7c9fb0;
                        font-size: 13px;
                    }
                }
            }
        }

        .rbc-month-row {
            justify-content: center;
        }

        .rbc-date-cell {
            pointer-events: none;
            font-size: 13px;
        }
    }

    .calendar-container {
        height: calc(100% - 50px);
        flex: 1;
        display: flex;
        flex-direction: column-reverse;

        .calendar-keys {
            top: 0;
            padding: 5px 5px;
            border-top: none;

            .calendar-key {
                margin-left: 15px !important;
                margin-right: 0;
            }
        }

        .rbc-event {
            cursor: default;
        }
    }

    > a {
        color: $body-colour;
        font-size: 16px;
        font-weight: bold;
        letter-spacing: 1px;
        line-height: 18px;
        text-align: center;
        text-decoration: none;
        text-transform: uppercase;
        display: block;
        margin: -10px 30px 10px;
        box-shadow: 0 2px 17px -5px rgba(95, 129, 226, 0.4);
        border: 1px solid #cdcdcd;
        border-radius: 4px;
        padding: 12px;

        transition: 200ms ease-in-out;
        &:hover {
            background-color: #f5f6fb;
        }
    }
}
