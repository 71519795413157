@import '../../../variables';

.member-table {
  width: 100%;
  padding: 20px;
  text-align: center;

  table {
    width: 95%;
    border-color: $corporate-blue;
    border-width: 5px;
    border-style: solid;

    tr {
      margin-top: 50px;

      th {
        border-color: $corporate-blue;
        border-width: 1px;
        border-style: solid;
      }

      td {
        border-color: $corporate-blue;
        border-width: 1px;
        border-style: solid;
      }
    }
  }
}

.memberships-spinner {
  text-align: center;
  margin: 10% 40%;
}

.float-right {
  float: right;
  text-align: right;
}

.description-inline {

  .inline-p {
    display: inline-block;
    padding: 0px 40px;
  }
}

.filter-parent {
  text-align: right;
  display: inline-block;
  padding-left: 170px;

  .filter-child {
    display: inline-block;
    padding: 0px 10px;
  }
}

@keyframes fadeIn {
  0% { opacity: 0;}
  100% {opacity: 1;}
}

@keyframes fadeOut {
  0% {opacity: 1;}
  100% { opacity: 0;}
}

@keyframes moveDown {
  0% { max-height: 0px; }
  100% { max-height: 1000px; }
}



.gridDisplay {
  display: flex;
  flex-wrap: wrap;
  animation: moveDown 1s;
}

@media screen and (max-width: 600px) {
  .sign-agreement-button {
    justify-content: center;
  }

  .container-box {
    width: 90%;
    border: 1px solid darkgray;
    padding: 10px;
    padding-bottom: 50px;
    animation: fadeIn 0.5s;
    border-radius: 10px;
    margin-bottom: 10px;
    position: relative;
    h4 {
      margin-top: 0px;
      margin-bottom: 5px;
      font-size: 16px;
    }
    p {
      font-size: 14px;
      margin-bottom: 2px;
      margin-left: 2%;
    }
  }
  
  .container-title {
    text-align: center;
  }

  .dashboard-owner-memberships {
    justify-content: center;
  }
}

@media screen and (min-width: 600px) {
  .sign-agreement-button {
    float: right;
  }

  .container-box {
    width: 500px;
    border: 1px solid darkgray;
    padding: 10px;
    padding-bottom: 30px;
    margin: 10px;
    animation: fadeIn 0.5s;
    border-radius: 10px;
    position: relative;
    h4 {
      margin-top: 0px;
      margin-bottom: 5px;
      font-size: 16px;
    }
    p {
      font-size: 14px;
      margin-bottom: 2px;
      margin-left: 2%;
    }
  }

  .container-title {
    text-align: left;
  }

  .dashboard-owner-memberships {
    float: right; 
  }
}

.no-membership-text {
  p {
    margin: 0px;
    margin-bottom: 10px;
    text-align: center;
    color: gray;
  }
}