@import '../../../variables';

.transaction-balance-widget {
    text-align: center;
    padding-top: 0;
    padding-left: 20px;
    padding-right: 20px;
    .title {
        margin-top: 20px;
        font-size: 23px;
        line-height: 26px;
        letter-spacing: 0.43px;
        color: $corporate-blue;
    }

    .balance {
        margin-top: 10px;
        margin-bottom: 20px;
        font-size: 32px;
        line-height: 36px;
        letter-spacing: 0.6px;
        color: $corporate-blue;
    }
}

.transaction-balance-widget > .btn-tertiary {
    margin-top: 20px;
    margin-left: -20px;
    margin-right: -20px;
}
