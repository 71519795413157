.quick-reply-modal {
  width: 650px;

  form {
    width: 100%;
  }
}

.quick-reply-modal-group {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-bottom: 25px;

  .quick-reply-modal-field {
    display: flex;
    flex-direction: column;
    width: 50%;

    label {
      color: #80a2b2;
      font-size: 14px;
      font-weight: bold;
      margin-bottom: 10px;
    }

    span {
      font-size: 20px;
    }
  }
}

.quick-reply-modal-mobile {
  max-width: 100%;
  width: 100%;
  position: relative;

  form {
    footer button {
      margin-left: 5%;
    }
  }
  .quick-reply-modal-group {
    flex-direction: row;
  }

  .quick-reply-modal-field {
    margin-bottom: 25px;
  }
}

.message-body {
  width: 100% !important;

  textarea {
    min-height: 180px;
    line-height: 20px;
  }
}
