@import '../../../variables';
$cell-width: 30px;

.delete-btn {
    width: 26px;
    height: 26px;
    box-shadow: 0 1px 1px 0 rgba(37, 130, 237, 0.5);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: $primary-button-colour;
    opacity: 0.63;
    margin-left: auto;

    transition: transform 0.1s ease-in-out;
    &:hover {
        cursor: pointer;
        transform: scale(1.1);
    }

    @media only screen and (max-width: 1023px) {
        margin-top: 5px;
    }
}

.remove-pass-cell {
    width: $cell-width;
}

.remove-pass-cell-header {
    flex-basis: $cell-width !important;
}
