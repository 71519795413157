@import "../../../variables";

.modal {
  padding: 30px;
  background-color: white;
  border-radius: 8px;
  box-shadow: 3px 8px 26px 0 rgba(0, 0, 0, 0.25);
  display: flex;
  -ms-flex-direction: column;
  -webkit-flex-direction: column;
  flex-direction: column;
  align-items: center;

  &:focus {
    outline: none;
  }

  h1 {
    margin-top: 0;
    color: $corporate-blue;
  }

  p {
    font-size: 13px;
    line-height: 19px;
    letter-spacing: 0.8px;

    &.error-message {
      font-size: 18px;
      line-height: 36px;
    }
  }

  .modal-header {
    color: $corporate-blue;
    font-size: 29px;
    line-height: 29px;
    letter-spacing: 0.43px;

    margin: 0 auto 30px auto;
    text-align: center;
  }

  .modal-footer {
    display: flex;
    margin-top: 50px;
    width: 100%;

    @media only screen and (max-width: 1023px) {
      margin-top: 20px;
    }

    .back-button {
      position: relative;
    }
  }
}

.modal-overlay.mobile-modal-overlay {
  justify-content: flex-start;

  // don't take up full height on iPad
  @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
    justify-content: center;
    padding: 0 50px;

    .modal {
      height: initial !important;
    }
  }

  .modal {
    max-height: 850px;
    height: auto;
    box-sizing: border-box;
    padding: 40px 20px;
  }
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.75);
  z-index: 100;
  display: flex;
  -ms-flex-direction: column;
  -webkit-flex-direction: column;
  flex-direction: column;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-align-items: center;
  align-items: center;
}

.message-modal {
  padding: 50px 80px;
  background-color: white;
  box-shadow: 3px 8px 26px 0 rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  position: relative;

  h1 {
    color: $corporate-blue;
    font-size: 22px;
    text-align: center;
    margin-top: 0;
  }

  p {
    color: rgba(27, 78, 107, 0.56);
    font-size: 13px;
    text-align: center;
    white-space: pre-line;
  }

  .btn.btn-primary {
    position: absolute;
    width: 100%;
    height: 50px;
    left: 0;
    bottom: 0;
    margin: 0;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }

  &:focus {
    outline: none;
  }

  .important-info {
    padding: 8px;
    min-height: 36px;
    margin-bottom: 2vh;
    line-height: 36px;
    border-radius: 8px;
    border: 1px solid #ffa7a7;
    color: #ff0000;
    background-color: rgba(255, 167, 167, 0.15);
    width: 400px;
    display: flex;
    align-items: center;

    * {
      display: inline-block;
      margin: 0;
      margin-left: 15px;
    }

    p {
      color: inherit;
      font-size: inherit;
      text-align: inherit;
      margin-right: 15px;
    }
  }
}

.confirm-modal {
  @extend .message-modal;
  .confirm-button-container {
    display: flex;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    overflow: hidden;

    .btn {
      margin: 0;
      border: none;
      position: relative;
      border-radius: 0;
      box-shadow: none;
      color: #fff;
    }

    .btn:first-child {
      border-bottom-left-radius: 8px;
    }
    .btn:last-child {
      border-bottom-right-radius: 8px;
    }
  }
}

.back-button {
  box-shadow: 0 5px 11px 0 rgba(185, 193, 197, 0.2);
  background-color: #dce7ec;
  color: rgba(28, 77, 107, 0.41);
  height: 50px;
  width: 50px;
  line-height: 20px;
  display: flex;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-align-items: center;
  align-items: center;
  align-self: flex-start;

  position: absolute;
  left: 0;

  transition: 200ms ease-in-out;

  &:hover {
    cursor: pointer;
    background-color: #d1dce1;
  }
}

.ReactModal__Body--open {
  // prevent scrolling the content behind the modal
  overflow: hidden;
  position: fixed;
  width: 100%;
  height: 100%;
}
