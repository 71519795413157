@import './variables';

.error-boundary {
    background-color: rgba(#1e2171, 0.2);
    display: flex;
    height: 100%;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    h1 {
        color: $corporate-blue;
        font-family: Foco;
        font-size: 35px;
        font-weight: bold;
        letter-spacing: 0.65px;
        line-height: 40px;
        text-align: center;
    }

    p {
        font-family: Foco;
        font-size: 18px;
        letter-spacing: 1px;
        line-height: 22px;
        max-width: 800px;
        text-align: center;
        margin: 15px 15px 30px;
    }

    .btn.btn-primary {
        max-height: 50px;
        width: 250px;
        margin-top: 25px;
    }
}
