@import '../../../variables';

.fab {
    width: 65px;
    height: 65px;
    border-radius: 50%;
    box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.19);
    font-size: 30px;
    line-height: 33px;
    color: $primary-button-colour;
    background-color: white;

    display: flex;
    justify-content: center;
    align-items: center;

    position: absolute;

    transition: 200ms ease-in-out;
    &:hover {
        background-color: #f5f6fb;
        cursor: pointer;
    }
}
