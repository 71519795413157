@import '../../../variables';
$sublet-booking-blob-color: #b7c9f8;
$sublet-booking-track-color: #d8e2ff;
$my-holidays-blob-color: #f9b881;
$my-holidays-track-color: #fbe3ce;
$my-pending-holidays-blob-color: #d1d1d1;
$my-pending-holidays-track-color: #ebebeb;
$events-blob-color: #e662bb;

$marker-width: 30px;
$marker-border-width: 21px;

.add-booking {
    @media only screen and (max-width: 1023px) {
        right: 30px;
        top: 200px;
    }

    right: 50px;
    top: -20px;
    z-index: 2;
}

.rbc-event.events {
    background-color: $events-blob-color;
    margin-left: -1px;
    height: 10px !important;
    width: 10px;
    transform: translate(10px, 25px);
    padding: 0;
}

.my-calendar {
    height: 700px;

    &:not(.mobile-calendar) {
        // Do not apply large tracks to mobile version as there is not enough space
        // Crazy selector to avoid affecting the small calendar. This is needed because te track is wider and everything needs to be positioned accordingly.
        .rbc-month-view .rbc-row-content .rbc-row-segment .rbc-event-wrapper,
        .rbc-calendar .rbc-month-view .rbc-row .rbc-row-segment .rbc-event-wrapper {
            margin-left: 37px;
            margin-right: 40px;

            .rbc-event {
                height: 45px;

                &.my-holidays, &.sublet-bookings, &.my-holidays.pending {

                    &:before {
                        top: 18px;
                        left: -43px;
                    }

                    &:after {
                        top: -5px;
                        right: -43px;
                    }

                    &:before, &:after {
                        border-width: 32px;
                    }
                }

                &.rbc-event.rbc-event-continues-after {
                    &:after {
                        top: 0;
                        right: -47px;
                    }

                    &.rbc-event-starts-on-week-end:before {
                        right: -40px;
                    }
                }

                &.rbc-event.rbc-event-continues-prior {
                    &:after {
                        right: -43px;
                    }

                    &.rbc-event-ends-on-week-start:after {
                        right: -33px;
                    }
                }

                &.rbc-event.rbc-event-continues-prior.rbc-event-continues-after {
                    &:before {
                        width: 49px;
                        left: -49px;
                    }

                    &:after {
                        right: -47px;
                    }
                }

                &.rbc-event.rbc-event-continues-prior:before {
                    top: 0;
                    width: 49px;
                    left: -49px;
                }
            }
        }
    }
}

.rbc-event {

    &:after {
        content: '';
        position: absolute;
        width: 0;
        height: 0;
        top: -3px;
        right: -28px;
        background-color: transparent;
        border-left: $marker-border-width solid transparent;
        border-right: $marker-border-width solid transparent;
        transform: rotate(-45deg);
    }

    &:before {
        content: '';
        position: absolute;
        width: 0;
        height: 0;
        top: 12px;
        left: -$marker-width + 2px;
        border-left: $marker-border-width solid transparent;
        border-right: $marker-border-width solid transparent;
        transform: rotate(135deg);
    }
}

.rbc-event.rbc-event-continues-after {

    &.sublet-bookings:after {
        background-color: darken($sublet-booking-track-color, 20%);
    }

    &.my-holidays:after {
        background-color: darken($my-holidays-track-color, 20%);
    }

    &.pending.my-holidays:after {
        background-color: darken($my-pending-holidays-track-color, 20%);
    }


    &:not(.rbc-event-continues-prior) {
        &:after {
            border-bottom: none;
            width: $marker-width;
            transform: none;
            top: 0;
            right: -34px;
            height: 100%;
        }

        &.rbc-event-starts-on-week-end {
            padding: 0;

            &:after {
                content: none;
            }
        }
    }
}

.rbc-event.rbc-event-continues-prior {

    &.sublet-bookings:before {
        border: none;
        background-color: darken($sublet-booking-track-color, 20%);
    }

    &.my-holidays:before {
        border: none;
        background-color: darken($my-holidays-track-color, 20%);
    }

    &.pending.my-holidays:before {
        border: none;
        background-color: darken($my-pending-holidays-track-color, 20%);
    }


    &:not(.rbc-event-continues-after) {
        &:before {
            width: $marker-width + 2px;
            transform: none;
            top: 0;
            height: 100%;
            left: -32px;
        }

        &.rbc-event-ends-on-week-start {
            padding: 0;
            left: -38px;

            &:before {
                content: none;
            }
        }
    }
}

// for tracks that span whole weeks
.rbc-event.rbc-event-continues-prior.rbc-event-continues-after {
    &:before {
        top: 0;
        height: 100%;
        transform: none;
        width: 32px;
        left: -32px;
    }

    &:after {
        transform: none;
        right: -34px;
        height: 100%;
        top: 0;
    }
}

.rbc-event.rbc-event-continues-prior.rbc-event-ends-on-week-start {
    &:after {
        right: -34px;
    }
}

.rbc-event.rbc-event-continues-after.rbc-event-starts-on-week-end {
    &:before {
        left: -27px;
    }
}

.rbc-event.sublet-bookings {
    background-color: darken($sublet-booking-track-color, 20%);
    opacity: 0.3;
    cursor: default;
    
    &.clickable {
        cursor: pointer;
    }

    &:after {
        border-bottom: $marker-border-width solid  darken($sublet-booking-track-color, 20%);
    }

    &:before {
        border-bottom: $marker-border-width solid  darken($sublet-booking-track-color, 20%);
    }
}

.rbc-event.my-holidays {
    background-color: darken($my-holidays-track-color, 20%);
    opacity: 0.3;
    cursor: default;

    &:after {
        border-bottom: $marker-border-width solid darken($my-holidays-track-color, 20%);
    }

    &:before {
        border-bottom: $marker-border-width solid darken($my-holidays-blob-color, 20%);
    }
}

.rbc-event.my-holidays.pending {
    background-color: darken($my-pending-holidays-track-color, 20%);
    opacity: 0.3;
    cursor: default;
    

    &:after {
        border-bottom: $marker-border-width solid darken($my-pending-holidays-track-color, 20%);
    }

    &:before {
        border-bottom: $marker-border-width solid darken($my-pending-holidays-blob-color, 20%);
    }
}


.calendar-key.sublet-bookings {
    margin-left: 43px !important;
}

.calendar-key.sublet-bookings .calendar-colour {
    background-color: $sublet-booking-blob-color;
}

.calendar-key.my-holidays {
    margin-left: auto !important;

    .calendar-colour {
        background-color: $my-holidays-blob-color;
    }

    &.pending {
        .calendar-colour {
            background-color: $my-pending-holidays-blob-color;
        }
    }
}

.calendar-key.events .calendar-colour {
    background-color: $events-blob-color;
}

.mobile-widget-container .calendar-container {
    margin-top: 40px;
}

.calendar-container {
    z-index:0;
}

.mobile-widget-container {
    .calendar-container .calendar-keys {
        align-items: flex-start;
        justify-content: center;
        flex-direction: column;
        bottom: 0px;
        padding: 10px 0;
    }

    .calendar-key {
        margin: 5px 0;
    }

    .calendar-key.my-holidays {
        margin: 5px 0;
        margin-left: 25px !important;
    }
}

.mobile-calendar {
    &.rbc-calendar {
        padding: 25px 25px 150px 25px;
        margin-bottom: 5px;
    }

    .rbc-month-view {
        height: auto;
        top: 50px;
    }

    &.rbc-calendar .rbc-toolbar {
        margin-bottom: -50px;
        top: -10px;
    }

    &.rbc-calendar .rbc-toolbar .rbc-btn-group {
        position: relative;
        top: 0;
        left: 0;
        margin: 15px auto;
    }

    &.rbc-calendar .rbc-month-view .rbc-row.rbc-month-header {
        margin: 0 -25px;
        padding: 0 25px;

        .rbc-header {
            line-height: 40px;

            span {
                color: transparent;
                font-size: 0;
                display: inline-block;

                &::first-letter,
                &:first-letter {
                    color: #7c9fb0;
                    font-size: 13px;
                }
            }
        }
    }

    &.rbc-calendar .rbc-toolbar .rbc-toolbar-label {
        width: 100%;
        display: inline-block;
    }
}

.my-calendar-events-header {
    color: $corporate-blue;
    font-size: 23px;
    letter-spacing: 0.43px;
    line-height: 26px;
    margin-bottom: 20px;
}

.mobile-events-header {
    background-color: #fff;
    margin: 0 -17px 0 -17px;
    padding: 20px 0;
    box-shadow: 0 2px 11px 0 rgba(84, 83, 177, 0.14);

    .my-calendar-events-header {
        text-align: center;
        padding-bottom: 20px;
        border-bottom: 1px solid #eee;
        font-size: 19px;
    }

    .button-group {
        padding: 0 17px;

        .btn {
            max-width: 100px;
        }
    }
}

.reservation-links-widget-container {
    .widget-contents {
        background: transparent;
        box-shadow: none;
    }
}

.guarantee {
    margin: 0 45px;
    margin-bottom: 20px;
    line-height: 21px;
    text-align: center;

    @media only screen and (max-width: 1023px) {
        margin: 0 25px;
        margin-bottom: 25px;
    }
}
.sublet-booking-info-modal {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    flex-direction: row;
    position: relative;
    width: 380px;
    height: auto !important; 

    .sublet-booking-info-header {
        width: 100%;
        display: flex;
        align-items: center;
        margin-bottom: 20px;

        .home-icon-container {
            display:block;
            font-size: 15px;
        }
    }
    h1 {
        text-align: center;
        margin-bottom: 0;
        margin-left: 10px;
        margin-top: 10px;
        color: #184F6D;
    }
    span {
        width: fit-content;
        margin-right: 10px;
        color: #141A32;

    }
    p {
        width: 60%;
        margin-bottom: 5px;
        color:black;
        font-weight: bold;
        font-size: 14px;
    }
    .sublet-booking-info-note {
        width:100%;
        margin-top: 15px;
        color:#808390;
        font-style: normal;
        font-weight: normal;
    }
    .sublet-booking-info-back-button {
        width:10px;
        height:10px;
        position: absolute;
        right:30px;
        top:15px;
        cursor: pointer;
    }

}
