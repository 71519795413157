@import '../../../variables';

.restaurant-details {
    width: 100%;
    box-sizing: border-box;

    p {
        margin-bottom: 0;
    }

    .details {
        display: flex;
        flex-wrap: wrap;

        .image {
            max-width: 400px;
            max-height: 180px;
            border-radius: 9px;
            overflow: hidden;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: 25px;

            @media only screen and (max-width: 767px) {
                margin: 0;
                margin-bottom: 18px;
            }

            img {
                max-width: 400px;
            }
        }

        .side-details {
            > div:not(:last-child) {
                margin-bottom: 18px;
            }

            .book-a-table {
                margin-left: 0;
                min-width: 220px;
            }

            @media only screen and (max-width: 767px) {
                display: flex;
                width: 100%;
                flex-wrap: wrap;

                .book-a-table {
                    margin-top: 25px;
                    width: 100%;
                }

                > div {
                    flex: 1;
                }

                > div:not(:last-child) {
                    margin-bottom: 0;
                }
            }
        }
    }

    .title {
        font-size: 14px;
        font-weight: bold;
        line-height: 14px;
        color: $corporate-blue;
        margin-bottom: 10px;
    }

    .description {
        font-size: 16px;
        line-height: 22px;
        color: $body-colour;
    }

    .value {
        font-size: 20px;
        line-height: 18px;
        color: $body-colour;
    }

    a.value {
        color: #008bff;
    }

    .detail-margin {
        margin-top: 25px;
    }

    .menu-list {
        margin-right: -10px;
        margin-bottom: -10px;
        max-width: 680px;

        > button {
            margin: 0;
            margin-right: 10px;
            margin-bottom: 10px;
            display: inline-block;
            min-width: 160px;
        }
    }
}
