@import '../../../variables';

.add-pass {
  @media only screen and (max-width: 1023px) {
    right: 10px;
    top: -15px;
  }

  right: 50px;
  top: -20px;
}

.passes-description-container {
  display: flex;
  flex-direction: column;
  align-content: flex-start;
  justify-content: flex-start;
  padding: 2vh 1vw 1vh 2vw;
  border-top: 1px solid #eeeeee;
  margin-top: 15px;

  .passes-description-header {
    color: #80a2b2;
    font-size: 14px;
    line-height: 14px;
    font-weight: bold;
    margin-bottom: 1vh;
  }

  .passes-description {
    font-size: 16px;
    line-height: 22px;
    margin-bottom: 0;
    margin-right: 5px;
  }
}

.passes-description-container-mobile {
  @extend .passes-description-container;
  padding: 15px 15px 15px 15px;
  border-top: none;
  border-bottom: 1px solid #eeeeee;
  width: 96%;
}

.renew-pass-btn {
  min-width: 250px;
  max-height: 50px;
  margin: 20px 20px 20px auto;
}

.renew-pass {
  align-self: flex-end;
}

.renew-pass-notallowed {
  box-sizing: border-box;
  max-height: 29px;
  margin: 5% 5%;
  width: 90%;
  font-family: Foco;
  font-size: 16px;
  color: #ff0000;
  letter-spacing: 1px;
  line-height: 19px;
  text-align: center;
  border: 1px solid #ffa7a7;
  border-radius: 4px;
  background-color: rgba(255, 167, 167, 0.15);
}

.renew-pass-notallowed-mobile {
  box-sizing: border-box;
  max-height: 60px;
  margin: 5% 5%;
  width: 90%;
  font-family: Foco;
  font-size: 16px;
  color: #ff0000;
  letter-spacing: 1px;
  line-height: 19px;
  text-align: center;
  border: 1px solid #ffa7a7;
  border-radius: 4px;
  background-color: rgba(255, 167, 167, 0.15);
}

.renew-pass-mobile {
  align-self: center;

  .renew-pass-btn {
    margin-right: 0;
  }
}

.temp-text {
  color: $corporate-blue;
}

.text-grey {
  color: gray;
}

.breadcrumb {
  margin: 1px 3px;
  padding: 1px;
  text-align: center;

  .breadcrumb-child {
    display: inline-block;
    padding: 3px 3px;
    vertical-align: middle;
    font-size: 16px;
  }
}

.save-btn {
  margin-right: 0;
  margin-top: 0;
  margin-bottom: 0;
  margin-left: 80%;
  width: 200px;
  max-height: 50px;
}