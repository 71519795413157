@import "../../../variables";

.owner-contact-details-modal {
  width: 650px;
  overflow-x: hidden;
  overflow-y: auto;

  form {
    width: 100%;
  }

  .requirement {
    display: inline-flex;
    width: 50%;
  }

  p.update-password-title {
    color: #1e2171;
    font-size: 29px;
    line-height: 29px;
    letter-spacing: 0.43px;
    margin-bottom: 0;
  }

  .info-message.show {
    line-height: 36px;
  }
}
.marketing-modal-field-container {
  p {
    text-align: center;
  }
  .marketing-preferences-container {
    padding: 0 20% 0 25%;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 30px;

    .contact-details-modal-field {
      padding-top: 5px;
      margin: 0;
      box-sizing: border-box;
      display: flex;
      justify-content: space-between;
      flex-direction: row;
      align-items: center;
      width: 50%;
      padding-right: 5%;
      min-width: 18ch;

      label {
        margin-bottom: 0;
      }
    }
  }
}

.contact-details-modal-field {
  display: inline-block;
  width: 50%;
  margin-top: 25px;
  margin-bottom: 25px;
  vertical-align: top;

  label {
    color: #7c9fb0;
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 10px;
    word-wrap: break-word;
    display: block;
  }

  span {
    color: $body-colour;
    font-size: 20px;
    word-wrap: break-word;
    margin-right: 5px;
  }

  &.password-field {
    width: 100%;
    margin-bottom: 0px;
  }
}

.owner-contact-details-modal-mobile {
  max-width: 100%;
  width: 100%;
  position: relative;

  .contact-details-modal-field {
    margin-bottom: 25px;

    &.password-field {
      margin-bottom: 0px;
    }
  }

  .email-field,
  .address1-field,
  .address2-field,
  .city-field {
    width: 100%;
  }

  .react-tabs__tab-list {
    margin-left: -60px;
    margin-right: -20px;
  }

  .requirement {
    display: flex;
    width: 100%;
  }
}


.react-tabs__tab-list {
  margin-left: -80px;
  margin-right: -41px;
  margin-top: 0;
  margin-bottom: 25px;
  display: flex;
}

.react-tabs__tab {
  height: 70px;
  line-height: 70px;
  vertical-align: middle;
  color: $body-colour;
  font-family: Foco;
  font-size: 17px;
  border: none;
  flex: 1;
  text-align: center;
  vertical-align: middle;
  list-style-type: none;
  outline-width: 0;
  cursor: pointer;
  opacity: 0.3;
  transition: all 0.3s;
  border-bottom: 3px solid #1c4d6b;
}
.react-tabs__tab--selected {
  border-bottom: 3px solid #1c4d6b;
  opacity: 1;
}

.react-tabs__tab-panel {
  display: none;
}

.react-tabs__tab-panel--selected {
  display: block;
}

.owner-contact-details-input {
  width: 90%;
}

.owner-contact-details-password-input {
  width: 100%;
}

// .referAlertMobilemodal{
//   height: 25% !important;
// }